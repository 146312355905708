import * as React from "react";
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Button, Dimmer, Form, Grid, Header, Icon, Loader, Message, Segment} from "semantic-ui-react";
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {loadEntities} from "../admin/shared/loadEntities";
import {PaymentOverview} from "./paymentOverview";
import axios from "../../middleware/axios";
import {Link} from "react-router-dom";
import CheckoutSteps from "./checkoutSteps";
import config from "../../config";

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      lineHeight: '1.429',
      iconColor: "#17454e",
      color: "#17454e",
      height: "50px",
      fontSize: "16px",
      fontFamily: '"Open Sans", sans-serif',
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#5D7D83"
      }
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238"
      }
    }
  }
};

class CartPaymentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: false,
    }
  }

  componentDidMount() {
    if (sessionStorage.getItem('cart') != null) {
      this.props.store.cartStore.load(sessionStorage.getItem('cart')).then((cart) => {
        this.props.store.addressStore.load(cart.address.id)
      })
    }
  }

  onSuccess = (response) => {
    this.props.history.push({
      pathname: '/checkout/complete',
      state: {receipt: response.data}
    })
  }

  submitWithoutPayment = (cart) => {
    this.setState({loading: true})
    axios.post("/api/v1/checkout", {
        stripe_cart: cart.number,
        stripe_email: cart.address.email
      }
    ).then(() => {
      this.setState({
        loading: false,
        success: true
      })
    })
  }

  render() {
    const cart = this.props.store.cartStore.carts.find(cart => cart.number === sessionStorage.getItem('cart'));
    const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY, {apiVersion: "2020-08-27"});

    if (cart && cart.cart_tickets.length === 0) {
      this.props.history.push("/")
    }

    return (
      <>
        <CheckoutSteps active={'payment'}/>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <PaymentOverview cart={cart} shows={this.props.shows}/>
              {this.state.loading ?
                <Dimmer active inverted>
                  <Loader>Bestellvorgang läuft</Loader>
                </Dimmer>
                : <></>
              }
              {cart && !cart.payment_required ?
                this.state.success
                  ? <SuccessComponent paymentRequired={false} />
                  :
                  <Button floated={"right"} type={"submit"} style={{margin: 0, marginTop: 30}} primary onClick={
                    () => this.submitWithoutPayment(cart)}>
                    Bestellung abschliessen
                  </Button>
                :
                <Elements stripe={stripePromise}>
                  <CheckoutForm cart={cart} onSuccess={(response) => this.onSuccess(response)}/>
                </Elements>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  }
}

const CheckoutForm = ({cart, address, onSuccess}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [paymentRunning, setPaymentRunning] = React.useState(false)

  const CARD_ERROR_MESSAGES = {
    "Your card has expired.": "Ihre Karte ist abgelaufen",
    "Your card's security code is incorrect.": "Der Sicherheitscode Ihrer Karte ist falsch.",
    "An error occurred while processing your card. Try again in a little bit.": "Bei der Verarbeitung Ihrer Karte ist ein Fehler aufgetreten. Versuchen Sie es in Kürze erneut.",
    "Your card was declined.": "Ihre Karte wurde abgelehnt.",
    undefined: "Leider ist bei der Bezahlung ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns falls das Problem weiterhin besteht."
  }

  const handleSubmit = async event => {
    event.preventDefault();
    setPaymentRunning(true)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      billing_details: {
        address: {
          city: cart.address.city,
          country: "CH",
          line1: cart.address.street + " " + cart.address.house,
          postal_code: cart.address.zipcode,
        },
        email: cart.address.email,
        name: cart.address.firstname + " " + cart.address.lastname
      },
      card: elements.getElement(CardElement)
    });

    if (error) {
      setError(error)
    } else {
      setError("")
      const {id} = paymentMethod
      const cartNumber = cart.number

      axios.post("/api/v1/checkout", {
          stripe_token: id,
          stripe_cart: cartNumber,
          stripe_email: cart.address.email
        }
      ).then((response) => {
        setPaymentRunning(false);
        setSuccess(true);
      }).catch(function (error) {
        setPaymentRunning(false);
        if (error.response) {
          setError(CARD_ERROR_MESSAGES[`${error.response.data.error}`]);
        }
      })
    }
  };

  return (
    <>
      {success ?
        <SuccessComponent paymentRequired={true}/>
        :
        <Segment style={{marginTop: 40}} padded>
          {paymentRunning ?
            <Dimmer active inverted>
              <Loader>Bezahlvorgang läuft</Loader>
            </Dimmer>
            : <></>
          }

          <Form onSubmit={handleSubmit}>
            <h2 style={{marginBottom: 40}}>Bezahlung per Kreditkarte</h2>
            <div className={"card-elements-stripe"}>
              <CardElement options={CARD_ELEMENT_OPTIONS}/>
            </div>
            {error !== "" ?
              <Message negative>
                <p>{error}</p>
              </Message>
              : <></>
            }
            <Button
              disabled={!stripe}
              type={"submit"}
              style={{margin: 0, marginTop: 30}}
              primary
            >
              Bezahlen
            </Button>
          </Form>
        </Segment>
      }
    </>
  )
}

const SuccessComponent = (paymentRequired) => {
  return (
    <>
      <Grid style={{marginTop: 40, marginBottom: 40}}>
        <Grid.Row>
          <Grid.Column>
            <Message>
              <Message.Header>Vielen Dank für Ihre Bestellung!</Message.Header>
              {paymentRequired ?
                <p>
                  Ihre Zahlung war erfolgreich und Sie werden die Tickets in Kürze per Mail zugestellt bekommen.
                </p>
                :
                <p>
                  Ihre Bestellung war erfolgreich und Sie werden die Tickets in Kürze per Mail zugestellt bekommen.
                </p>
              }
            </Message>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Segment>
        <Grid columns={1} stackable textAlign='center'>
          <Grid.Row verticalAlign='middle'>
            <Grid.Column>
              <Link to={'/'}>
                <div>
                  <Header icon>
                    <Icon name='ticket'/>
                    Weiter einkaufen
                  </Header>
                </div>
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  )
}

export default compose(
  loadEntities('showStore', 'shows'),
  withNamespaces('translation'),
  inject('store'),
  observer
)(CartPaymentPage);