import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {EntityDetailTable, EntityDetailTableEntry} from "../shared/tables";
import {adminRoute} from "../../../routing/routing";
import {DeleteButton, EditButton} from "../shared/buttons";
import {withRouter} from "react-router";
import {confirmDelete} from "../shared/confirm";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {loadEntity} from "../shared/loadEntity";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";

class SalesPointDetail extends React.Component {

  render() {
    const {t} = this.props;
    return (
      <EntityDetailLayout title={t(i18nArModel('sales_point.one'))} backPath={`/sales_points`}>
        <EditButton route={adminRoute(`/sales_points/${this.props.salesPoint.id}/edit`)}/>
        <DeleteButton onClick={() => this.delete()}/>

        <EntityDetailTable>
          <EntityDetailTableEntry
            name={t(i18nArAttr('sales_point.name'))}><b>{this.props.salesPoint.name}</b></EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('sales_point.location'))}>{this.props.salesPoint.location}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('sales_point.notes'))}>{this.props.salesPoint.notes}</EntityDetailTableEntry>
        </EntityDetailTable>
      </EntityDetailLayout>
    )
  }

  delete() {
    if (confirmDelete(this.props.t, 'sales_point')) {
      this.props.store.salesPointStore.delete(this.props.salesPoint.id).then(() => {
        this.props.history.push(adminRoute('/sales_points'));
      });
    }
  }
}

export default compose(
  loadEntity('salesPointStore', 'salesPoint', adminRoute('/sales_points')),
  withRouter,
  withNamespaces('translation'),
  inject('store'),
  observer
)(SalesPointDetail);
