import * as React from "react";
import compose from "compose-function";
import {inject, observer} from "mobx-react";
import {withNamespaces} from "react-i18next";
import {i18nArModel} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import FestivalPassCategoryForm from "./form";
import {loadEntity} from "../shared/loadEntity";

class EditFestivalPassCategory extends React.Component {
  render() {
    const {t} = this.props;

    const festivalPassCategoryPath = `/festival_pass_categories/${this.props.match.params.id}`;

    return <EntityDetailLayout
      title={t('admin.entity.edit', {model_name: t(i18nArModel(`festival_pass_category.one`))})}
      backPath={festivalPassCategoryPath}
    >
      <FestivalPassCategoryForm
        redirectTo={adminRoute(festivalPassCategoryPath)}
        festivalPassCategory={this.props.festivalPassCategory}
      />
    </EntityDetailLayout>
  }
}

export default compose(
  loadEntity('festivalPassCategoryStore', 'festivalPassCategory', (props) => adminRoute(`/festival_pass_categories`)),
  withNamespaces('translation'),
  inject('store'),
  observer
)(EditFestivalPassCategory);
