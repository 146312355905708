import React from 'react'
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import Navbar from "./components/presale/navbar";
import PreSale from "./components/presale/presale";
import Footer from "./components/presale/footer";

export const App = ({store}) => {

  return (
    <>
      <Navbar/>
      <div
        style={{minHeight: 'calc(100% - 100px)'}}
        className={'app-container ' + (store.configStore.isMobile ? 'mobile' : 'desktop')}
      >
        <PreSale/>
      </div>
      <Footer/>
    </>
  )
}

export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(App);
