import * as React from "react";
import {withNamespaces} from "react-i18next";
import CustomForm from "../../forms/form";
import Text from "../../forms/text";
import Select from "../../forms/select";
import {inject, observer} from "mobx-react";
import {Checkbox, Form} from "semantic-ui-react";
import {withRouter} from "react-router";
import compose from "compose-function";
import {SubmitButton} from "../shared/buttons";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import {priceTypes} from "../../../models/price";

class PriceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      internal_checked: props.price ? props.price.internal : false
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.successCallback = this.successCallback.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  handleOnSubmit(price) {
    price = {
      ...price,
      id: this.props.price ? this.props.price.id : null,
      show_id: this.props.show.id,
      internal: this.state.internal_checked,
    };

    if (this.props.price) {
      this.props.store.priceStore.update(price).then(this.successCallback).catch(this.errorCallback);
    } else {
      this.props.store.priceStore.create(price).then(this.successCallback).catch(this.errorCallback);
    }
  }

  errorCallback(formErrors) {
    this.setState({errors: formErrors});
  }

  successCallback(price) {
    this.setState({errors: {}});
    if (this.props.redirectTo) {
      this.props.history.push(this.props.redirectTo);
    }
  }

  render() {
    const {t, price = {}} = this.props;

    const priceTypeOptions = priceTypes.map(priceType => {
      return {value: priceType, text: t(i18nArAttr(`price.price_types.${priceType}`))}
    });

    return <CustomForm onSubmit={this.handleOnSubmit} initialValues={{...price}}>
      <Form.Field>
        <label htmlFor={'price'}>{t(i18nArAttr('price.price'))}*</label>
        <Text type={'number'} field="price" errors={this.state.errors.price}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'price_type'}>{t(i18nArAttr('price.price_type'))}*</label>
        <Select field="price_type" errors={this.state.errors.price_type} options={priceTypeOptions}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'internal'}>{t(i18nArAttr('price.internal'))}*</label>
        <Checkbox
          checked={this.state.internal_checked}
          onClick={() => {
            this.setState({internal_checked: !this.state.internal_checked})
          }} toggle field="internal"/>
      </Form.Field>
      <SubmitButton loading={this.props.store.priceStore.isLoading}/>
    </CustomForm>
  }
}

export default compose(
  withRouter,
  withNamespaces('translation'),
  inject('store'),
  observer
)(PriceForm);
