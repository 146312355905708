import * as React from "react";
import {Header} from "semantic-ui-react";
import {adminRoute} from "../../../routing/routing";
import {BackButton} from "./buttons";

export class EntityDetailLayout extends React.Component {
  render(){
    return <React.Fragment>
      <Header as={'h1'}>
        {this.props.title}
      </Header>
      {this.props.children}
      <BackButton route={adminRoute(this.props.backPath)} />
    </React.Fragment>
  }
}