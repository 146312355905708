import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {EntityDetailTable, EntityDetailTableEntry, EntityTable} from "../shared/tables";
import {i18nArAttr, i18nArModel, i18nBool} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {DeleteButton, EditButton} from "../shared/buttons";
import {withRouter} from "react-router";
import {confirmDelete} from "../shared/confirm";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {loadEntity} from "../shared/loadEntity";
import {Message, Tab} from "semantic-ui-react";
import FestivalPassList from "../festival_pass/list";
import loadEntities from "../shared/loadEntities";
import PresaleOrderListItem from "../presale_orders/listItem";
import moment from "moment";

class FestivalDetail extends React.Component {

  render() {
    const {t, shows} = this.props;
    const headers = [
      t(i18nArAttr('presale_order.bought_at')),
      t(i18nArAttr('presale_order.firstname')),
      t(i18nArAttr('presale_order.lastname')),
      t(i18nArAttr('presale_order.email')),
      t(i18nArAttr('presale_order.tickets')),
      ''
    ];
    let {presaleOrders} = this.props;
    presaleOrders = presaleOrders.filter(po => po.festival_id === this.props.festival.id)


    return (
      <EntityDetailLayout title={t(i18nArModel('festival.one'))} backPath={`/festivals`}>
        <EditButton route={adminRoute(`/festivals/${this.props.festival.id}/edit`)}/>
        <DeleteButton onClick={() => this.delete()}/>
        <EntityDetailTable>
          <EntityDetailTableEntry name={t(i18nArAttr('festival.name'))}>
            <b>{this.props.festival.name}</b>
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('festival.location'))}>
            {this.props.festival.location}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('festival.starts_at'))}>
            {this.props.festival.starts_at ? moment(this.props.festival.starts_at).format() : '-'}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('festival.ends_at'))}>
            {this.props.festival.ends_at ? moment(this.props.festival.ends_at).format() : '-'}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('festival.presale_active'))}>
            {t(i18nBool(this.props.festival.presale_active))}
          </EntityDetailTableEntry>
        </EntityDetailTable>
        <Tab
          panes={[
            {
              menuItem: t(i18nArModel('festival_pass.other')),
              render: () =>
                <Tab.Pane>
                  <FestivalPassList festival={this.props.festival}/>
                </Tab.Pane>
            },
            {
              menuItem: t(i18nArModel('presale_order.other')),
              render: () =>
                <Tab.Pane>
                  {presaleOrders.length > 0 ?
                    <EntityTable headers={headers}>
                      {presaleOrders ? presaleOrders.map((presaleOrder) =>
                        <PresaleOrderListItem key={presaleOrder.id} shows={shows} presaleOrder={presaleOrder}/>
                      ) : <></>
                      }
                    </EntityTable>
                    :
                    <Message>Leider wurden für dieses Festival noch keine Tickets über den Vorverkauf verkauft</Message>
                  }
                </Tab.Pane>
            },
          ]}
        />
      </EntityDetailLayout>
    )
  }

  delete() {
    if (confirmDelete(this.props.t, 'festival')) {
      this.props.store.festivalStore.delete(this.props.festival.id).then(() => {
        this.props.history.push(adminRoute('/festivals'));
      });
    }
  }

  errorCallback(formErrors) {
    console.error({formErrors});
    this.setState({errors: formErrors});
  }
}

export default compose(
  loadEntities('presaleOrderStore', 'presaleOrders', adminRoute('/festivals')),
  loadEntity('festivalStore', 'festival', adminRoute('/festivals')),
  loadEntities('showStore', 'shows', {
    storeParams: () => ({params: {current_festival: true}})
  }),
  withRouter,
  withNamespaces('translation'),
  inject('store'),
  observer
)(FestivalDetail);
