import {BaseModel} from "./base";
import {formatPrice} from "../formatters/priceFormatter";

export class Price extends BaseModel {
  id = 0;
  show_id = 0;
  price = 0;
  price_currency = '';
  price_type = '';
  internal = false;

  formatPrice() {
    return formatPrice(this.price, this.price_currency);
  }

  static fromPlainObject(plainObject) {
    const price = new Price();
    return Object.assign(price, plainObject);
  }
}

export const priceTypes = ['normal', 'reduced', 'free', 'special', 'culture', 'patron'];