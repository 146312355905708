import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {Table} from "semantic-ui-react";
import compose from "compose-function";
import {adminRoute} from "../../../routing/routing";
import {inject, observer} from "mobx-react";
import {DetailsButton} from "../shared/buttons";
import {i18nArAttr, i18nBool} from "@semabit/rails-i18n-tools";

class PriceListItem extends React.Component {

  render() {
    return (
      <Table.Row>
        <Table.Cell>{this.props.price.formatPrice()}</Table.Cell>
        <Table.Cell>{this.props.t(i18nArAttr(`price.price_types.${this.props.price.price_type}`))}</Table.Cell>
        <Table.Cell>{this.props.t(i18nBool(this.props.price.internal))}</Table.Cell>
        <Table.Cell collapsing>
          <DetailsButton route={adminRoute(`/shows/${this.props.show.id}/prices/${this.props.price.id}`)}/>
        </Table.Cell>
      </Table.Row>
    )
  }
}

export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(PriceListItem);
