import * as React from "react";
import {Grid, Icon, Step} from "semantic-ui-react";
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";


class CheckoutSteps extends React.Component {
  render() {
    const STEPS = {
      'cart': 1,
      'address': 2,
      'confirmation': 3,
      'payment': 4,
    }
    const current = STEPS[this.props.active];
    return (
      <Grid columns={'equal'}>
        <Grid.Row style={{marginBottom: 40, marginTop: 40}}>
          <Grid.Column>
            <Step.Group widths={4}>
              <Step href={current > 1 ? '/cart' : ''} active={current === 1}>
                <Icon name='cart'/>
                <Step.Content>
                  <Step.Title>Warenkorb</Step.Title>
                </Step.Content>
              </Step>

              <Step href={current > 2 ? '/checkout/address' : ''} active={current === 2} disabled={current < 2}>
                <Icon name={'address card'}/>
                <Step.Content>
                  <Step.Title>Adressangaben</Step.Title>
                </Step.Content>
              </Step>

              <Step href={current > 3 ? '/checkout/confirmation' : ''} active={current === 3}  disabled={current < 3}>
                <Icon name={'address card'}/>
                <Step.Content>
                  <Step.Title>Bestätigung</Step.Title>
                </Step.Content>
              </Step>

              <Step active={current === 4} disabled={current < 4}>
                <Icon name={'payment'}/>
                <Step.Content>
                  <Step.Title>Bezahlung</Step.Title>
                </Step.Content>
              </Step>
            </Step.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}


export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(CheckoutSteps);