import * as React from "react";
import NavigationBar from "./navbar";
import DesktopSidebar from "./sidebar/desktopSidebar";

class DesktopMenu extends React.Component {
  render() {
    return <React.Fragment>
      <DesktopSidebar/>
      <div className={"desktop-content-wrapper"}>
        <NavigationBar/>
        {this.props.children}
      </div>
    </React.Fragment>;
  }
}

export default DesktopMenu;