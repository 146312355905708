import {Button, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import * as React from "react";
import {withNamespaces} from "react-i18next";
import compose from "compose-function";

export const LabeledIconLinkButton = props => {
  const {route, text, icon, primary = true, ...rest} = props;
  return <Button primary={primary} as={Link} to={route} icon labelPosition='left' {...rest}>
    <Icon name={icon || 'plus'}/>
    {text}
  </Button>;
};

export const SubmitButton = compose(withNamespaces('translation'))(({t, loading = false}) => {
  return <Button primary type="submit" loading={loading}>{t('form.submit')}</Button>;
});

export const DetailsButton = compose(withNamespaces('translation'))((props) => {
  return <Button primary size={'small'} as={Link} to={props.route} icon labelPosition='left'>
    <Icon name={'zoom'}/>{props.t('link.details')}
  </Button>;
});

export const EditButton = compose(withNamespaces('translation'))((props) => {
  return <Button primary size={'small'} as={Link} to={props.route} icon labelPosition='left'>
    <Icon name={'pencil'}/>{props.t('link.edit')}
  </Button>;
});

export const DeleteButton = compose(withNamespaces('translation'))((props) => {
  return <Button primary size={'small'} onClick={props.onClick} icon labelPosition='left'>
    <Icon name={'trash'}/>{props.t('link.destroy')}
  </Button>;
});

export const CancelButton = compose(withNamespaces('translation'))((props) => {
  return <Button primary size={'small'} onClick={props.onClick} icon labelPosition='left' disabled={props.disabled}>
    <Icon name={'ban'}/>{props.t('link.cancel')}
  </Button>;
});

export const BackButton = compose(withNamespaces('translation'))((props) => {

  const defaultStyle = {marginTop: "1rem"};
  const style = typeof props.style === 'object' ? Object.assign(defaultStyle, props.style) : defaultStyle;

  return <Button style={style} as={Link} to={props.route}>
    {props.t('link.back')}
  </Button>;
});