import * as React from "react";
import {Sidebar} from "semantic-ui-react";
import NavigationBar from "./navbar";
import compose from "compose-function";
import {inject, observer} from "mobx-react";
import MobileSidebar from "./sidebar/mobileSidebar";

class MobileMenu extends React.Component {

  constructor(props) {
    super(props);
    this.closeSidebar = this.closeSidebar.bind(this);
  }

  render() {
    return <Sidebar.Pushable>
      <MobileSidebar onNavigate={this.closeSidebar}/>
      <NavigationBar/>
      <Sidebar.Pusher>
        {this.props.children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>;
  }

  closeSidebar() {
    if (this.props.store.configStore.isMobile) {
      this.props.store.configStore.sidebarVisible = false;
    }
  }
}

export default compose(
  inject('store'),
  observer
)(MobileMenu);