import {decorate, observable} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {SalesPoint} from "../models/salesPoint";

decorate(SalesPoint, {
  name: observable,
  location: observable,
});

export class SalesPointStore extends EntityStore {

  salesPoints = [];

  constructor(rootStore) {
    super(rootStore, 'salesPoints', Api.salesPoints, SalesPoint);
  }
}

decorate(SalesPointStore, {
  salesPoints: observable,
});
