import * as React from "react";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {staffRoute} from "../../routing/routing";
import {loadEntities} from "../admin/shared/loadEntities";
import {loadEntity} from "../admin/shared/loadEntity";
import {EntryTypes} from "../../models/entry";
import {BackButton} from "../admin/shared/buttons";
import {Label, Table} from "semantic-ui-react";
import moment from "moment";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../admin/shared/tables";

class ScannedTicketsView extends React.Component {

  render() {
    const {t, show, entries, festivalPasses} = this.props;
    const scannedEntries = entries.filter(
      e => (e.entry_type === EntryTypes.eveningSale && e.ticket_id !== null) || (e.entry_type === EntryTypes.festivalPass && e.festival_pass_id !== null));
    const headers = [
      '',
      t(i18nArAttr('ticket.id')),
      t(i18nArAttr('entry.created_at')),
      t(i18nArAttr('entry.entry_type')),
    ];

    return (
      <div className={'reservations-view'}>
        <BackButton route={staffRoute(`/entry/shows/${show.id}`)} style={{margin: '0', marginBottom: '20px'}}/>
        {scannedEntries.length <= 0
          ? <p>Bisher wurden für diese Show keine Tickets eingescannt.</p>
          : <EntityTable headers={headers}>
            {scannedEntries.map(entry => {
              return (
                <Table.Row key={entry.id}>
                  <Table.Cell>
                    <Label color='teal' horizontal>{this.props.t(i18nArAttr('ticket.entered'))}</Label>
                  </Table.Cell>
                  <Table.Cell>
                    {entry.ticket_id !== null
                      ? '#' + entry.ticket_id
                      : ''
                    }
                    {entry.festival_pass_id !== null
                      ? '#' + entry.festival_pass_id
                      : ''
                    }
                  </Table.Cell>
                  <Table.Cell>
                    {entry.created_at ? moment(entry.created_at).format() : '-'}
                  </Table.Cell>
                  <Table.Cell>
                    {this.props.t(i18nArAttr('entry.entry_types.' + entry.entry_type))}<br />
                    {entry.festival_pass_id !== null ?
                      <i>{festivalPasses.find( fp => fp.id === entry.festival_pass_id).fullName()}</i>
                      : ''
                    }
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </EntityTable>
        }
      </div>
    )
  }
}

export default compose(
  withNamespaces('translation'),
  loadEntity('showStore', 'show', staffRoute('/entry'), {lazyLoad: true}),
  loadEntities('festivalPassStore', 'festivalPasses', {
    storeParams: (props) => ({params: {festival_id: props.show.festival_id}})
  }),
  loadEntities('entryStore', 'entries', {
    storeParams: (props) => ({params: {showId: props.show.id}}),
    autoUpdate: true
  }),
  loadEntities('ticketStore', 'tickets', {
    storeParams: (props) => ({params: {showId: props.show.id}}),
    autoUpdate: true
  }),
  inject('store'),
  observer
)(ScannedTicketsView);

