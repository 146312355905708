import {createEntity, executeDelete, executeGet, patchEntity, updateEntity} from "./base";

export const resourcesName = 'receipts';
export const resourceName = 'receipt';

const pathPrefix = (showId) => `shows/${showId}`;

export const receiptsResourcePath = (showId, path = '') => {
  return pathPrefix(showId) + `/${resourcesName}` + path;
};

export const ReceiptsApi = {
  all: ({showId, ...params}) => executeGet(receiptsResourcePath(showId), {params}),
  byId: (id, {showId}) => executeGet(receiptsResourcePath(showId, `/${id}`)),
  current: ({showId}) => executeGet(receiptsResourcePath(showId, `/current`)),
  create: (entity) => createEntity(entity, receiptsResourcePath(entity.show_id), resourceName),
  update: (entity) => updateEntity(entity, receiptsResourcePath(entity.show_id, `/${entity.id}`), resourceName),
  complete: (id, {showId}) => patchEntity(receiptsResourcePath(showId, `/${id}/complete`)),
  destroy: (id, {showId}) => executeDelete(receiptsResourcePath(showId, `/${id}`))
};
