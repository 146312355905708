import * as React from "react";
import {withNamespaces} from "react-i18next";
import CustomForm from "../../forms/form";
import Text from "../../forms/text";
import {inject, observer} from "mobx-react";
import {Form, Dropdown, Button, Image} from "semantic-ui-react";
import TextArea from "../../forms/textarea";
import {withRouter} from "react-router";
import compose from "compose-function";
import {SubmitButton} from "../shared/buttons";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import DateTimePicker from "../../forms/dateTimePicker";
import {Show} from "../../../models/show";
import {loadEntities} from "../shared/loadEntities";
import FileBase64 from 'react-file-base64';


class ShowForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      selectedFestivalId: props.show ? props.show.festival_id : null,
      imageUpload: null,
      setNewImage: true,
      file: [],
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.successCallback = this.successCallback.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  componentDidMount() {
    if(this.props.show && this.props.show.presale_image !== null) {
      this.setState({setNewImage: false})
    }
  }

  handleOnSubmit(show) {
    show = {
      ...show,
      id: this.props.show ? this.props.show.id : null,
      festival_id: this.state.selectedFestivalId,
      presale_image: this.state.file.base64
    };

    if (this.props.show) {
      this.props.store.showStore.update(show).then(this.successCallback).catch(this.errorCallback);
    } else {
      this.props.store.showStore.create(show).then(this.successCallback).catch(this.errorCallback);
    }
  }

  errorCallback(formErrors) {
    this.setState({errors: formErrors});
  }

  successCallback(show) {
    this.setState({errors: {}});
    if (this.props.redirectTo) {
      this.props.history.push(this.props.redirectTo);
    }
  }

  endsAtValid(currentDate) {
    return !this.state.begins_at || currentDate.endOf('day').isSameOrAfter(this.state.begins_at);
  }

  handleSelectionChange = (e, {value}) => {
    if (value === '') {
      this.setState({selectedFestivalId: null})
    } else {
      this.setState({selectedFestivalId: value})
    }
  }

  // Callback~
  getFile(file) {
    this.setState({file: file})
  }

  render() {
    const festivalOptions = this.props.festivals.map((festival) => {
      return (
        {
          key: festival.id,
          value: festival.id,
          text: festival.name
        }
      )
    });

    festivalOptions.unshift({key: 'null', value: '', text: 'Kein Festival zuweisen'})

    // initialValues are not updated in the form once rendered, re-render does not update them either.
    const {t, show = new Show()} = this.props;
    return <CustomForm onSubmit={this.handleOnSubmit} initialValues={{...show}}>
      <Form.Field>
        <label htmlFor={'name'}>{t(i18nArAttr('show.name'))}*</label>
        <Text field="name" errors={this.state.errors.name}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'group_name'}>{t(i18nArAttr('show.group_name'))}*
        </label>
        <Text field="group_name" errors={this.state.errors.group_name}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'presale_image'}>{t(i18nArAttr('show.presale_image'))}
        </label>
        {this.state.setNewImage
          ?
          <>
            <FileBase64
              multiple={false}
              value={this.state.file}
              onDone={this.getFile.bind(this)}
            />
          </>
          :
          <>
            <Image src={show.presale_image.list_size.url} style={{height: '100px', marginBottom: 20}}/>
            <Button onClick={() => this.setState({setNewImage: true})} type={"button"} className={'primary'}>Bild entfernen</Button>
          </>

        }

      </Form.Field>
      <Form.Field>
        <label htmlFor={'festival_id'}>{t(i18nArAttr('show.festival'))}</label>
        <Dropdown
          name="festival_id"
          onChange={this.handleSelectionChange}
          selection
          options={festivalOptions}
          value={this.state.selectedFestivalId}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor={'location'}>{t(i18nArAttr('show.location'))}</label>
        <Text field="location" errors={this.state.errors.location}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'description'}>{t(i18nArAttr('show.description'))}</label>
        <TextArea field="description" errors={this.state.errors.description}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'begins_at'}>{t(i18nArAttr('show.begins_at'))}</label>
        <DateTimePicker field="begins_at" errors={this.state.errors.begins_at}
                        onChange={(v) => this.setState((prevState) => ({...prevState, begins_at: v}))}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'ends_at'}>{t(i18nArAttr('show.ends_at'))}</label>
        <DateTimePicker field="ends_at" errors={this.state.errors.ends_at}
                        isValidDate={(current) => this.endsAtValid(current)}
                        viewDate={this.state.begins_at}
        />
      </Form.Field>
      <Form.Field>
        <label
          htmlFor={'max_available_tickets_count'}>{t(i18nArAttr('show.max_available_tickets_count'))}*</label>
        <Text type={'number'} field="max_available_tickets_count"
              errors={this.state.errors.max_available_tickets_count}/>
      </Form.Field>
      <Form.Field>
        <label
          htmlFor={'max_tickets_available_for_presale_count'}>{t(i18nArAttr('show.max_tickets_available_for_presale_count'))}*</label>
        <Text type={'number'} field="max_tickets_available_for_presale_count"
              errors={this.state.errors.max_tickets_available_for_presale_count}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'presale_starts_at'}>{t(i18nArAttr('show.presale_starts_at'))}</label>
        <DateTimePicker field="presale_starts_at" errors={this.state.errors.presale_starts_at}
                        onChange={(v) => this.setState((prevState) => ({...prevState, presale_starts_at: v}))}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'presale_ends_at'}>{t(i18nArAttr('show.presale_ends_at'))}</label>
        <DateTimePicker field="presale_ends_at" errors={this.state.errors.presale_ends_at}
                        onChange={(v) => this.setState((prevState) => ({...prevState, presale_ends_at: v}))}/>
      </Form.Field>
      <SubmitButton loading={this.props.store.showStore.isLoading}/>
    </CustomForm>
  }
}

export default compose(
  withRouter,
  loadEntities('festivalStore', 'festivals'),
  withNamespaces('translation'),
  inject('store'),
  observer
)(ShowForm);
