import * as React from "react";
import compose from "compose-function";
import {inject, observer} from "mobx-react";

class ConfigProvider extends React.Component {
  constructor(props) {
    super(props);

    this.updateLayoutMode = this.updateLayoutMode.bind(this);
  }

  render() {
    return this.props.children || null;
  }

  componentDidMount() {
    this.updateLayoutMode();
    window.addEventListener('resize', this.updateLayoutMode);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateLayoutMode);
  }

  updateLayoutMode() {
    this.props.store.configStore.isMobile = window.innerWidth < 768;
  }
}

export default compose(
  inject('store'),
  observer
)(ConfigProvider);
