import {BaseModel} from "./base";

export class PresaleOrder extends BaseModel {
  address_id = 0;
  address = null;
  tickets = null;
  tickets_count = 0;
  festival = 0;

  static fromPlainObject(plainObject) {
    const presaleOrder = new PresaleOrder();
    return Object.assign(presaleOrder, plainObject);
  }
}

