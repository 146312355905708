import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {EntityDetailTable, EntityDetailTableEntry} from "../shared/tables";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {DeleteButton, EditButton} from "../shared/buttons";
import {withRouter} from "react-router";
import {confirmDelete} from "../shared/confirm";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {loadEntity} from "../shared/loadEntity";
import moment from "moment";

class FestivalPassCategoryDetail extends React.Component {

  render() {
    const {t} = this.props;
    return (
      <EntityDetailLayout title={t(i18nArModel('festival_pass_category.one'))} backPath={`/festival_pass_categories`}>
        <EditButton route={adminRoute(`/festival_pass_categories/${this.props.festivalPassCategory.id}/edit`)}/>
        <DeleteButton onClick={() => this.delete()}/>
        <EntityDetailTable>
          <EntityDetailTableEntry
            name={t(i18nArAttr('festival_pass_category.name'))}><b>{this.props.festivalPassCategory.name}</b></EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArModel('festival.one'))}>
            {this.props.festivalPassCategory.festival
              ? this.props.festivalPassCategory.festival.name
              : '-'
            }
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('festival_pass_category.starts_at'))}>
            {this.props.festivalPassCategory.starts_at ? moment(this.props.festivalPassCategory.starts_at).format("DD.MM.YYYY") : '-'}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('festival_pass_category.ends_at'))}>
            {this.props.festivalPassCategory.ends_at ? moment(this.props.festivalPassCategory.ends_at).format("DD.MM.YYYY") : '-'}
          </EntityDetailTableEntry>
        </EntityDetailTable>
      </EntityDetailLayout>
    )
  }

  delete() {
    if (confirmDelete(this.props.t, 'festival_pass_category')) {
      this.props.store.festivalPassCategoryStore.delete(this.props.festivalPassCategory.id).then(() => {
        this.props.history.push(adminRoute(`/festival_pass_categories`));
      });
    }
  }

  errorCallback(formErrors) {
    console.error({formErrors});
    this.setState({errors: formErrors});
  }
}

export default compose(
  withRouter,
  loadEntity('festivalPassCategoryStore', 'festivalPassCategory', adminRoute('/festival_pass_categories')),
  withNamespaces('translation'),
  inject('store'),
  observer
)(FestivalPassCategoryDetail);
