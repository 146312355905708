import {BaseModel} from "./base";

export class User extends BaseModel {
  id = 0;
  email = "";
  uid = "";
  accessToken = "";
  expiry = "";
  client = "";
  first_name = "";
  last_name = "";
  admin = false;

  fullName() {
    return `${this.first_name} ${this.last_name}`
  }

  static fromPlainObject(plainObject) {
    const user = new User();
    return Object.assign(user, plainObject);
  }
}