import {decorate, observable, runInAction} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {Ticket} from "../models/ticket";

decorate(Ticket, {
  price: observable,
  show_id: observable,
  receipt_id: observable,
  state: observable
});

export class TicketStore extends EntityStore {

  tickets = [];

  constructor(rootStore) {
    super(rootStore, 'tickets', Api.tickets, Ticket);
  }

  deleteMultiple(ticketIds, options = {}) {
    this.pendingRequests++;
    return this.api.destroyMultiple(ticketIds, options.params).then(() => {
      runInAction(() => this.pendingRequests--);
    }).catch(e => this.handleApiError(e));
  }

  updateMultiple(ticketIds, options = {}) {
    this.pendingRequests++;
    return this.api.updateMultiple(ticketIds, options.params).then((tickets) => {
      const entities = tickets.map(e => this.modelClass.fromPlainObject(e));
      runInAction(() => this.pendingRequests--);
      entities.map(e => this.add(e));
      return entities;
    }).catch(e => this.handleApiError(e));
  }

  createMultiple(numberOfTickets, templateTicket, options = {}) {
    this.pendingRequests++;
    return this.api.createMultiple(numberOfTickets, templateTicket).then((entities) => {
      this.addMessage({type: 'success', title: 'messages.create_success'}, options);
      const tickets = entities.map(e => this.modelClass.fromPlainObject(e));
      runInAction(() => this.pendingRequests--);
      tickets.map(e => this.add(e));
      return tickets;
    }).catch(e => this.handleApiError(e));
  }

}

decorate(TicketStore, {
  tickets: observable,
});
