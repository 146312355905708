import axios from "../middleware/axios";
import {saveAs} from "file-saver";

export const showPdfFromUrl = (url) => {
  // window.open has to be called immediately (during the click event), otherwise will be blocked from popup-blocker/ad-blocker
  const win = window.open(window.location, '_blank');

  return axios.get(url, {responseType: 'arraybuffer'}).then(({data, headers}) => {

    // const filename = headers['content-disposition'] && headers['content-disposition'].split("filename=").pop().replace(/"/g, '');

    const fileBlob = new Blob([data], {type: "application/pdf"});

    // now we set the real location of the new window/tab
    if (win.document.readyState === 'complete') {
      win.location = window.URL.createObjectURL(fileBlob);
    } else {
      win.onload = () => {
        win.location = window.URL.createObjectURL(fileBlob);
      };
    }
  });
};

export const downloadPdfFromUrl = (url) => {
  return axios.get(url, {responseType: 'arraybuffer'}).then(({data, headers}) => {

    const filename = headers['content-disposition'] && headers['content-disposition'].split("filename=").pop().replace(/"/g, '');

    const fileBlob = new Blob([data], {type: "application/pdf"});

    saveAs(fileBlob, filename || 'receipt.pdf');
  });
};