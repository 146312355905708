import * as React from "react";
import {Button, Icon, Label, Table} from "semantic-ui-react";
import compose from "compose-function";
import {inject, observer} from "mobx-react";
import {confirmDelete} from "../admin/shared/confirm";
import {withNamespaces} from "react-i18next";
import {loadEntities} from "../admin/shared/loadEntities";

class ReservationListItem extends React.Component {

  constructor(props) {
    super(props);
    this.addClicked = this.addClicked.bind(this);
    this.state = {editing: false};
  }

  get editingAllowed() {
    return this.props.allowEdit && !this.props.disabled && !this.props.reservation.tickets_picked_up && !this.props.reservation.already_paid;
  }

  render() {
    const {
      t,
      showAlreadyPaidBadge = true,
      icon = 'ticket alternate',
      disabled = false,
      showTicketsPickedUpBadge = true,
      showNotes = true,
    } = this.props;

    return (
      <Table.Row disabled={this.props.reservation.tickets_picked_up}>
        <Table.Cell>
          {this.props.reservation.fullName()}
          {showAlreadyPaidBadge && this.props.reservation.already_paid === true ?
            <Label size={'tiny'} color={'teal'} style={{marginLeft: '5px'}}>vorausbezahlt</Label> : ''}
          {showTicketsPickedUpBadge && this.props.reservation.tickets_picked_up === true ?
            <Label size={'tiny'} color={'green'} style={{marginLeft: '5px'}}><Icon
              name={'check'}/>abgeholt</Label> : ''}
          <div
            className={'reservation-details'}>
            {this.props.reservation.number_of_persons} {this.props.reservation.number_of_persons > 1 ? 'Personen' : 'Person'}
            {this.editingAllowed ?
              <Button className={'minimal-icon-button'} icon={this.state.editing ? 'check' : 'pencil'}
                      onClick={this.toggleEditing.bind(this)}/> : ''}
            <Button className={'minimal-icon-button'} icon={'trash'}
                    onClick={() => this.deleteClicked(t)}/>
          </div>
          {showNotes ? <div className={'reservation-details'}>{this.props.reservation.notes}</div> : ''}
        </Table.Cell>
        <Table.Cell collapsing style={{textAlign: 'right'}}>
          {this.state.editing ? this.renderEditingButtons() : ''}
          {!this.state.editing ? <Button icon={icon} onClick={this.addClicked} disabled={disabled}/> : ''}
        </Table.Cell>
      </Table.Row>
    )
  }

  renderEditingButtons() {
    return (
      <React.Fragment>
        <Button icon={'minus'} onClick={this.removePersonClicked.bind(this)}
                disabled={this.props.reservation.number_of_persons <= 1}/>
        <Button icon={'plus'} onClick={this.addPersonClicked.bind(this)}
                disabled={this.props.show.available_tickets_count <= 0}/>
      </React.Fragment>
    )
  }

  toggleEditing() {
    this.setState(state => ({editing: !state.editing}));
  }

  addPersonClicked() {
    if (this.props.onAddPerson) this.props.onAddPerson(this.props.reservation);
  }

  removePersonClicked() {
    if (this.props.onRemovePerson) this.props.onRemovePerson(this.props.reservation);
  }

  addClicked() {
    if (this.props.onAdd) this.props.onAdd(this.props.reservation);
  }

  deleteClicked = (t) => {
    if (confirmDelete(t, 'reservation')) {
      this.props.store.reservationStore.delete(this.props.reservation.id, {params: {show_id: this.props.show.id}});
    }
  }
}

export default compose(
  loadEntities('reservationStore', 'reservations', {
    storeParams: (props) => ({params: {show_id: props.show.id}}),
    showLoader: false
  }),
  withNamespaces('translation'),
  inject('store'),
  observer
)(ReservationListItem);