import * as React from "react";
import {Link} from "react-router-dom";
import {staffRoute} from "../../../routing/routing";
import {Image, Menu} from "semantic-ui-react";
import cirqu_logo from "../../../assets/images/cirqu_logo.svg";

class HomeMenuItem extends React.Component {
  render() {
    return (
      <Menu.Item as={Link} to={staffRoute('/')} header className={'brand'}>
        <Image src={cirqu_logo}/>
      </Menu.Item>
    );
  }
}

export default HomeMenuItem;
