import {BaseModel} from "./base";

export class FestivalPassCategory extends BaseModel {
  name = '';
  starts_at = null;
  starts_at_formatted = null;
  ends_at = null;
  ends_at_formatted = null;
  festival = null;

  static fromPlainObject(plainObject) {
    const festivalPassCategory = new FestivalPassCategory();
    return Object.assign(festivalPassCategory, plainObject);
  }
}
