import * as React from "react";
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import {loadEntities} from "../admin/shared/loadEntities";
import CartItem from "./cartItem";
import {Link} from "react-router-dom";
import {Button, Grid, Message, Table} from "semantic-ui-react";
import CheckoutSteps from "./checkoutSteps";

class Cart extends React.Component {
  componentDidMount() {
    if (sessionStorage.getItem('cart') != null) {
      this.props.store.cartStore.load(sessionStorage.getItem('cart'))
    }
  }

  render() {
    const {shows} = this.props;
    const cart = this.props.store.cartStore.carts.find(cart => cart.number === sessionStorage.getItem('cart'));

    return (
      <>
        <CheckoutSteps active={'cart'}/>
        <Grid>
          {cart && cart.cart_tickets.length > 0 ?
            <>
              <Grid.Row>
                <Grid.Column>
                  <Table style={{fontSize: '1.2em'}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Vorstellung</Table.HeaderCell>
                        <Table.HeaderCell>Veranstaltungsort</Table.HeaderCell>
                        <Table.HeaderCell>Eventdatum</Table.HeaderCell>
                        <Table.HeaderCell>Anzahl Tickets</Table.HeaderCell>
                        <Table.HeaderCell/>
                        <Table.HeaderCell textAlign={'right'}>Preis</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {cart ? cart.cart_tickets.map((cartTicket) =>
                          <CartItem key={cartTicket.id} cart={cart} shows={shows} cartItem={cartTicket} editable={true}/>
                        )
                        : null
                      }
                    </Table.Body>
                    {cart.cart_tickets.length >= 2 ?
                      <Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell>Total</Table.HeaderCell>
                          <Table.HeaderCell/>
                          <Table.HeaderCell/>
                          <Table.HeaderCell
                            textAlign={'left'}>{cart.cart_tickets.reduce((a, b) => a + (b["quantity"] || 0), 0)} Tickets</Table.HeaderCell>
                          <Table.HeaderCell/>
                          <Table.HeaderCell textAlign={'right'}>{cart.formatPrice()}</Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                      : null
                    }
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </>
            : <Grid.Row>
              <Grid.Column>
                <Message>
                  Leider befinden sich keine Tickets in Ihrem Warenkorb
                </Message>
              </Grid.Column>
            </Grid.Row>
          }
        </Grid>

        <Grid style={{marginTop: 40}}>
          <Grid.Row>
            <Grid.Column textAlign={'right'}>
              <Link to={'/checkout/address'}>
                <Button
                  disabled={cart && cart.cart_tickets.length <= 0}
                  primary
                >
                  Weiter
                </Button>
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  }

}

export default compose(
  loadEntities('showStore', 'shows'),
  withNamespaces('translation'),
  inject('store'),
  observer
)(Cart);