import * as React from "react";
import {BackButton} from "../admin/shared/buttons";
import {staffRoute} from "../../routing/routing";
import compose from "compose-function";
import {inject, observer} from "mobx-react";
import {loadEntity} from "../admin/shared/loadEntity";
import {loadEntities} from "../admin/shared/loadEntities";
import {withNamespaces} from "react-i18next";
import ReservationsTabs from './reservationsTabs';

const ReservationsView = (props) => {

  const {show, reservations = []} = props;

  return (
    <div className={'reservations-view'}>
      <BackButton route={staffRoute(`/entry/shows/${show.id}`)} style={{margin: '0'}}/>
      <ReservationsTabs show={show} reservations={reservations.filter(r => r.already_paid)}/>
    </div>
  )
};

export default compose(
  withNamespaces('translation'),
  loadEntity('showStore', 'show', staffRoute('/entry'), {lazyLoad: true}),
  loadEntities('reservationStore', 'reservations', {
    storeParams: (props) => ({params: {show_id: props.show.id}}),
    autoUpdate: true
  }),
  inject('store'),
  observer
)(ReservationsView);
