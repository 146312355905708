import * as React from "react";
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Dimmer, Form, Grid, Loader, Segment} from "semantic-ui-react";
import CheckoutSteps from "./checkoutSteps";

class CartContactForm extends React.Component {
  state = {
    firstname: '',
    lastname: '',
    street: '',
    house: '',
    zipcode: '',
    city: '',
    email: '',
    check: false
  }

  componentDidMount() {
    if (sessionStorage.getItem('cart') != null) {
      this.props.store.cartStore.load(sessionStorage.getItem('cart')).then((response) => {
        if (response.address !== null) {
          this.setState(response.address)
        }
      })
    }
  }

  render() {
    const {firstname, lastname, street, house, zipcode, city, email, check} = this.state
    const cart = this.props.store.cartStore.carts.find(cart => cart.number === sessionStorage.getItem('cart'));

    const handleChange = (e, {name, value}) => this.setState({[name]: value})

    const handleCheckboxChange = (e, data) => this.setState({'check': data.checked})

    const handleSubmit = () => {
      if (cart && cart.address === null) {
        this.props.store.addressStore.create({
          firstname,
          lastname,
          street,
          house,
          zipcode,
          city,
          email,
          check,
          'cart_id': cart.id
        }).then(() => {
          this.props.store.cartStore.load(sessionStorage.getItem('cart')).then(() => this.props.history.push('/checkout/confirmation'))
        })
      } else {
        cart.address.firstname = this.state.firstname;
        cart.address.lastname = this.state.lastname;
        cart.address.city = this.state.city;
        cart.address.zipcode = this.state.zipcode;
        cart.address.street = this.state.street;
        cart.address.house = this.state.house;
        cart.address.email = this.state.email;
        cart.address.cart_id = cart.id;
        this.props.store.addressStore.update(cart.address).then(() => {
          this.props.store.cartStore.load(sessionStorage.getItem('cart')).then(() => this.props.history.push('/checkout/confirmation'))
        })
      }
    }

    return (
      <>
        <CheckoutSteps active={'address'}/>
        <Segment>
          {!cart ?
            <Dimmer active inverted>
              <Loader inverted content='Einen Moment bitte'/>
            </Dimmer>
            :
            <Grid columns={'equal'}>
              <Grid.Row>
                <Grid.Column>
                  <Form
                    onSubmit={handleSubmit}
                  >
                    <Form.Group>
                      <Form.Input
                        fluid
                        required
                        label={'Vorname'}
                        placeholder={'Vorname'}
                        name={'firstname'}
                        value={firstname}
                        width={8}
                        onChange={handleChange}
                      />
                      <Form.Input
                        fluid
                        required
                        label={'Nachname'}
                        placeholder={'Nachname'}
                        name={'lastname'}
                        value={lastname}
                        width={8}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Input
                        fluid
                        required
                        label={'Strasse'}
                        placeholder={'Strasse'}
                        width={12}
                        name={'street'}
                        value={street}
                        onChange={handleChange}
                      />
                      <Form.Input
                        fluid
                        required
                        label={'Hausnummer'}
                        placeholder={'Haus Nr.'}
                        name={'house'}
                        value={house}
                        width={4}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Input
                        fluid
                        required
                        label={'PLZ'}
                        placeholder={'PLZ'}
                        width={4}
                        name={'zipcode'}
                        value={zipcode}
                        onChange={handleChange}
                      />
                      <Form.Input
                        required
                        label={'Ort'}
                        placeholder={'Ort'}
                        width={12}
                        name={'city'}
                        value={city}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Input
                        required
                        label={'Email'}
                        placeholder={'joe@schmoe.com'}
                        width={16}
                        name={'email'}
                        value={email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Checkbox
                        required
                        style={{marginTop: 20}}
                        width={16}
                        name={'check'}
                        onChange={handleCheckboxChange}
                        label={{ children:
                          <>
                          Hiermit akzeptiere ich die <a target={'_blank'} href={'/agb'}>AGB</a> von Cirqu
                          </>
                        }}
                      >
                      </Form.Checkbox>
                    </Form.Group>
                    <Form.Button
                      disabled={!check}
                      floated={'right'}
                      primary
                    >
                      Weiter
                    </Form.Button>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          }
        </Segment>
      </>
    )
  }

}

export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(CartContactForm);