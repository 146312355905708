import {BaseModel} from "./base";

export class Reservation extends BaseModel {
  id = 0;
  show_id = 0;
  user_id = 0;
  ticket_id = 0;
  user = null;
  first_name = '';
  last_name = '';
  notes = '';
  already_paid = false;
  entered = false;
  tickets_picked_up = false;
  number_of_persons = 0;

  fullName() {
    return `${this.last_name} ${this.first_name}`
  }

  static fromPlainObject(plainObject) {
    const reservation = new Reservation();
    return Object.assign(reservation, plainObject);
  }
}
