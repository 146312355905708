import * as React from "react";
import AppMenu from "./menu";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {withRouter} from "react-router";
import FlashMessages from "../admin/shared/messages/flashMessages";

class AppLayout extends React.Component {

  render() {
    return (
      <AppMenu>
        <div className={'app-container ' + (this.props.store.configStore.isMobile ? 'mobile' : 'desktop')}>
          <FlashMessages/>
          {this.props.children}
        </div>
      </AppMenu>
    )
  }
}


export default compose(
  withRouter,
  inject('store'),
  observer
)(AppLayout);
