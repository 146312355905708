import {Menu} from "semantic-ui-react";
import HomeMenuItem from "../menu_items/homeMenuItem";
import AppSidebar from "./sidebar";
import * as React from "react";

function DesktopSidebar() {
  return <Menu className={"fixed-sidebar"} vertical inverted>
    <HomeMenuItem/>
    <AppSidebar/>
  </Menu>;
}

export default DesktopSidebar;