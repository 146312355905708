import * as React from "react";
import compose from "compose-function";
import {loadEntities} from "../admin/shared/loadEntities";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Card, Header, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {staffRoute} from "../../routing/routing";
import {BackButton} from "../admin/shared/buttons";

class PointOfSalesList extends React.Component {
  render() {
    const {t} = this.props;

    return <React.Fragment>
      <Header as='h1'>{t('pos.choose_pos')}</Header>
      <Card.Group itemsPerRow={3} stackable={true} className={'card-links'}>
        {this.props.salesPoints.map(salesPoint => {
          return <Card as={Link} to={staffRoute(`/pos/${salesPoint.id}`)} className={'card-link'} key={salesPoint.id}>
            <Card.Content>
              <Icon size='big' name={'ticket'}/>
              <Card.Header>{salesPoint.name}</Card.Header>
            </Card.Content>
          </Card>
        })}
      </Card.Group>
      <BackButton route={staffRoute('/')}/>
    </React.Fragment>
  }
}

export default compose(
  loadEntities('salesPointStore', 'salesPoints'),
  withNamespaces('translation'),
  inject('store'),
  observer
)(PointOfSalesList);
