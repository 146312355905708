import * as React from "react";
import {Table} from "semantic-ui-react";
import CartItem from "./cartItem";

export class PaymentOverview extends React.Component {

  render() {
    const {cart, shows} = this.props

    return (
      <Table style={{fontSize: '1.2em'}}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Vorstellung</Table.HeaderCell>
            <Table.HeaderCell>Veranstaltungsort</Table.HeaderCell>
            <Table.HeaderCell>Eventdatum</Table.HeaderCell>
            <Table.HeaderCell>Anzahl Tickets</Table.HeaderCell>
            <Table.HeaderCell textAlign={'right'}>Preis</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {cart ? cart.cart_tickets.map((cartTicket) =>
              <CartItem key={cartTicket.id} cart={cart} cartItem={cartTicket} shows={shows} editable={false}/>
            )
            : null
          }
        </Table.Body>
      </Table>
    )
  }
}