import * as React from "react";
import {withNamespaces} from "react-i18next";
import CustomForm from "../../forms/form";
import Text from "../../forms/text";
import {inject, observer} from "mobx-react";
import {Dropdown, Form} from "semantic-ui-react";
import {withRouter} from "react-router";
import compose from "compose-function";
import {SubmitButton} from "../shared/buttons";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import {FestivalPass} from "../../../models/festivalPass";
import {loadEntity} from "../shared/loadEntity";
import {adminRoute} from "../../../routing/routing";
import moment from "moment";
import loadEntities from "../shared/loadEntities";
import DateTimePicker from "../../forms/dateTimePicker";

class FestivalPassForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      selectedFestivalPassCategoryId: props.festivalPass ? props.festivalPass.festival_pass_category_id : null,
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.successCallback = this.successCallback.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  handleOnSubmit(festivalPass) {
    festivalPass = {
      ...festivalPass,
      id: this.props.festivalPass ? this.props.festivalPass.id : null,
      festival: this.props.festival,
      festival_id: this.props.festival.id,
      birth_date: moment(this.state.birth_date).format("YYYY-MM-DD"),
      festival_pass_category_id: this.state.selectedFestivalPassCategoryId,
    };

    if (this.props.festivalPass) {
      this.props.store.festivalPassStore.update(festivalPass).then(this.successCallback).catch(this.errorCallback);
    } else {
      this.props.store.festivalPassStore.create(festivalPass).then(this.successCallback).catch(this.errorCallback);
    }
  }

  errorCallback(formErrors) {
    this.setState({errors: formErrors});
  }

  successCallback() {
    this.setState({errors: {}});
    if (this.props.redirectTo) {
      this.props.history.push(this.props.redirectTo);
    }
  }

  handleSelectionChange = (e, {value}) => {
    this.setState({selectedFestivalPassCategoryId: value || null})
  }

  render() {
    // initialValues are not updated in the form once rendered, re-render does not update them either.
    const {t, festival, festivalPass = new FestivalPass()} = this.props;
    const festivalPassCategoryOptions = this.props.festivalPassCategories.filter(c => c.festival_id === festival.id).map((festival) => {
      return (
        {
          key: festival.id,
          value: festival.id,
          text: festival.name
        }
      )
    });

    return <CustomForm onSubmit={this.handleOnSubmit} initialValues={{...festivalPass}}>
      <Form.Field>
        <label htmlFor={'festival_pass_category_id'}>{t(i18nArAttr('festival_pass.category'))}</label>
        <Dropdown
          name="festival_pass_category_id"
          onChange={this.handleSelectionChange}
          selection
          options={festivalPassCategoryOptions}
          value={this.state.selectedFestivalPassCategoryId}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor={'first_name'}>{t(i18nArAttr('festival_pass.first_name'))}*</label>
        <Text field="first_name" errors={this.state.errors.first_name}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'last_name'}>{t(i18nArAttr('festival_pass.last_name'))}*</label>
        <Text field="last_name" errors={this.state.errors.last_name}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'email'}>{t(i18nArAttr('festival_pass.email'))}*</label>
        <Text field="email" errors={this.state.errors.email}/>
      </Form.Field>
      <label htmlFor={'birth_date'}>{t(i18nArAttr('festival_pass.birth_date'))}</label>
      <Form.Field>
        <DateTimePicker
          timeFormat={false}
          field="birth_date" errors={this.state.errors.birth_date}
          onChange={(v) => this.setState((prevState) => ({...prevState, birth_date: v}))}
        />
      </Form.Field>
      <SubmitButton style={{marginTop: 30}} loading={this.props.store.festivalStore.isLoading}/>
    </CustomForm>
  }
}

export default compose(
  loadEntity('festivalStore', 'festival', adminRoute('/festivals'), {paramName: 'festivalId'}),
  loadEntities('festivalPassCategoryStore', 'festivalPassCategories'),
  withRouter,
  withNamespaces('translation'),
  inject('store'),
  observer
)(FestivalPassForm);
