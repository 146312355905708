import * as React from 'react';
import {withNamespaces} from 'react-i18next';
import {inject, observer} from "mobx-react";
import {adminRoute} from "../../../routing/routing";
import compose from "compose-function";
import UserListItem from "./listItem";
import {LabeledIconLinkButton} from "../shared/buttons";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {loadEntities} from "../shared/loadEntities";

class UserList extends React.Component {

  render() {
    const {t} = this.props;
    const headers = [
      t(i18nArAttr('user.email')),
      t(i18nArAttr('user.first_name')),
      t(i18nArAttr('user.last_name')),
      t(i18nArAttr('user.admin')),
      ''
    ];

    return (
      <div>
        <LabeledIconLinkButton text={t(i18nArModel('user.one'))}
                               route={adminRoute(`/users/create`)}/>
        <EntityTable headers={headers}>
          {this.props.users.map((user) => <UserListItem key={user.id} user={user}/>)}
        </EntityTable>
      </div>
    )
  }
}

export default compose(
  loadEntities('userStore', 'users'),
  withNamespaces('translation'),
  inject('store'),
  observer
)(UserList);
