import * as React from "react";
import compose from "compose-function";
import UserForm from "./form";
import {inject, observer} from "mobx-react";
import {adminRoute} from "../../../routing/routing";
import {i18nArModel} from "@semabit/rails-i18n-tools";
import loadEntity from "../shared/loadEntity";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {withNamespaces} from "react-i18next";

class EditUser extends React.Component {
  render() {
    const {t} = this.props;

    return <EntityDetailLayout title={t('admin.entity.edit', {model_name: t(i18nArModel(`user.one`))})}
                               backPath={`/users/${this.props.match.params.id}`}>
      <UserForm redirectTo={adminRoute(`/users/${this.props.match.params.id}`)} user={this.props.user}/>
    </EntityDetailLayout>
  }
}

export default compose(
  loadEntity('userStore', 'user', adminRoute('/users')),
  withNamespaces('translation'),
  inject('store'),
  observer
)(EditUser);
