import * as React from "react";
import {
  Route,
  Switch
} from 'react-router-dom'
import NotFound from "../../notFound";
import NewFestivalPass from "./new";
import EditFestivalPass from "./edit";
import FestivalPassDetail from "./detail";


class Routes extends React.Component {
  render() {
    const match = this.props.match;
    return <Switch>
      <Route exact path={`${match.path}/create`} component={NewFestivalPass}/>
      <Route exact path={`${match.path}/:id/edit`} component={EditFestivalPass}/>
      <Route exact path={`${match.path}/:id`} component={FestivalPassDetail}/>
      <Route path={`${match.path}/*`} component={NotFound}/>
    </Switch>
  }
}

export default Routes;