import * as React from "react";
import SalesPointForm from "./form";
import NewEntity from "../shared/newEntity";

class NewSalesPoint extends React.Component {
  render() {
    return <NewEntity entitiesPath={'/sales_points'} name={'sales_point'} form={SalesPointForm}/>;
  }
}

export default NewSalesPoint;