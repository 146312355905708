import * as React from "react";
import compose from "compose-function";
import {loadEntities} from "../admin/shared/loadEntities";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import ReservationListItem from "./reservationListItem";
import {Checkbox, Header, Table} from "semantic-ui-react";
import {Ticket} from "../../models/ticket";
import ReservationSearchInput from "../admin/shared/reservationSearchInput";

class ReservationList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showAllReservations: false,
      'loading': false,
      'results': this.props.reservations
    };
  }

  render() {
    return (
      <div className={'reservation-list'}>
        <Header as={'h3'}>Reservationen</Header>
        <div>
          <Checkbox
            toggle
            label='Alle anzeigen'
            onChange={this.handleShowAllReservationsCheckboxClicked.bind(this)}
          />
        </div>
        <ReservationSearchInput reservations={this.props.reservations} onResultsChange={(results) => this.setState({'results': results})} />
        {this.props.reservations.length
          ? this.renderTable()
          : <p style={{fontWeight: 'bold', marginTop: 20}}><b>Es liegen keine Reservationen vor</b></p>
        }
      </div>
    )
  }

  renderTable() {
    return <Table compact>
      <Table.Body>
        {this.state.results.filter(res => this.state.showAllReservations || (!res.tickets_picked_up && !res.already_paid))
          .map((reservation) => {
          return (
            <ReservationListItem
              key={reservation.id}
              reservation={reservation}
              show={this.props.show}
              onAdd={(reservation) => this.addReservationToCart(reservation)}
              disabled={reservation.tickets_picked_up || reservation.already_paid}
              allowEdit={true}
              onAddPerson={(reservation) => this.addPersonToReservation(reservation)}
              onRemovePerson={(reservation) => this.removePersonFromReservation(reservation)}
            />
          );
        })}
      </Table.Body>
    </Table>
  }

  changeResults = (results) => {
    this.setState({'results': results})
  }

  addReservationToCart(reservation) {
    const receipt = this.props.store.receiptStore.current;

    const ticket = Ticket.fromPlainObject({
      show_id: reservation.show_id,
      price_id: null,
      receipt_id: receipt ? receipt.id : null,
      reservation_id: reservation.id,
      state: "created",
    });

    this.props.store.ticketStore
      .createMultiple(reservation.number_of_persons, ticket, {skipNotification: true})
      .then(() => {
        this.props.store.receiptStore.loadCurrent({params: {showId: this.props.show.id}});
        this.props.store.showStore.load(this.props.show.id);
        this.props.store.reservationStore.loadAll({params: {show_id: this.props.show.id}});
    }).catch(error => {
      this.props.store.messageStore.addMessage({type: 'error', title: error.joinMessages()});
    });
  }

  handleShowAllReservationsCheckboxClicked() {
    this.setState((state) => ({showAllReservations: !state.showAllReservations}))
  }

  removePersonFromReservation(reservation) {
    const payload = {...reservation, number_of_persons: reservation.number_of_persons - 1};
    this.props.store.reservationStore.update(payload, {skipNotification: true}).then(() => {
      this.props.store.showStore.load(this.props.show.id);
    });
  }

  addPersonToReservation(reservation) {
    const payload = {...reservation, number_of_persons: reservation.number_of_persons + 1};
    this.props.store.reservationStore.update(payload, {skipNotification: true}).then(() => {
      this.props.store.showStore.load(this.props.show.id);
    });
  }
}

export default compose(
  loadEntities('reservationStore', 'reservations', {
    storeParams: (props) => ({params: {show_id: props.show.id}}),
    showLoader: false
  }),
  withNamespaces('translation'),
  inject('store'),
  observer
)(ReservationList);
