import * as React from 'react';
import {withNamespaces} from 'react-i18next';
import {inject, observer} from "mobx-react";
import {adminRoute} from "../../../routing/routing";
import compose from "compose-function";
import PriceListItem from "./listItem";
import {LabeledIconLinkButton} from "../shared/buttons";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {loadEntities} from "../shared/loadEntities";

class PriceList extends React.Component {

  render() {
    const {t} = this.props;
    const headers = [
      t(i18nArAttr('price.price')),
      t(i18nArAttr('price.price_type')),
      t(i18nArAttr('price.internal')),
      ''
    ];

    return (
      <div>
        <LabeledIconLinkButton
          text={t(i18nArModel('price.one'))}
          route={adminRoute(`/shows/${this.props.show.id}/prices/create`)}
        />
        <EntityTable headers={headers}>
          {this.props.prices.map((price) => <PriceListItem key={price.id} price={price} show={this.props.show}/>)}
        </EntityTable>
      </div>
    )
  }
}

export default compose(
  loadEntities('priceStore', 'prices', {
    storeParams: (props) => ({params: {showId: props.show.id}})
  }),
  withNamespaces('translation'),
  inject('store'),
  observer
)(PriceList);
