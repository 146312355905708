import * as React from 'react';
import {withNamespaces} from 'react-i18next';
import {inject, observer} from "mobx-react";
import {adminRoute} from "../../../routing/routing";
import compose from "compose-function";
import ShowListItem from "./listItem";
import {LabeledIconLinkButton} from "../shared/buttons";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {loadEntities} from "../shared/loadEntities";

class ShowList extends React.Component {

  render() {
    const {t} = this.props;

    const headers = [
      t(i18nArAttr('show.name')),
      t(i18nArModel('festival.one')),
      t(i18nArAttr('show.location')),
      t(i18nArAttr('show.begins_at')),
      t(i18nArAttr('show.available_tickets_count')),
      t(i18nArAttr('show.max_tickets_available_for_presale_count')),
      t(i18nArAttr('show.presale_starts_at')),
      t(i18nArAttr('show.presale_ends_at')),
      ''
    ];

    return (
      <div>
        <LabeledIconLinkButton text={t(i18nArModel('show.one'))}
                               route={adminRoute(`/shows/create`)}/>
        <EntityTable headers={headers}>
          {this.props.shows.map(
            (show) =>
              <ShowListItem
                key={show.id}
                show={show}
                festival={this.props.festivals.find((festival) => festival.id === show.festival_id)}
              />
          )}
        </EntityTable>
      </div>
    )
  }
}

export default compose(
  loadEntities('festivalStore', 'festivals'),
  loadEntities('showStore', 'shows', {
    storeParams: () => ({params: {current_festival: true}})
  }),
  withNamespaces('translation'),
  inject('store'),
  observer
)(ShowList);
