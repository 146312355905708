import * as React from "react";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import DesktopSidebar from "./desktopMenu";
import MobileSidebar from "./mobileMenu";
import NavigationBar from "./navbar";

class AppMenu extends React.Component {

  render() {
    if (!this.props.store.configStore.sidebarEnabled) {
      return <React.Fragment><NavigationBar/>{this.props.children}</React.Fragment>
    }

    return this.props.store.configStore.isMobile ? <MobileSidebar>{this.props.children}</MobileSidebar> :
      <DesktopSidebar>{this.props.children}</DesktopSidebar>
  }

}


export default compose(
  inject('store'),
  observer
)(AppMenu);
