import * as React from "react";
import {loadEntities} from "../admin/shared/loadEntities";
import compose from "compose-function";
import {NavLink} from "react-router-dom";
import {staffRoute} from "../../routing/routing";
import {Label, Menu} from "semantic-ui-react";
import {withRouter} from "react-router";
import {observer} from "mobx-react";
import moment from "moment";

class Sidebar extends React.Component {

  constructor(props) {
    super(props);

    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
  }

  render() {
    const {match} = this.props;

    return <div className={'shows-list-sidebar'}>
      {this.props.shows.map(show => {
        return <Menu.Item as={NavLink} exact to={staffRoute(`/pos/${match.params.posId}/shows/${show.id}`)}
                          onClick={(event) => this.handleMenuItemClick(event, show)} key={show.id}>
          <Label size={'tiny'} color={show.available_tickets_count ? 'green' : 'red'} style={{marginLeft: '5px'}}>
            {show.available_tickets_count}
          </Label>
          <div>{show.name}</div>
          <div className={'show-begin'}>{moment(show.begins_at).format()}</div>
        </Menu.Item>
      })}
    </div>;
  }

  handleMenuItemClick(event, show) {
    this.setState({show});
    if (this.props.onNavigate) {
      this.props.onNavigate(event, show)
    }
  }
}

export default compose(
  loadEntities('showStore', 'shows', {
    lazyLoad: true,
    storeParams: () => ({params: {current: true}})
  }),
  withRouter,
  observer
)(Sidebar);
