import * as React from "react";
import {Form as InformedForm} from "informed";
import {addCssClass} from "./utils";

class Form extends React.Component {
  render() {
    let {children, className = '', ...other} = this.props;
    className = addCssClass(className, 'ui form');

    return <InformedForm {...other} className={className}>{children}</InformedForm>
  }
}

export default Form;
