import * as React from "react";
import {withNamespaces} from "react-i18next";
import CustomForm from "../../forms/form";
import Text from "../../forms/text";
import {inject, observer} from "mobx-react";
import {Form} from "semantic-ui-react";
import {withRouter} from "react-router";
import compose from "compose-function";
import {SubmitButton} from "../shared/buttons";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import Checkbox from "../../forms/checkbox";

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {errors: {}};
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.successCallback = this.successCallback.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  handleOnSubmit(user) {
    user = {
      password: '',
      password_confirmation: '',
      ...user,
      id: this.props.user ? this.props.user.id : null
    };

    if (this.props.user) {
      this.props.store.userStore.update(user).then(this.successCallback).catch(this.errorCallback);
    } else {
      this.props.store.userStore.create(user).then(this.successCallback).catch(this.errorCallback);
    }
  }

  errorCallback(formErrors){
    this.setState({errors: formErrors});
    console.error({formErrors});
  }

  successCallback(user) {
    this.setState({errors: {}});
    if (this.props.redirectTo) {
      this.props.history.push(this.props.redirectTo);
    }
  }

  render() {
    // initialValues are not updated in the form once rendered, re-render does not update them either.
    const {t, user = {}} = this.props;
    return <CustomForm onSubmit={this.handleOnSubmit} initialValues={{...user}}>
      <Form.Field>
        <label htmlFor={'email'}>{t(i18nArAttr('user.email'))}*</label>
        <Text type={'email'} field="email" errors={this.state.errors.email}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'first_name'}>{t(i18nArAttr('user.first_name'))}</label>
        <Text field="first_name" errors={this.state.errors.first_name}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'last_name'}>{t(i18nArAttr('user.last_name'))}</label>
        <Text field="last_name" errors={this.state.errors.last_name}/>
      </Form.Field>
      <Form.Field>
        <Checkbox field="admin" label={t(i18nArAttr('user.admin'))} errors={this.state.errors.admin}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'password'}>{t(i18nArAttr('user.password'))}*</label>
        <Text type={'password'} field="password" errors={this.state.errors.password}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'password_confirmation'}>{t(i18nArAttr('user.password_confirmation'))}*</label>
        <Text type={'password'} field="password_confirmation" errors={this.state.errors.password_confirmation}/>
      </Form.Field>
      <SubmitButton loading={this.props.store.userStore.isLoading}/>
    </CustomForm>
  }
}

export default compose(
  withRouter,
  withNamespaces('translation'),
  inject('store'),
  observer
)(UserForm);
