import * as React from "react";
import compose from "compose-function";
import FestivalForm from "./form";
import {inject, observer} from "mobx-react";
import {adminRoute} from "../../../routing/routing";
import {i18nArModel} from "@semabit/rails-i18n-tools";
import loadEntity from "../shared/loadEntity";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {withNamespaces} from "react-i18next";

class EditFestival extends React.Component {
  render() {
    const {t} = this.props;

    return <EntityDetailLayout title={t('admin.entity.edit', {model_name: t(i18nArModel(`festival.one`))})}
                               backPath={`/festivals/${this.props.match.params.id}`}>
      <FestivalForm redirectTo={adminRoute(`/festivals/${this.props.match.params.id}`)} festival={this.props.festival}/>
    </EntityDetailLayout>
  }
}

export default compose(
  loadEntity('festivalStore', 'festival', adminRoute('/festivals')),
  withNamespaces('translation'),
  inject('store'),
  observer
)(EditFestival);
