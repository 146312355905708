import * as React from "react";
import compose from "compose-function";
import {inject, observer} from "mobx-react";
import {Route, Switch} from "react-router-dom";
import NotFound from "../notFound";
import PosList from "./posList";
import PosView from "./posView";
import {loadEntities} from "../admin/shared/loadEntities";
import {Redirect} from "react-router";

class PointOfSale extends React.Component {
  render() {
    const {match} = this.props;

    const defaultShowId = this.props.shows.length ? this.props.shows[0].id : null;

    return <Switch>
      <Route exact path={`${match.path}`} component={PosList}/>
      {defaultShowId ?
        <Redirect exact from={`${match.path}/:posId`} to={`${match.path}/:posId/shows/${defaultShowId}`}/> : ''}
      <Route exact path={`${match.path}/:posId/shows/:id`} component={PosView}/>
      <Route path={`${match.path}/*`} component={NotFound}/>
    </Switch>
  }
}

export default compose(
  loadEntities('showStore', 'shows', {
    storeParams: () => ({params: {current: true}})
  }),
  inject('store'),
  observer
)(PointOfSale);