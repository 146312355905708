import * as React from "react";

export const DummyComponent = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>
};

export const replaceIf = (replace) => {
  return (WrappedComponent, Replacement = DummyComponent) => {
    return replace ? Replacement : WrappedComponent;
  }
};

export const replaceInStaging = (WrappedComponent, Replacement = DummyComponent) => {
  return replaceIf(window.location.host.indexOf('staging') >= 0)(WrappedComponent, Replacement);
};
