import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {Container, Menu} from "semantic-ui-react";
import {inject, observer} from "mobx-react";
import SidebarToggle from "./menu_items/sidebarToggle";
import HomeMenuItem from "./menu_items/homeMenuItem";
import UserMenu from "./menu_items/userMenu";
import compose from "compose-function";

class NavigationBar extends React.Component {

  render() {
    let additionalClasses = [];
    if (!this.props.store.configStore.sidebarEnabled) {
      additionalClasses.push('no-sidebar')
    }
    additionalClasses.push((this.props.store.configStore.isMobile ? 'mobile' : 'desktop'));

    const showHomeMenuItem = this.props.store.configStore.isMobile || !this.props.store.configStore.sidebarEnabled;

    return (
      <Menu id={'navbar'} className={'fixed ' + additionalClasses.join(' ')}>
        {this.props.store.configStore.isMobile && this.props.store.configStore.sidebarEnabled ? <SidebarToggle/> : ''}
        <Container>
          {showHomeMenuItem ? <HomeMenuItem/> : ''}
          <Menu className={'right'}>
            <UserMenu/>
          </Menu>
        </Container>
      </Menu>
    );
  }
}


export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(NavigationBar);
