import * as React from "react";
import compose from "compose-function";
import {inject, observer} from "mobx-react";
import {Loader} from "semantic-ui-react";
import deepEqual from "deep-equal";

export const loadEntities = (storeName, propertyName, options = {}) => {
  return (WrappedComponent) => {
    class LoadEntitiesHOC extends React.Component {

      componentDidMount() {
        let params = this.getStoreParams(this.props);

        if (!options.lazyLoad || this.props.store[storeName][propertyName].length === 0) {
          this.load(params);
        }

        if (options.autoUpdate === true) {
          this.interval = setInterval(() => {
            this.load(params);
          }, options.autoUpdateInterval || 2000)
        }
      }

      render() {
        const showLoader = options.hasOwnProperty('showLoader') ? options.showLoader : true;
        if (showLoader && this.props.store[storeName].isActionInProgress('loadAll') && !options.autoUpdate) {
          return <Loader active inline className={'centered'}/>
        }

        const props = {
          [propertyName]: this.props.store[storeName][propertyName],
          ...this.props
        };

        return <WrappedComponent {...props}/>
      }

      componentDidUpdate(prevProps, prevState, snapshot) {
        const newParams = this.getStoreParams(this.props);
        const oldParams = this.getStoreParams(prevProps);
        if (!deepEqual(newParams, oldParams)) {
          this.load(newParams);
        }
      }

      componentWillUnmount() {
        if (this.interval) {
          clearInterval(this.interval);
        }
      }

      load(params) {
        this.props.store[storeName].loadAll(params);
      }

      getStoreParams(props) {
        if (options.storeParams) {
          return options.storeParams(props);
        }
        return {};
      }

    }

    LoadEntitiesHOC.displayName = `LoadEntities(${WrappedComponent.displayName})`;

    return compose(inject('store'), observer)(LoadEntitiesHOC);
  }
};

export default loadEntities;
