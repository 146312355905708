import * as React from "react";
import NewEntity from "../shared/newEntity";
import FestivalPassCategoryForm from "./form";

class NewFestivalPassCategory extends React.Component {
  render() {

    return (
      <NewEntity
        entitiesPath={'/festival_pass_categories'}
        name={'festival_pass_category'}
        form={FestivalPassCategoryForm}
        titleI18nKey={'admin.entity.new.n'}
      />
    );
  }
}

export default NewFestivalPassCategory;