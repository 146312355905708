import {observable, decorate, action} from "mobx";
import {Message} from "../models/message";
import {uniqueId} from "lodash";

decorate(Message, {
  type: observable,
  message: observable,
  title: observable
});

export class MessageStore {

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  messages = [];

  addMessage(message) {
    message.id = uniqueId();
    this.messages.push(message);
  }

  removeMessage(message) {
    const index = this.messages.indexOf(message);
    if (index >= 0) {
      this.messages.splice(index, 1);
    }
  }
}

decorate(MessageStore, {
  messages: observable,
  addMessage: action,
  removeMessage: action
});