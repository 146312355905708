import * as React from "react";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {loadEntity} from "../admin/shared/loadEntity";
import {staffRoute} from "../../routing/routing";
import {withNamespaces} from "react-i18next";
import {Divider, Grid, Header} from "semantic-ui-react";
import TicketCatalog from "./ticketCatalog";
import TicketCart from "./ticketCart";
import TicketStatistics from "./ticketStatistics";
import ReservationList from "./reservationList";
import {ClearFloat} from "../shared/clearfloat";
import {loadEntities} from "../admin/shared/loadEntities";
import ReceiptList from "./receiptList";

class PointOfSalesView extends React.Component {
  componentDidMount() {
    this.props.store.configStore.sidebarEnabled = true;
  }

  render() {

    return <div className={'pos'}>
      <TicketStatistics show={this.props.show} autoUpdate={true}/>
      <Header as='h5' style={{marginBottom: 0, marginTop: 0}}>{this.props.salesPoint.name}</Header>
      <Header as='h1' style={{marginTop: 0}}>{this.props.show.name}</Header>
      <ClearFloat/>
      <Grid stackable style={{margin: 0}}>
        <Grid.Row>
          <Grid.Column width={10} style={{paddingLeft: 0}}>
            <TicketCatalog show={this.props.show} prices={this.props.prices}/>
            <Divider/>
            <TicketCart show={this.props.show} prices={this.props.prices}/>
            <Divider/>
            <ReceiptList show={this.props.show}/>
          </Grid.Column>
          <Grid.Column width={6} style={{paddingRight: 0}}>
            <ReservationList show={this.props.show}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  }
}

export default compose(
  loadEntity('showStore', 'show', staffRoute('/pos'), {lazyLoad: true}),
  loadEntity('salesPointStore', 'salesPoint', staffRoute('/pos'), {paramName: 'posId', lazyLoad: true}),
  loadEntities('priceStore', 'prices', {
    storeParams: (props) => ({params: {showId: props.show.id}})
  }),
  withNamespaces('translation'),
  inject('store'),
  observer,
)(PointOfSalesView);
