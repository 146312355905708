import {BaseModel} from "./base";
import {formatPrice} from "../formatters/priceFormatter";

export class Cart extends BaseModel {
  id = 0;
  number = "";
  expire_date = null;
  cart_tickets = null;
  total_price = 0;
  address = null;
  payment_required = null;

  formatPrice() {
    return formatPrice(this.total_price);
  }

  static fromPlainObject(plainObject) {
    const cart = new Cart();
    return Object.assign(cart, plainObject);
  }
}
