import {createEntity, executeDelete, executeGet, updateEntity} from "./base";

export const resourcesName = 'entries';
export const resourceName = 'entry';

const pathPrefix = (showId) => `shows/${showId}`;

export const EntriesApi = {
  all: ({showId}) => executeGet(pathPrefix(showId) + `/${resourcesName}`),
  byId: (id, {showId}) => executeGet(pathPrefix(showId) + `/${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, pathPrefix(entity.show_id) + `/${resourcesName}`, resourceName),
  update: (entity) => updateEntity(entity, pathPrefix(entity.show_id) + `/${resourcesName}/${entity.id}`, resourceName),
  destroy: (id, {showId}) => executeDelete(pathPrefix(showId) + `/${resourcesName}/${id}`)
};
