import {asField} from 'informed';
import withStyle from "./withStyle";
import compose from "compose-function";
import withDefaultId from "./withDefaultId";
import {Form} from "semantic-ui-react";
import React from "react";

const InformedText = asField(({fieldState, fieldApi, ...props}) => {
  const {value} = fieldState;
  const {setValue, setTouched} = fieldApi;
  const {onChange, onBlur, initialValue, forwardedRef, ...rest} = props;
  return (
    <Form.Input
      {...rest}
      ref={forwardedRef}
      value={value || initialValue || ''}
      onChange={(e, f) => {
        setValue(f.value);
        if (onChange) {
          onChange(e);
        }
      }}
      onBlur={e => {
        setTouched();
        if (onBlur) {
          onBlur(e);
        }
      }}
    />
  );
});

export default compose(
  withStyle,
  withDefaultId
)(InformedText);