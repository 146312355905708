import {BaseModel} from "./base";

export class FestivalPass extends BaseModel {
  id = 0;
  first_name = '';
  last_name = '';
  email = '';
  festival_id = null;
  birth_date = null;
  entry_allowed = null;

  fullName() {
    return `${this.last_name} ${this.first_name}`
  }

  static fromPlainObject(plainObject) {
    const festivalPass = new FestivalPass();
    return Object.assign(festivalPass, plainObject);
  }
}
