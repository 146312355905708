import * as React from 'react';
import {withNamespaces} from 'react-i18next';
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {loadEntities} from "../shared/loadEntities";
import PresaleOrderListItem from "./listItem";
import {Header} from "semantic-ui-react";

class PresaleOrderList extends React.Component {

  render() {
    const {t, shows} = this.props;
    const headers = [
      '#',
      t(i18nArAttr('presale_order.bought_at')),
      t(i18nArAttr('presale_order.firstname')),
      t(i18nArAttr('presale_order.lastname')),
      t(i18nArAttr('presale_order.email')),
      t(i18nArAttr('presale_order.tickets')),
      ''
    ];

    return (
      <div>
        <Header style={{marginBottom: 40}} as={'h2'}>{t('activerecord.models.presale_order.other')}</Header>
        <EntityTable headers={headers}>
          {this.props.presaleOrders.map((presaleOrder) =>
            <PresaleOrderListItem key={presaleOrder.id}  shows={shows} presaleOrder={presaleOrder}/>
          )}
        </EntityTable>
      </div>
    )
  }
}

export default compose(
  loadEntities('showStore', 'shows', {
    storeParams: () => ({params: {current_festival: true}})
  }),
  loadEntities('presaleOrderStore', 'presaleOrders'),
  withNamespaces('translation'),
  inject('store'),
  observer
)(PresaleOrderList);
