import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {Table} from "semantic-ui-react";
import compose from "compose-function";
import {adminRoute} from "../../../routing/routing";
import {DetailsButton} from "../shared/buttons";
import {i18nBool} from "@semabit/rails-i18n-tools";
import {Link} from "react-router-dom";
import moment from "moment";
import {inject, observer} from "mobx-react";

class ReservationListItem extends React.Component {

  render() {
    return (
      <Table.Row>
        <Table.Cell>{this.props.reservation.first_name}</Table.Cell>
        <Table.Cell>{this.props.reservation.last_name}</Table.Cell>
        <Table.Cell>{this.props.t(i18nBool(this.props.reservation.already_paid))}</Table.Cell>
        {
          this.props.searchTable
            ? <Table.Cell>
              <Link to={adminRoute(`/shows/${this.props.show.id}`)}>
                {this.props.show.name}
              </Link>
              {this.props.show.begins_at ? " (" + moment(this.props.show.begins_at).format() + ")" : '-'}
            </Table.Cell>
            : <></>
        }
        <Table.Cell width={2}>{this.props.reservation.number_of_persons}</Table.Cell>
        <Table.Cell width={6}>{this.props.reservation.notes}</Table.Cell>
        <Table.Cell collapsing>
          <DetailsButton
            route={adminRoute(`/shows/${this.props.show.id}/reservations/${this.props.reservation.id}`)}/>
        </Table.Cell>
      </Table.Row>
    )
  }

}

export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(ReservationListItem);
