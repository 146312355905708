import * as React from "react";
import compose from "compose-function";
import {inject, observer} from "mobx-react";
import Message from "./flashMessage";

class FlashMessages extends React.Component {
  render() {
    return <React.Fragment>
      {this.props.store.messageStore.messages.map((m) => <Message message={m} key={m.id}/>)}
    </React.Fragment>
  }
}

export default compose(
  inject('store'),
  observer
)(FlashMessages);
