import {rejectProperties} from "../tools/attributeFilter";

export class ApiError {
  static fromPlainObject(plainObject) {
    const errors = new ApiError();
    return Object.assign(errors, plainObject);
  }

  joinMessages(options = {}) {
    const joinStr = options.joinStr || ', ';
    const errorMessages = [];
    for (const property in this) {
      if (this.hasOwnProperty(property)) {
        let value = this[property];
        if (value && ((value.length && typeof value[0] === 'string') || typeof value === 'string')) {
          errorMessages.push(value);
        }
      }
    }

    return errorMessages.join(joinStr);
  }

  getSpecialErrorsForModel(model) {
    return ApiError.fromPlainObject(rejectProperties(this, Object.keys(model)));
  }
}
