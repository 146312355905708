import {BaseModel} from "./base";
import {Ticket} from "./ticket";
import {formatPrice} from "../formatters/priceFormatter";

export class Receipt extends BaseModel {
  user_id = 0;
  state = '';
  sales_point_id = 0;
  total_price = 0;
  tickets = [];
  tickets_count = 0;
  tickets_not_canceled_count = 0;
  created_at = '';
  updated_at = '';

  formatPrice() {
    return formatPrice(this.total_price);
  }

  get groupedTickets() {
    if (!this.tickets) {
      return [];
    }
    // group by reservation id
    const reservationIds = Array.from(new Set(this.tickets.filter(t => !!t.reservation_id).map(t => t.reservation_id)));
    let groupedTickets = reservationIds.map(id => this.tickets.filter(t => t.reservation_id === id));

    // group remaining by price_id
    const priceIds = Array.from(new Set(this.tickets.map(t => t.price_id)));
    groupedTickets.push(...priceIds.map(id => this.tickets.filter(t => t.price_id === id && !t.reservation_id)));
    groupedTickets = groupedTickets.filter(group => group.length > 0);

    return groupedTickets;
  }

  get isComplete() {
    return this.state === 'state_complete';
  }

  static fromPlainObject(plainObject) {
    const receipt = new Receipt();
    Object.assign(receipt, plainObject);

    if (receipt.tickets && receipt.tickets.length) {
      const tickets = [];
      for (const ticket of receipt.tickets) {
        tickets.push(Ticket.fromPlainObject(ticket));
      }
      receipt.tickets = tickets;
    }

    return receipt;
  }
}

export const receiptStates = ['new', 'complete'];
