import {createEntity, executeDelete, executeGet, updateEntity} from "./base";

export const resourcesName = 'presale_orders';
export const resourceName = 'presaleOrder';

export const presaleOrderResourcePath = (path = '') => {
  return `${resourcesName}` + path;
};

export const PresaleOrdersApi = {
  all: (params) => executeGet(presaleOrderResourcePath(), {params}),
  byId: (id) => executeGet(presaleOrderResourcePath() + `/${id}`),
  create: (entity) => createEntity(entity, resourcesName, resourceName),
  update: (entity) => updateEntity(entity, `${resourcesName}/${entity.id}`, resourceName),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`)
};
