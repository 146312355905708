import {decorate, observable} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {FestivalPassCategory} from "../models/festivalPassCategory";

decorate(FestivalPassCategory, {
  name: observable,
  starts_at: observable,
  ends_at: observable,
  festival: observable,
});

export class FestivalPassCategoryStore extends EntityStore {

  festivalPassCategories = [];

  constructor(rootStore) {
    super(rootStore, 'festivalPassCategories', Api.festivalPassCategories, FestivalPassCategory);
  }
}

decorate(FestivalPassCategoryStore, {
  festivalPassCategories: observable,
});
