import {createEntity, executeDelete, executeGet, updateEntity} from "./base";

export const resourcesName = 'cart_tickets';
export const resourceName = 'cart_ticket';

const pathPrefix = (cartId) => `carts/${cartId}`;

export const CartTicketsApi = {
  all: ({cartId}) => executeGet(pathPrefix(cartId) + `/${resourcesName}`),
  byId: (id, {cartId}) => executeGet(pathPrefix(cartId) + `/${resourcesName}/${id}`),
  create: ({cart_number, ...entity}) => createEntity(entity, pathPrefix(cart_number) + `/${resourcesName}`, resourceName),
  update: ({cart_number, ...entity}) => updateEntity(entity, pathPrefix(cart_number) + `/${resourcesName}/${entity.id}`, resourceName),
  destroy: ({cart_number, ...entity}) => executeDelete(pathPrefix(cart_number) + `/${resourcesName}/${entity.id}`),
};
