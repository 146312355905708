import * as React from "react";
import {Header, Table} from "semantic-ui-react";
import compose from "compose-function";
import {loadEntities} from "../admin/shared/loadEntities";
import {inject, observer} from "mobx-react";
import ReceiptListItem from "./receiptListItem";
import {downloadPdfFromUrl, showPdfFromUrl} from "../../tools/files";
import {resourcePath} from "../../middleware/apiPath";
import {receiptsResourcePath} from "../../middleware/endpoints/receipts";

class ReceiptList extends React.Component {
  render() {
    return (
      <div className={'receipt-list'}>
        <Header as={'h3'}>Ticket-Drucke</Header>
        {this.receipts.length ? this.renderTable() : <b>Es wurden noch keine Tickets verkauft</b>}
      </div>
    )
  }

  renderTable() {
    return <Table compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={3}/>
          <Table.HeaderCell>Anzahl Tickets</Table.HeaderCell>
          <Table.HeaderCell>Total CHF</Table.HeaderCell>
          <Table.HeaderCell>Erstellt am</Table.HeaderCell>
          <Table.HeaderCell/>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.receipts.map((receipt) => {
          return <ReceiptListItem
            key={receipt.id}
            tickets={this.props.tickets.filter(ticket => ticket.receipt_id === receipt.id)}
            receipt={receipt}
            show={this.props.show}
            onShow={this.showReceipt.bind(this)}
            onDownload={this.downloadReceipt.bind(this)}
          />;
        })}
      </Table.Body>
    </Table>
  }

  get receipts() {
    return this.props.receipts.filter(r => r.isComplete);
  }

  showReceipt(receipt) {
    showPdfFromUrl(resourcePath(receiptsResourcePath(this.props.show.id, `/${receipt.id}.pdf`)));
  }

  downloadReceipt(receipt) {
    downloadPdfFromUrl(resourcePath(receiptsResourcePath(this.props.show.id, `/${receipt.id}.pdf`)));
  }
}

export default compose(
  loadEntities('receiptStore', 'receipts', {
    storeParams: (props) => ({
      params: {showId: props.show.id, complete: true}
    }),
    showLoader: true,
    autoUpdate: true
  }),
  loadEntities('ticketStore', 'tickets', {
    storeParams: (props) => ({
      params: {showId: props.show.id}
    }),
    showLoader: true,
    autoUpdate: true
  }),
  inject('store'),
  observer
)(ReceiptList);
