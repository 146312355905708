import axios from "../axios";
import {resourcePath} from "../apiPath";

export const handleError = (e) => {
  if (e.response && e.response.data) {
    return Promise.reject(e.response.data);
  }
  return Promise.reject(e);
};

export const executeGet = (path, params = {}) => axios.get(resourcePath(path), params).then(({data}) => data).catch(handleError);

export const patchEntity= (path, params = {}) => axios.patch(resourcePath(path), params).then(({data}) => data).catch(handleError);

export const createEntity = (entity, path, paramName, additionalParams = {}) => {
  return axios.post(resourcePath(path), {[paramName]: entity, ...additionalParams}).then(({data}) => data).catch(handleError)
};

export const updateEntity = (entity, path, paramName) => {
  return axios.put(resourcePath(path), {[paramName]: entity}).then(({data}) => data).catch(handleError);
};

export const executeDelete = (path, params = {}) => {
  return axios.delete(resourcePath(path), params).then(({data}) => data).catch(handleError);
};
