import * as React from "react";
import {Input} from "semantic-ui-react";
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";

class ReservationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reservations: this.props.reservations,
      results: this.props.reservations,
      searchQuery: ''
    }
  }


  nullSafeToLower = (string) => {
    if(string == null) {
      string = ""
    }
    return string.toLowerCase()
  }

  render() {
    return (
      <Input style={{marginTop: 20}} icon='search' placeholder={'Reservation suchen'}
             onChange={this.handleSearchChange}/>
    )
  }


  handleSearchChange = (e) => {
    this.setState({
      searchQuery: e.target.value
    });

    const filtered = this.props.reservations.filter(reservation =>
      reservation.first_name.toLowerCase().includes(e.target.value.toLowerCase())
      || reservation.last_name.toLowerCase().includes(e.target.value.toLowerCase())
      || this.nullSafeToLower(reservation.notes).includes(e.target.value.toLowerCase())
    )
    this.props.onResultsChange(filtered)
  }
}

export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(ReservationSearchInput);
