import * as React from "react";
import QrReader from 'react-qr-reader'

class QRCodeReader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      qrValue: "No Data",
      delayScan: 1000
    };
  }

  handleScan = value => {
    if (value) {
      this.setState({
        qrValue: value
      })

      this.props.onSuccess(value)
    }
  }

  handleError = err => {
    console.error(err)
  }

  render() {
    return (
      <>
        <QrReader
          delay={this.state.delayScan}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{width: '100%'}}
        />
      </>
    )
  }

}

export default QRCodeReader;