import React from 'react'
import {Redirect, Route, withRouter} from 'react-router-dom'
import {inject, observer} from "mobx-react";
import {staffRoute} from "../../routing/routing";
import compose from "compose-function";
import {UserPolicy} from "../../policies/userPolicy";

class ProtectedRoute extends React.Component {

  render() {
    const {store, component: Component, ...rest} = this.props;
    const isAdmin = new UserPolicy(store).canAccessAdminSection();

    return <Route {...rest} render={props => this.renderRoute(Component, props, isAdmin)}/>
  }

  renderRoute(Component, props, isAdmin) {
    if (isAdmin) {
      return <Component {...props}/>;
    }

    return <Redirect to={{
      pathname: staffRoute('/'),
      state: {from: props.location}
    }}/>
  }
}


export default compose(
  withRouter,
  inject('store'),
  observer
)(ProtectedRoute);
