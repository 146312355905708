import {decorate, observable, runInAction, action, computed} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {Receipt} from "../models/receipt";

decorate(Receipt, {
  state: observable,
  tickets: observable,
  total_price: observable,
  groupedTickets: computed
});

export class ReceiptStore extends EntityStore {

  receipts = [];
  current = null;

  constructor(rootStore) {
    super(rootStore, 'receipts', Api.receipts, Receipt);
  }

  complete(receiptId, options = {}) {
    this.pendingRequests++;
    return this.api.complete(receiptId, options.params).then((entity) => {
      this.addMessage({type: 'success', title: 'messages.update_success'}, options);
      entity = this.modelClass.fromPlainObject(entity);
      runInAction(() => this.pendingRequests--);
      return this.replace(entity);
    }).catch(e => this.handleApiError(e));
  }

  loadCurrent(options = {}) {
    this.pendingRequests++;
    return this.api.current(options.params).then(entity => {
      if (entity) {
        entity = this.modelClass.fromPlainObject(entity);
        runInAction(() => {
            this.current = entity;
            this.add(entity);
            this.pendingRequests--;
          }
        );
      } else {
        runInAction(() => {
          this.current = null;
          this.pendingRequests--;
        });
      }
      return entity;
    }).catch(e => this.handleApiError(e));
  }
}

decorate(ReceiptStore, {
  receipts: observable,
  current: observable,
  loadCurrent: action
});
