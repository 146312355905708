import {action, computed, decorate, observable, runInAction} from "mobx";
import {ApiError} from "../models/apiError";

export class EntityStore {

  pendingRequests = 0;
  pendingActions = {
    loadAll: false,
    create: false,
    update: false
  };

  constructor(rootStore, entityNamePlural, api, modelClass) {
    this.rootStore = rootStore;
    this.listName = entityNamePlural;
    this.api = api;
    this.modelClass = modelClass;
    this.comparator = (id) => (e) => e.id.toString() === id.toString();
  }

  add(entity) {
    if (!entity) {
      return;
    }
    if (!this[this.listName].find(this.comparator(entity.id))) {
      this[this.listName].push(entity);
    } else {
      this.replace(entity);
    }
  }

  load(id, options = {}) {
    this.pendingRequests++;
    return this.api.byId(id, options.params).then(entity => {
      entity = this.modelClass.fromPlainObject(entity);
      runInAction(() => {
          this.add(entity);
          this.pendingRequests--;
        }
      );
      return entity;
    }).catch(e => this.handleApiError(e));
  }

  loadAll(options = {}) {
    if (this.pendingActions['loadAll']) {
      return;
    }
    this.pendingActions['loadAll'] = true;
    this.pendingRequests++;
    return this.api.all(options.params).then(entities => {
      entities = entities.map(e => this.modelClass.fromPlainObject(e));
      runInAction(() => {
        this[this.listName].replace(entities);
        this.pendingRequests--;
        this.pendingActions['loadAll'] = false;
      });
    }).catch(e => this.handleApiError(e, 'loadAll'));
  }

  getById(id) {
    if (!id) {
      return null;
    }
    return this[this.listName].find(this.comparator(id));
  }

  replace(entity) {
    const oldEntity = this.getById(entity.id);
    if (!oldEntity) {
      return null;
    }
    return oldEntity.copyAttributesFrom(entity);
  }

  remove(id) {
    if (!id) {
      return null;
    }
    const index = this[this.listName].findIndex(this.comparator(id));
    if (index >= 0) {
      this[this.listName].splice(index, 1);
    }
  }

  delete(id, options = {}) {
    this.pendingRequests++;
    return this.api.destroy(id, options.params).then((response) => {
      this.addMessage({type: 'success', title: 'messages.delete_success'}, options);
      runInAction(() => this.pendingRequests--);
      this.remove(id);
      return response;
    }).catch(e => this.handleApiError(e));
  }

  create(entity, options = {}) {
    const { action = 'create'} = options;
    this.pendingActions[action] = entity;
    this.pendingRequests++;
    return this.api.create(entity).then((entity) => {
      this.addMessage({type: 'success', title: 'messages.create_success'}, options);
      entity = this.modelClass.fromPlainObject(entity);
      runInAction(() => {
        this.pendingRequests--;
        this.pendingActions[action] = false;
      });
      this.add(entity);
      return entity;
    }).catch(e => this.handleApiError(e, action));
  }

  update(entity, options = {}) {
    const { action = 'update'} = options;
    this.pendingActions[action] = true;
    this.pendingRequests++;
    return this.api.update(entity).then((entity) => {
      this.addMessage({type: 'success', title: 'messages.update_success'}, options);
      entity = this.modelClass.fromPlainObject(entity);
      runInAction(() => {
        this.pendingRequests--;
        this.pendingActions[action] = false;
      });
      return this.replace(entity);
    }).catch(e => this.handleApiError(e, action));
  }

  get isLoading() {
    return this.pendingRequests > 0;
  }

  isActionInProgress(action) {
    return !!this.pendingActions[action];
  }

  actionState(action) {
    return this.pendingActions[action];
  }

  handleApiError(e, action) {
    if (action) {
      this.pendingActions[action] = false;
    }
    runInAction(() => this.pendingRequests--);
    return Promise.reject(ApiError.fromPlainObject(e));
  }

  addMessage(message, {skipNotification}) {
    if (!skipNotification) {
      this.rootStore.messageStore.addMessage(message);
    }
  }
}

decorate(EntityStore, {
  add: action,
  create: action,
  load: action,
  loadAll: action,
  remove: action,
  replace: action,
  update: action,
  pendingRequests: observable,
  pendingActions: observable,
  isLoading: computed,
});