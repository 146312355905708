import * as React from "react";
import {withNamespaces} from "react-i18next";
import CustomForm from "../../forms/form";
import Text from "../../forms/text";
import TextArea from "../../forms/textarea";
import {inject, observer} from "mobx-react";
import {Form} from "semantic-ui-react";
import {withRouter} from "react-router";
import compose from "compose-function";
import {SubmitButton} from "../shared/buttons";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import Checkbox from "../../forms/checkbox";
import {Reservation} from "../../../models/reservation";

class ReservationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {errors: {}};
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.successCallback = this.successCallback.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  handleOnSubmit(reservation) {
    reservation = {
      ...reservation,
      id: this.props.reservation ? this.props.reservation.id : null,
      show_id: this.props.show.id
    };
    if (this.props.reservation) {
      this.props.store.reservationStore.update(reservation).then(this.successCallback).catch(this.errorCallback);
    } else {
      this.props.store.reservationStore.create(reservation).then(this.successCallback).catch(this.errorCallback);
    }
  }

  errorCallback(formErrors) {
    const errorMessage = formErrors.getSpecialErrorsForModel(new Reservation()).joinMessages();
    if (errorMessage) {
      this.props.store.messageStore.addMessage({type: 'error', title: errorMessage});
    }
    this.setState({errors: formErrors});
  }

  successCallback(reservation) {
    this.setState({errors: {}});
    if (this.props.redirectTo) {
      this.props.history.push(this.props.redirectTo);
    }
  }

  render() {
    const {t, reservation = {}} = this.props;

    return <CustomForm onSubmit={this.handleOnSubmit} initialValues={{...reservation}}>
      <Form.Field>
        <label htmlFor={'first_name'}>{t(i18nArAttr('reservation.first_name'))}*</label>
        <Text field="first_name" errors={this.state.errors.first_name}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'last_name'}>{t(i18nArAttr('reservation.last_name'))}*</label>
        <Text field="last_name" errors={this.state.errors.last_name}/>
      </Form.Field>
      <Form.Field>
        <Checkbox field="already_paid" label={t(i18nArAttr('reservation.already_paid'))}
                  errors={this.state.errors.already_paid}/>
      </Form.Field>
      <Form.Field>
        <Text type={'number'} field="number_of_persons" label={t(i18nArAttr('reservation.number_of_persons'))}
                  errors={this.state.errors.number_of_persons}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'notes'}>{t(i18nArAttr('reservation.notes'))}</label>
        <TextArea field="notes" errors={this.state.errors.notes}/>
      </Form.Field>
      <SubmitButton loading={this.props.store.reservationStore.isLoading}/>
    </CustomForm>
  }
}

export default compose(
  withRouter,
  withNamespaces('translation'),
  inject('store'),
  observer
)(ReservationForm);
