import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {Table} from "semantic-ui-react";
import compose from "compose-function";
import {adminRoute} from "../../../routing/routing";
import {inject, observer} from "mobx-react";
import {DetailsButton} from "../shared/buttons";
import moment from "moment";
import {Link} from "react-router-dom";

class PresaleOrderListItem extends React.Component {

  render() {
    const {shows} = this.props
    const ticketCount = this.props.presaleOrder.tickets.reduce((allShows, ticket) => {
      if (ticket.show_id in allShows) {
        allShows[ticket.show_id]++;
      } else {
        allShows[ticket.show_id] = 1;
      }
      return allShows;
    }, {})

    return (
      <Table.Row>
        <Table.Cell>{this.props.presaleOrder.id}</Table.Cell>
        <Table.Cell>{this.props.presaleOrder.created_at ? moment(this.props.presaleOrder.created_at).format() : '-'}</Table.Cell>
        <Table.Cell>{this.props.presaleOrder.address.firstname}</Table.Cell>
        <Table.Cell>{this.props.presaleOrder.address.lastname}</Table.Cell>
        <Table.Cell>{this.props.presaleOrder.address.email}</Table.Cell>
        <Table.Cell>
          {Object.keys(ticketCount).map(showId => {
              let show = shows.find((s) => s.id.toString() === showId)
              return <p key={show.id}>
                <Link to={adminRoute(`/shows/${show.id}`)}>
                  <b>{show.name} </b>
                </Link>
                am {show.begins_at ? moment(show.begins_at).format() : '-'} ({ticketCount[showId]} Tickets)
              </p>
            }
          )}
        </Table.Cell>
        <Table.Cell collapsing>
          <DetailsButton
            route={adminRoute(`/presale_orders/${this.props.presaleOrder.id}`)}
          />
        </Table.Cell>
      </Table.Row>
    )
  }
}

export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(PresaleOrderListItem);
