import {BaseModel} from "./base";

export class Show extends BaseModel {
  id = 0;
  name = '';
  location = '';
  begins_at = null;
  ends_at = null;
  presale_starts_at = null;
  presale_ends_at = null;
  printer = '';
  tickets_count = 0;
  entries_quantity_sum = 0;
  festival_id = null;
  presale_active = null;
  max_available_tickets_count = 0;
  available_tickets_count = 0;
  max_tickets_available_for_presale_count = 0;
  available_presale_tickets_count = 0;
  description = null;
  group_name = '';
  past = false;

  static fromPlainObject(plainObject) {
    const show = new Show();
    return Object.assign(show, plainObject);
  }
}
