import {decorate, observable} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {Address} from "../models/address";

decorate(Address, {
  firstname: observable,
  lastname: observable,
  city: observable,
  zipcode: observable,
  street: observable,
  house: observable,
  email: observable,
});

export class AddressStore extends EntityStore {
  addresses = [];

  constructor(rootStore) {
    super(rootStore, 'addresses', Api.addresses, Address);
  }
}

decorate(AddressStore, {
  addresses: observable,
});
