import * as React from "react";
import {Button, Table} from "semantic-ui-react";
import moment from "moment";
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import {formatPrice} from "../../formatters/priceFormatter";
import {confirmDelete} from "../admin/shared/confirm";

class CartItem extends React.Component {
  componentDidMount() {
    this.props.shows.forEach((show) => {
      this.props.store.priceStore.loadAll({params: {showId: show.id}})
    })
  }

  handleMinus(item) {
    if(item.quantity > 1) {
      item.quantity -= 1;
      this.props.store.cartTicketStore.update(item).then(() => {
        this.props.store.cartStore.load(this.props.cart.number)
        this.props.store.showStore.load(item.show_id)
      })
    } else {
      this.handleDelete(item)
    }
  }

  handleAdd(item) {
    const show = this.props.shows.find(show => show.id === item.show_id)
    if (show.available_presale_tickets_count > 0) {
      item.quantity += 1;
      this.props.store.cartTicketStore.update(item).then(() => {
        this.props.store.cartStore.load(this.props.cart.number)
        this.props.store.showStore.load(item.show_id)
      })
    }
  }

  handleDelete(item) {
    if (confirmDelete(this.props.t, 'ticket')) {
      this.props.store.cartTicketStore.delete(item).then(() => {
        this.props.store.cartStore.load(this.props.cart.number)
      })
    }
  }

  render() {
    const {cartItem, shows, editable} = this.props
    const show = shows.find(show => show.id === cartItem.show_id)
    cartItem.cart_number = sessionStorage.getItem('cart')

    return (
      <Table.Row key={cartItem.id}>
        <Table.Cell>
          {show ? show.name : null}
        </Table.Cell>
        <Table.Cell>
          {show ? show.location : null}
        </Table.Cell>
        <Table.Cell>
          {show ? moment(show.begins_at).format() : null}
        </Table.Cell>
        <Table.Cell>
          {cartItem.quantity} Tickets
          {editable ?
            <>
              <Button icon='minus' style={{marginLeft: 40}} onClick={() => this.handleMinus(cartItem)}/>
              <Button icon='plus' onClick={() => this.handleAdd(cartItem)}/>
            </>
            : null
          }

        </Table.Cell>
        {editable ?
          <Table.Cell>
            <Button icon='trash' onClick={() => this.handleDelete(cartItem)}/>
          </Table.Cell>
          : null
        }
        <Table.Cell textAlign={'right'}>
          {formatPrice(cartItem.total_price)}
        </Table.Cell>
      </Table.Row>
    )
  }
}

export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(CartItem);