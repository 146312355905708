// useful when we need to add a global prefix (like a locale)

export const route = (path) => {
    return path;
};

export const staffRoute = (path) => {
    return route(`/staff${path}`);
};

export const adminRoute = (path) => {
    return staffRoute(`/admin${path}`);
};

export const routerPath = (match) => {
    return match.path === '/' ? '' : match.path;
};
