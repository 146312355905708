import * as React from 'react';
import {withNamespaces} from 'react-i18next';
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {LabeledIconLinkButton} from "../shared/buttons";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {loadEntities} from "../shared/loadEntities";
import {adminRoute} from "../../../routing/routing";
import FestivalPassCategoryListItem from "./listitem";

class FestivalPassCategoryList extends React.Component {

  render() {
    const {t} = this.props;
    const headers = [
      t(i18nArAttr('festival_pass_category.name')),
      t(i18nArModel('festival.one')),
      t(i18nArAttr('festival_pass_category.starts_at')),
      t(i18nArAttr('festival_pass_category.ends_at')),
      ''
    ];

    return (
      <div>
        <LabeledIconLinkButton
          text={t(i18nArModel('festival_pass_category.one'))}
          route={adminRoute(`/festival_pass_categories/create/`)}
        />
        <EntityTable headers={headers}>
          {this.props.festivalPassCategories.map((festivalPassCategory) =>
            <FestivalPassCategoryListItem
              key={festivalPassCategory.id}
              festivalPassCategory={festivalPassCategory}
            />
          )}
        </EntityTable>
      </div>
    )
  }
}

export default compose(
  loadEntities('festivalPassCategoryStore', 'festivalPassCategories'),
  withNamespaces('translation'),
  inject('store'),
  observer
)(FestivalPassCategoryList);
