import * as React from "react";
import compose from "compose-function";
import PriceForm from "./form";
import {inject, observer} from "mobx-react";
import {adminRoute} from "../../../routing/routing";
import {i18nArModel} from "@semabit/rails-i18n-tools";
import loadEntity from "../shared/loadEntity";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {withNamespaces} from "react-i18next";

class EditReservation extends React.Component {
  render() {
    const {t} = this.props;

    const reservationPath = `/shows/${this.props.show.id}/reservations/${this.props.match.params.id}`;

    return <EntityDetailLayout title={t('admin.entity.edit', {model_name: t(i18nArModel(`reservation.one`))})}
                               backPath={reservationPath}>
      <PriceForm redirectTo={adminRoute(reservationPath)} reservation={this.props.reservation} show={this.props.show}/>
    </EntityDetailLayout>
  }
}

export default compose(
  loadEntity('showStore', 'show', adminRoute('/shows'), {paramName: 'showId'}),
  loadEntity('reservationStore', 'reservation', (props) => adminRoute(`/shows/${props.show.id}`)),
  withNamespaces('translation'),
  inject('store'),
  observer
)(EditReservation);
