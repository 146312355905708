import * as React from "react";
import {withNamespaces} from "react-i18next";
import CustomForm from "../../forms/form";
import Text from "../../forms/text";
import {inject, observer} from "mobx-react";
import {Dropdown, Form} from "semantic-ui-react";
import {withRouter} from "react-router";
import compose from "compose-function";
import {SubmitButton} from "../shared/buttons";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import {FestivalPassCategory} from "../../../models/festivalPassCategory";
import {loadEntities} from "../shared/loadEntities";
import DateTimePicker from "../../forms/dateTimePicker";
import moment from "moment";

class FestivalPassCategoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      selectedFestivalId: props.festivalPassCategory ? props.festivalPassCategory.festival_id : null,
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.successCallback = this.successCallback.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  componentDidMount() {
    if(this.props.festivalPassCategory && this.props.festivalPassCategory.presale_image !== null) {
      this.setState({setNewImage: false})
    }
  }

  handleOnSubmit(festivalPassCategory) {
    festivalPassCategory = {
      ...festivalPassCategory,
      starts_at: moment(this.state.starts_at).format("YYYY-MM-DD"),
      ends_at: moment(this.state.ends_at).format("YYYY-MM-DD"),
      festival_id: this.state.selectedFestivalId,
    };

    if (this.props.festivalPassCategory) {
      this.props.store.festivalPassCategoryStore.update(festivalPassCategory).then(this.successCallback).catch(this.errorCallback);

    } else {
      this.props.store.festivalPassCategoryStore.create(festivalPassCategory).then(this.successCallback).catch(this.errorCallback);
    }
  }

  errorCallback(formErrors) {
    this.setState({errors: formErrors});
  }

  successCallback() {
    this.setState({errors: {}});
    if (this.props.redirectTo) {
      this.props.history.push(this.props.redirectTo);
    }
  }

  handleSelectionChange = (e, {value}) => {
    this.setState({selectedFestivalId: value || null})
  }

  render() {
    // initialValues are not updated in the form once rendered, re-render does not update them either.
    const {t, festivalPassCategory = new FestivalPassCategory()} = this.props;
    const festivalOptions = this.props.festivals.map((festival) => {
      return (
        {
          key: festival.id,
          value: festival.id,
          text: festival.name
        }
      )
    });

    return <CustomForm onSubmit={this.handleOnSubmit} initialValues={{...festivalPassCategory}}>
      <Form.Field>
        <label htmlFor={'festival_id'}>{t(i18nArAttr('show.festival'))}</label>
        <Dropdown
          name="festival_id"
          onChange={this.handleSelectionChange}
          selection
          options={festivalOptions}
          value={this.state.selectedFestivalId}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor={'name'}>{t(i18nArAttr('festival_pass_category.name'))}*</label>
        <Text field="name" errors={this.state.errors.name}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'starts_at'}>{t(i18nArAttr('festival_pass_category.starts_at'))}</label>
        <DateTimePicker
          timeFormat={false}
          field="starts_at" errors={this.state.errors.starts_at}
          onChange={(v) => this.setState((prevState) => ({...prevState, starts_at: v}))}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor={'ends_at'}>{t(i18nArAttr('festival_pass_category.ends_at'))}</label>
        <DateTimePicker
          timeFormat={false}
          field="ends_at" errors={this.state.errors.ends_at}
          onChange={(v) => this.setState((prevState) => ({...prevState, ends_at: v}))}
        />
      </Form.Field>
      <SubmitButton style={{marginTop: 30}} loading={this.props.store.festivalPassCategoryStore.isLoading}/>
    </CustomForm>
  }
}

export default compose(
  withRouter,
  loadEntities('festivalStore', 'festivals'),
  withNamespaces('translation'),
  inject('store'),
  observer
)(FestivalPassCategoryForm);
