import * as React from "react";
import {Dropdown, Header, Icon, Image} from "semantic-ui-react";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import profile_placeholder from "./../../../assets/images/profile_placeholder.png";

class UserMenu extends React.Component {
  render() {
    return <Dropdown item simple className={'right'} trigger={this.renderTrigger()}>
      <Dropdown.Menu>
        <Header>
          {this.props.store.authStore.user.email}
        </Header>
        <Dropdown.Item onClick={() => this.props.store.authStore.logout()}><Icon name='lock'/>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>;
  }

  renderTrigger() {
    return <React.Fragment>
      <Image src={profile_placeholder} avatar/>
    </React.Fragment>
  }
}

export default compose(
  inject('store'),
  observer
)(UserMenu);
