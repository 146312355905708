import {action, decorate, observable, runInAction} from "mobx";
import axios from "axios";
import config from "./../config";
import {User} from "../models/user";

decorate(User, {
  email: observable,
  uid: observable,
  accessToken: observable,
  expiry: observable,
  client: observable,
  first_name: observable,
  last_name: observable,
  admin: observable,
});

export class AuthStore {

  authenticated = false;
  user = new User();

  constructor(rootStore) {
    this.rootStore = rootStore;
    if (this.loadUser() && this.user.accessToken) {
      this.validateToken();
    }
  }

  login({email, password}) {
    return this.authenticate(email, password);
  }

  logout() {
    return this.signout();
  }

  updateAuthToken(token) {
    this.user.accessToken = token;
    this.storeUser();
  }

  authenticate(email, password) {
    return axios.post(`${config.AUTH_PATH}/sign_in`, {email, password}).then(response => {
      runInAction('loginSuccess', () => {
        this.user.id = response.data.data.id;
        this.user.email = response.data.data.email;
        this.user.admin = response.data.data.admin;
        this.user.uid = response.headers['uid'];
        this.user.accessToken = response.headers['access-token'];
        this.user.client = response.headers['client'];
        this.user.expiry = response.headers['expiry'];
        this.authenticated = true;
        this.storeUser();
      });
    });
  }

  validateToken() {
    return axios.get(`${config.AUTH_PATH}/validate_token`, {
      headers: this.getRequestHeaders()
    }).then(({data, headers}) => {
      const {admin, email, id} = data.data;
      runInAction('validateTokenSuccess', () => {
        this.user.id = id;
        this.user.admin = admin;
        this.user.email = email;
      });
      if (headers["access-token"]) {
        this.updateAuthToken(headers["access-token"]);
      } else {
        this.storeUser();
      }
      this.authenticated = true;
    }).catch(() => {
      this.authenticated = false;
    })
  }

  signout() {
    return axios.delete(`${config.AUTH_PATH}/sign_out`, {
      headers: this.getRequestHeaders()
    }).then(response => {
      this.user = new User();
      this.authenticated = false;
      this.storeUser();
    });
  }

  loadUser() {
    const user = localStorage.getItem('auth');
    if (user) {
      const {accessToken, email, uid, expiry, client, admin} = JSON.parse(user);
      this.user.accessToken = accessToken;
      this.user.uid = uid;
      this.user.email = email;
      this.user.expiry = expiry;
      this.user.client = client;
      this.user.admin = admin;
      this.authenticated = !!accessToken;
    }
    return !!user;
  }

  storeUser() {
    return localStorage.setItem('auth', JSON.stringify(this.user));
  }

  getRequestHeaders() {
    this.loadUser();

    if (!this.user) {
      return null;
    }
    return {
      'access-token': this.user.accessToken,
      'client': this.user.client,
      'uid': this.user.uid
    }
  }
}

decorate(AuthStore, {
  authenticated: observable,
  user: observable,
  login: action,
  logout: action,
  updateAuthToken: action,
  requestHeaders: action,
});
