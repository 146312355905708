import * as React from "react";
import {Route, Switch} from "react-router-dom";
import NotFound from "../notFound";
import {Redirect} from "react-router";
import UserRoutes from "./users/routes";
import ShowRoutes from "./shows/routes";
import ReservationRoutes from "./reservations/direct_routes";
import FestivalRoutes from "./festivals/routes";
import FestivalPassCategoryRoutes from "./festival_pass_category/routes";
import SalesPointRoutes from "./sales_points/routes";
import PresaleOrderRoutes from "./presale_orders/routes";
import {inject, observer} from "mobx-react";
import compose from "compose-function";

class Admin extends React.Component {

  componentDidMount() {
    this.props.store.configStore.sidebarEnabled = true;
  }

  render() {
    const match = this.props.match;

    return <Switch>
      <Redirect exact from={match.path} to={`${match.path}/shows`}/>
      <Route path={`${match.path}/users`} component={UserRoutes}/>
      <Route path={`${match.path}/shows`} component={ShowRoutes}/>
      <Route path={`${match.path}/festivals`} component={FestivalRoutes}/>
      <Route path={`${match.path}/festival_pass_categories`} component={FestivalPassCategoryRoutes}/>
      <Route path={`${match.path}/reservations`} component={ReservationRoutes}/>
      <Route path={`${match.path}/sales_points`} component={SalesPointRoutes}/>
      <Route path={`${match.path}/presale_orders`} component={PresaleOrderRoutes}/>
      <Route path={`${match.path}/*`} component={NotFound}/>
    </Switch>
  }
}

export default compose(inject('store'), observer)(Admin);
