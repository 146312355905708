import * as React from "react";
import PriceForm from "./form";
import NewEntity from "../shared/newEntity";
import compose from "compose-function";
import {loadEntity} from "../shared/loadEntity";
import {adminRoute} from "../../../routing/routing";

class NewPrice extends React.Component {
  render() {
    return <NewEntity entitiesPath={`/shows/${this.props.show.id}`} name={'price'} form={PriceForm} show={this.props.show}/>;
  }
}

export default compose(
  loadEntity('showStore', 'show', adminRoute('/shows'), {paramName: 'showId'})
)(NewPrice);
