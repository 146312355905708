import {decorate, observable} from "mobx";
import {Api} from "../middleware/api";
import {User} from "../models/user";
import {EntityStore} from "./entityStore";

decorate(User, {
  title: observable,
  description: observable,
});

export class UserStore extends EntityStore {

  users = [];

  constructor(rootStore) {
    super(rootStore, 'users', Api.users, User);
  }
}

decorate(UserStore, {
  users: observable,
});
