import {Menu, Sidebar} from "semantic-ui-react";
import AppSidebar from "./sidebar";
import * as React from "react";
import compose from "compose-function";
import {inject, observer} from "mobx-react";

function MobileSidebar(props) {
  return <Sidebar as={Menu} animation='push' inverted vertical
                  visible={props.store.configStore.sidebarVisible}>
    <AppSidebar onNavigate={props.onNavigate}/>
  </Sidebar>;
}

export default compose(
  inject('store'),
  observer
)(MobileSidebar);