import {decorate, observable} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {Price} from "../models/price";

decorate(Price, {
  price: observable,
  price_type: observable,
  internal: observable,
});

export class PriceStore extends EntityStore {

  prices = [];

  constructor(rootStore) {
    super(rootStore, 'prices', Api.prices, Price);
  }
}

decorate(PriceStore, {
  prices: observable,
});
