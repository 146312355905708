import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {EntityDetailTable, EntityDetailTableEntry} from "../shared/tables";
import {i18nArAttr, i18nArModel, i18nBool} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {DeleteButton, EditButton} from "../shared/buttons";
import {withRouter} from "react-router";
import {confirmDelete} from "../shared/confirm";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {loadEntity} from "../shared/loadEntity";

class PriceDetail extends React.Component {

  render() {
    const {t} = this.props;
    return (
      <EntityDetailLayout title={t(i18nArModel('price.one'))} backPath={`/shows/${this.props.show.id}`}>
        <EditButton route={adminRoute(`/shows/${this.props.show.id}/prices/${this.props.price.id}/edit`)}/>
        <DeleteButton onClick={() => this.delete()}/>
        <EntityDetailTable>
          <EntityDetailTableEntry
            name={t(i18nArModel('show.one'))}><b>{this.props.show.name}</b></EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('price.price'))}><b>{this.props.price.formatPrice()}</b></EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('price.internal'))}>{this.props.t(i18nBool(this.props.price.internal))}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('price.price_type'))}>{t(i18nArAttr(`price.price_types.${this.props.price.price_type}`))}</EntityDetailTableEntry>
        </EntityDetailTable>
      </EntityDetailLayout>
    )
  }

  delete() {
    if (confirmDelete(this.props.t, 'price')) {
      this.props.store.priceStore.delete(this.props.price.id, {params: {showId: this.props.show.id}}).then(() => {
        this.props.history.push(adminRoute(`/shows/${this.props.show.id}`));
      });
    }
  }
}

export default compose(
  loadEntity('showStore', 'show', adminRoute('/shows'), {paramName: 'showId'}),
  loadEntity('priceStore', 'price', (props) => adminRoute(`/shows/${props.show.id}`)),
  withRouter,
  withNamespaces('translation'),
  inject('store'),
  observer
)(PriceDetail);
