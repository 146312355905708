import * as React from "react";
import NewEntity from "../shared/newEntity";
import FestivalForm from "./form";

class NewFestival extends React.Component {
  render() {
    return (
      <NewEntity
        entitiesPath={'/festivals'}
        name={'festival'}
        form={FestivalForm}
        titleI18nKey={'admin.entity.new.n'}
      />
    );
  }
}

export default NewFestival;