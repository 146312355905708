import * as React from "react";
import compose from "compose-function";
import PriceForm from "./form";
import {inject, observer} from "mobx-react";
import {adminRoute} from "../../../routing/routing";
import {i18nArModel} from "@semabit/rails-i18n-tools";
import loadEntity from "../shared/loadEntity";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {withNamespaces} from "react-i18next";

class EditPrice extends React.Component {
  render() {
    const {t} = this.props;

    const pricePath = `/shows/${this.props.show.id}/prices/${this.props.match.params.id}`;

    return <EntityDetailLayout
      title={t('admin.entity.edit', {model_name: t(i18nArModel(`price.one`))})}
      backPath={pricePath}
    >
      <PriceForm redirectTo={adminRoute(pricePath)} price={this.props.price} show={this.props.show}/>
    </EntityDetailLayout>
  }
}

export default compose(
  loadEntity('showStore', 'show', adminRoute('/shows'), {paramName: 'showId'}),
  loadEntity('priceStore', 'price', (props) => adminRoute(`/shows/${props.show.id}`)),
  withNamespaces('translation'),
  inject('store'),
  observer
)(EditPrice);
