import {decorate, observable} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {PresaleOrder} from "../models/presaleOrder";

decorate(PresaleOrder, {
  address_id: observable,
  address: observable,
  tickets: observable,
  tickets_count: observable,
  festival: observable,
});

export class PresaleOrderStore extends EntityStore {

  presaleOrders = [];

  constructor(rootStore) {
    super(rootStore, 'presaleOrders', Api.presaleOrders, PresaleOrder);
  }
}

decorate(PresaleOrderStore, {
  presaleOrders: observable,
});
