import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {Label, Table} from "semantic-ui-react";
import compose from "compose-function";
import {inject, observer} from "mobx-react";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import moment from "moment";
import ReservationLink from "../reservations/link";
import {CancelButton} from "../shared/buttons";
import {confirmCancel} from "../shared/confirm";
import {TicketStates} from "../../../models/ticket";
import PresaleOrderLink from "../presale_orders/link";

class TicketListItem extends React.Component {

  render() {
    const price = this.props.ticket.price_id ? this.props.prices.find(p => p.id.toString() === this.props.ticket.price_id.toString()) : null;
    const reservation = this.props.ticket.reservation_id ? this.props.reservations.find(r => r.id.toString() === this.props.ticket.reservation_id.toString()) : null;
    const presale = this.props.ticket.presale_order_id != null

    return (
      <Table.Row>
        <Table.Cell>
          # {this.props.ticket.id}
          {presale
            ? <Label style={{marginLeft: 10}} color={'yellow'}
                     horizontal>{this.props.t(i18nArAttr('ticket.presale'))}</Label>
            : null
          }
        </Table.Cell>
        <Table.Cell>
          {this.props.ticket.isCanceled
            ? <Label color={'red'} horizontal>{this.props.t(i18nArAttr('ticket.canceled'))}</Label>
            : null
          }
          {this.props.ticket.isEntered
            ? <Label color={'blue'} horizontal>{this.props.t(i18nArAttr('ticket.entered'))}</Label>
            : null
          }
          {this.props.ticket.isOpen
            ? <Label color={'teal'} horizontal>{this.props.t(i18nArAttr('ticket.active'))}</Label>
            : null
          }
        </Table.Cell>
        <Table.Cell>{moment(this.props.ticket.created_at).format()}</Table.Cell>
        <Table.Cell>{price ? this.props.t(i18nArAttr(`price.price_types.${price.price_type}`)) : '-'}</Table.Cell>
        <Table.Cell>{this.props.ticket.formatPrice()}</Table.Cell>
        <Table.Cell>
          {reservation ?
            <ReservationLink show={this.props.show} reservation={reservation}/>
            : ''
          }
          {presale ?
            <PresaleOrderLink presaleOrderId={this.props.ticket.presale_order_id}/>
            : ''
          }
        </Table.Cell>
        <Table.Cell>
          <CancelButton
            className={this.props.ticket.isCanceled ? 'disabled' : ''}
            disabled={this.props.ticket.isCanceled}
            onClick={() => this.cancelTicket()}
          />
        </Table.Cell>
      </Table.Row>
    )
  }

  cancelTicket() {
    if (confirmCancel(this.props.t, 'ticket')) {
      this.props.store.ticketStore.update({...this.props.ticket, state: TicketStates.canceled})
        .then(() => this.props.store.showStore.load(this.props.show.id));
    }
  }
}

export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(TicketListItem);
