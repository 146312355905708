import {observable, action, computed, decorate} from "mobx";
import {DEFAULT_LANGUAGE} from "../config/i18n";

export class ConfigStore {

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  _language = DEFAULT_LANGUAGE;
  sidebarVisible = false;
  sidebarEnabled = !!window.location.pathname.match(/^\/admin/);
  isMobile = false;
  selectedTabIndexes = {};

  get isDesktop() {
    return !this.isMobile;
  }

  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
  }

  get language() {
    return this._language;
  }

  getSelectedTabIndex(path) {
    return this.selectedTabIndexes[path] || 0;
  }
}

decorate(ConfigStore, {
  _language: observable,
  sidebarVisible: observable,
  sidebarEnabled: observable,
  isMobile: observable,
  selectedTabIndexes: observable,
  isDesktop: computed,
  toggleSidebar: action,
});