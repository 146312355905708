import {decorate, observable} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {Show} from "../models/show";

decorate(Show, {
  name: observable,
  festival_id: observable,
  location: observable,
  available_tickets_count: observable,
  tickets_count: observable,
  entries_quantity_sum: observable,
  presale_ends_at: observable,
  presale_starts_at: observable,
  presale_active: observable,
  tickets_available_for_presale_count: observable,
  description: observable,
  group_name: observable,
  past: observable,
});

export class ShowStore extends EntityStore {

  shows = [];

  constructor(rootStore) {
    super(rootStore, 'shows', Api.shows, Show);
  }
}

decorate(ShowStore, {
  shows: observable,
});
