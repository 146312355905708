import {BaseModel} from "./base";

export class Address extends BaseModel {
  id = 0;
  firstname = '';
  lastname = '';
  city = '';
  zipcode = 0;
  street = '';
  house = '';
  email = '';

  static fromPlainObject(plainObject) {
    const address = new Address();
    return Object.assign(address, plainObject);
  }
}
