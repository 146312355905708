import * as React from "react";
import compose from "compose-function";
import {loadEntity} from "../admin/shared/loadEntity";
import {staffRoute} from "../../routing/routing";
import TicketStatistics from "../point-of-sale/ticketStatistics";
import {inject, observer} from "mobx-react";
import EntryCounter from "./entryCounter";
import {Grid, Header, Message, Tab} from "semantic-ui-react";
import {loadEntities} from "../admin/shared/loadEntities";
import {withNamespaces} from "react-i18next";
import {EntryTypes} from "../../models/entry";
import ReservationsWidget from "./reservationsWidget";
import moment from "moment";
import QRCodeReader from "./QRCodeReader";
import ScannedTicketsWidget from "./scannedTicketsWidget";
import {TicketStates} from "../../models/ticket";

class EntryView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showQrReader: false,
      scanResult: ''
    };
  }

  addEntry = (entryType) => {
    this.createEntry(entryType, 1);
  };
  addExit = (entryType) => {
    this.createEntry(entryType, -1);
  };

  createEntry = (entryType, quantity, ticketId, festivalPassId) => {
    const entry = {
      entry_type: entryType,
      show_id: this.props.show.id,
      user_id: this.props.store.authStore.user.id,
      quantity: quantity,
      ticket_id: ticketId,
      festival_pass_id: festivalPassId
    };
    return this.props.store.entryStore.create(entry, {skipNotification: true}).then(() => {
      this.props.store.showStore.load(this.props.show.id);
    });
  };

  handleQrShow = () => {
    this.setState({
      showQrReader: !this.state.showQrReader
    });
  }

  handleTicketScan = (value) => {
    if (value.startsWith('f')) {
      const intValue = parseInt(value.substr(2));
      let festivalPass = this.props.festivalPasses.find(fp => fp.id === intValue);
      let festivalPassAlreadyUsed = false;

      if (festivalPass) {
        festivalPassAlreadyUsed = this.props.entries.some(entry => entry.festival_pass_id === festivalPass.id);
      }

      if (festivalPass && festivalPass.entry_allowed && festivalPass.festival_id === this.props.show.festival_id && !festivalPassAlreadyUsed) {
        this.createEntry(EntryTypes.festivalPass, 1, null, intValue);
        this.setState({
          scanResult: 'success'
        })
      } else if (festivalPassAlreadyUsed) {
        this.setState({
          scanResult: 'entered'
        })
      } else {
        this.setState({
          scanResult: 'error'
        })
      }
    } else {
      const intValue = parseInt(value);
      let ticket = this.props.tickets.find(t => t.id === intValue && t.show_id === this.props.show.id)
      let ticketAlreadyUsed = false;

      if (ticket) {
        ticketAlreadyUsed = this.props.entries.some(entry => entry.ticket_id === intValue);
      }

      if (ticket && ticket.state !== TicketStates.entered && ticket.state !== TicketStates.canceled) {
        this.props.store.ticketStore.update({...ticket, state: TicketStates.entered}).then(response => {
          this.setState({
            scanResult: 'success'
          });
          this.createEntry(EntryTypes.eveningSale, 1, intValue, null);
        })
      } else if (ticketAlreadyUsed) {
        this.setState({
          scanResult: 'entered'
        })
      } else {
        this.setState({
          scanResult: 'error'
        })
      }
    }
    setTimeout(function () {
      this.setState({scanResult: ''})
    }.bind(this), 5000);
  }

  render() {
    const {t, show, entries, store} = this.props;
    const eveningSaleEntriesCount = entries.filter(e => e.entry_type === EntryTypes.eveningSale).reduce((sum, entry) => sum + entry.quantity, 0);
    const festivalPassEntriesCount = entries.filter(e => e.entry_type === EntryTypes.festivalPass).reduce((sum, entry) => sum + entry.quantity, 0);
    const reservationEntries = entries.filter(e => e.entry_type === EntryTypes.reservation);
    const isCreatingEntry = (entryType) => {
      return store.entryStore.isActionInProgress('create') && store.entryStore.actionState('create').entry_type === entryType;
    };

    const panes = [
      {
        menuItem: 'Manueller Einlass', render: () =>
          <Tab.Pane>
            <Grid stackable columns={2}>
              <Grid.Column>
                <EntryCounter
                  title={t('entry.evening_sale')} entryType={EntryTypes.eveningSale} onIncrement={this.addEntry}
                  onDecrement={this.addExit} count={eveningSaleEntriesCount}
                  canIncrement={eveningSaleEntriesCount < show.tickets_count}
                  loading={isCreatingEntry(EntryTypes.eveningSale)}
                >
                  {eveningSaleEntriesCount} / {show.tickets_count}
                </EntryCounter>
              </Grid.Column>
              <Grid.Column>
                <EntryCounter
                  title={t('entry.festival_pass')}
                  entryType={EntryTypes.festivalPass}
                  onIncrement={this.addEntry}
                  onDecrement={this.addExit} count={festivalPassEntriesCount}
                  canIncrement={show.available_tickets_count > 0}
                  loading={isCreatingEntry(EntryTypes.festivalPass)}
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <ReservationsWidget show={show} entries={reservationEntries}/>
              </Grid.Column>
            </Grid>
          </Tab.Pane>
      },
      {
        menuItem: 'QR-Code Einlass', render: () =>
          <Tab.Pane>
            <Grid>
              <Grid.Column
                width={16}
                className={
                  `
                  ${this.state.scanResult === 'success' ? "successful-scan" : ""}
                  ${this.state.scanResult === '' ? "empty-scan" : ""}  
                  ${this.state.scanResult === 'error' || this.state.scanResult === 'entered' ? "failed-scan" : ""}
                  `
                }
              >
                <QRCodeReader showId={show.id} onSuccess={this.handleTicketScan}/>
              </Grid.Column>
              {this.state.scanResult === 'success' ?
                <Grid.Column width={16}>
                  <Message positive>
                    <Message.Header>Ticket gültig!</Message.Header>
                    <p>Das Ticket wurde erfolgreich gescannt.</p>
                  </Message>
                </Grid.Column>
                : null
              }
              {this.state.scanResult === 'error' ?
                <Grid.Column width={16}>
                  <Message negative>
                    <Message.Header>Ticket ungültig</Message.Header>
                    <p>
                      Das Ihnen vorgewiesene Ticket ist kein gültiges Ticket für diese Show.
                    </p>
                  </Message>
                </Grid.Column>
                :
                null
              }
              {this.state.scanResult === 'entered' ?
                <Grid.Column width={16}>
                  <Message negative>
                    <Message.Header>Ticket bereits eingelöst</Message.Header>
                    <p>
                      Das Ihnen vorgewiesene Ticket wurde bereits zum Einlass verwendet.
                    </p>
                  </Message>
                </Grid.Column>
                :
                null
              }
              <Grid.Column width={16}>
                <ScannedTicketsWidget/>
              </Grid.Column>
            </Grid>
          </Tab.Pane>
      }
    ];

    return (
      <div className={'entry-view'}>
        <div className={'header-centered'} style={{textAlign: 'center'}}>
          <Header as='h2' style={{marginTop: 0}}>{show.name}</Header>
          <p className={'show-begin'}>{moment(show.begins_at).format()}</p>
          <TicketStatistics show={show} autoUpdate={true} className={'block header'}/>
        </div>
        <Tab panes={panes}/>
      </div>
    )
  }
}

export default compose(
  withNamespaces('translation'),
  loadEntity('showStore', 'show', staffRoute('/entry'), {lazyLoad: true}),
  loadEntities('festivalPassStore', 'festivalPasses', {
    storeParams: (props) => ({params: {festivalId: props.show.festival_id}})
  }),
  loadEntities('entryStore', 'entries', {
    storeParams: (props) => ({params: {showId: props.show.id}}),
    autoUpdate: true
  }),
  loadEntities('ticketStore', 'tickets', {
    storeParams: (props) => ({params: {showId: props.show.id}}),
    autoUpdate: true
  }),
  inject('store'),
  observer
)(EntryView);
