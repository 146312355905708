import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {EntityDetailTable, EntityDetailTableEntry, EntityTable} from "../shared/tables";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {EditButton} from "../shared/buttons";
import {withRouter} from "react-router";
import {confirmDelete} from "../shared/confirm";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {loadEntity} from "../shared/loadEntity";
import {Button, Icon, Label, Tab} from "semantic-ui-react";
import loadEntities from "../shared/loadEntities";
import PresaleTicketItem from "./presaleTicketItem";
import {downloadPdfFromUrl, showPdfFromUrl} from "../../../tools/files";
import {resourcePath} from "../../../middleware/apiPath";
import {presaleOrderResourcePath} from "../../../middleware/endpoints/presaleOrders";

class PresaleOrderDetail extends React.Component {
  componentDidMount() {
    this.props.shows.forEach((show) => {
      this.props.store.priceStore.loadAll({params: {showId: show.id, collect: true}})
    })
  }

  render() {
    const {t, shows} = this.props;
    const presaleOrder = this.props.presaleOrder

    const downloadPdf = (presaleOrder) => {
      downloadPdfFromUrl(resourcePath(presaleOrderResourcePath(`/${presaleOrder.id}.pdf`)));
    }

    const showPdf = (presaleOrder) => {
      showPdfFromUrl(resourcePath(presaleOrderResourcePath(`/${presaleOrder.id}.pdf`)));
    }

    return (
      <EntityDetailLayout title={t(i18nArModel('presale_order.one'))} backPath={`/presale_orders`}>
        <EditButton route={adminRoute(`/presale_orders/${this.props.presaleOrder.id}/edit`)}/>
        <Button icon onClick={() => showPdf(presaleOrder)} labelPosition='left'>
          <Icon name='eye'/>
          Vorschau
        </Button>
        <Button icon onClick={() => downloadPdf(presaleOrder)} labelPosition='left'>
          <Icon name='download'/>
          PDF
        </Button>
        <EntityDetailTable>
          <EntityDetailTableEntry name={t(i18nArAttr('presale_order.firstname'))}>
            <b>{presaleOrder.address.firstname}</b>
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('presale_order.lastname'))}>
            <b>{presaleOrder.address.lastname}</b>
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('presale_order.email'))}>
            {presaleOrder.address.email}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('presale_order.city'))}>
            {presaleOrder.address.zipcode} {presaleOrder.address.city}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('presale_order.street'))}>
            {presaleOrder.address.street} {presaleOrder.address.house}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('ticket.state'))}>
            <Label color={'green'} horizontal>{this.props.t(i18nArAttr('reservation.already_paid'))}</Label>
          </EntityDetailTableEntry>
        </EntityDetailTable>

        <Tab panes={[
          {
            menuItem: t(i18nArModel('ticket.other')),
            render: () =>
              <Tab.Pane>
                <EntityTable headers={[
                  t(i18nArAttr('ticket.id')),
                  t(i18nArModel('show.one')),
                  t(i18nArAttr('ticket.state')),
                  t(i18nArAttr('common.created_at')),
                  t(i18nArAttr('price.price_type')),
                  t(i18nArAttr('price.price')),
                  ''
                ]}>
                  {presaleOrder.tickets.map((ticket) =>
                    <PresaleTicketItem
                      key={ticket.id}
                      ticket={ticket}
                      price={ticket.price_id}
                      show={shows.find(s => s.id === ticket.show_id)}
                    />
                  )}
                </EntityTable>
              </Tab.Pane>
          }
        ]}/>
      </EntityDetailLayout>
    )
  }

  delete() {
    if (confirmDelete(this.props.t, 'show')) {
      this.props.store.showStore.delete(this.props.show.id).then(() => {
        this.props.history.push(adminRoute('/shows'));
      });
    }
  }
}

export default compose(
  loadEntities('showStore', 'shows'),
  loadEntity('presaleOrderStore', 'presaleOrder', adminRoute('/presale_orders')),
  withRouter,
  withNamespaces('translation'),
  inject('store'),
  observer
)(PresaleOrderDetail);
