import * as React from 'react';
import {withNamespaces} from 'react-i18next';
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {LabeledIconLinkButton} from "../shared/buttons";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {loadEntities} from "../shared/loadEntities";
import FestivalPassListItem from "./listitem";
import {adminRoute} from "../../../routing/routing";
import {downloadPdfFromUrl, showPdfFromUrl} from "../../../tools/files";
import {resourcePath} from "../../../middleware/apiPath";
import {festivalPassResourcePath} from "../../../middleware/endpoints/festivalPasses";

class FestivalPassList extends React.Component {

  render() {
    const {t, festival} = this.props;
    const headers = [
      t(i18nArAttr('festival_pass.category')),
      t(i18nArAttr('festival_pass.name')),
      t(i18nArAttr('festival_pass.email')),
      t(i18nArAttr('festival_pass.birth_date')),
      ''
    ];

    return (
      <div>
        <LabeledIconLinkButton
          text={t(i18nArModel('festival_pass.one'))}
          route={adminRoute(`/festivals/${festival.id}/festival_passes/create/`)}
        />
        <EntityTable headers={headers}>
          {this.props.festivalPasses.map((festivalPass) =>
            <FestivalPassListItem
              key={festivalPass.id}
              festivalPass={festivalPass}
              festivalPassCategory={this.props.festivalPassCategories.find(fp => fp.id === festivalPass.festival_pass_category_id)}
              festival={festival}
              onShow={this.showFestivalPass.bind(this)}
              onDownload={this.downloadFestivalPass.bind(this)}
            />
          )}
        </EntityTable>
      </div>
    )
  }

  showFestivalPass(festivalPass) {
    showPdfFromUrl(resourcePath(festivalPassResourcePath(this.props.festival.id, `/${festivalPass.id}.pdf`)));
  }

  downloadFestivalPass(festivalPass) {
    downloadPdfFromUrl(resourcePath(festivalPassResourcePath(this.props.festival.id, `/${festivalPass.id}.pdf`)));
  }

}

export default compose(
  loadEntities('festivalPassStore', 'festivalPasses', {
    storeParams: (props) => ({params: {festival_id: props.festival.id}})
  }),
  loadEntities('festivalPassCategoryStore', 'festivalPassCategories'),
  withNamespaces('translation'),
  inject('store'),
  observer
)(FestivalPassList);
