import i18n from 'i18next';
import {reactI18nextModule} from 'react-i18next';
import de from './../i18n/de.json';

export const DEFAULT_LANGUAGE = 'de';
export const AVAILABLE_LANGUAGES = [DEFAULT_LANGUAGE];

i18n
  // pass the i18n instance to the react-i18next components.
  // Alternative use the I18nextProvider: https://react.i18next.com/components/i18nextprovider
  .use(reactI18nextModule)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    debug: process.env.NODE_ENV !== 'production',
    whitelist: AVAILABLE_LANGUAGES,
    nonExplicitWhitelist: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      prefix: '%{',
      suffix: '}'
    },
    resources: {
      de: {
        translation: de
      }
    },
    detection: {
      order: ['path', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'subdomain'],
    }
  });


export default i18n;
