import * as React from "react";
import UserForm from "./form";
import NewEntity from "../shared/newEntity";

class NewUser extends React.Component {
  render() {
    return <NewEntity entitiesPath={'/users'} name={'user'} form={UserForm}/>;
  }
}

export default NewUser;