import * as React from "react";
import {inject, observer} from "mobx-react";
import {Header, Segment} from "semantic-ui-react";
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {staffRoute} from "../../routing/routing";
import {LabeledIconLinkButton} from "../admin/shared/buttons";
import {loadEntity} from "../admin/shared/loadEntity";

class ScannedTicketsWidget extends React.Component {

  render() {
    const {t, show} = this.props;
    return (
      <Segment className={'reservations-widget'}>
        <Header as='h3' style={{textAlign: 'center'}}>{t('entry.scanned_tickets_widget.title')}</Header>
        <LabeledIconLinkButton
          primary={true}
          icon={'tasks'}
          route={staffRoute(`/entry/shows/${show.id}/tickets`)}
          text={t('entry.scanned_tickets_widget.show_tickets')}
        />
      </Segment>
    )
  }
}

export default compose(
  withNamespaces('translation'),
  loadEntity('showStore', 'show', staffRoute('/entry'), {lazyLoad: true}),
  inject('store'),
  observer
)(ScannedTicketsWidget);