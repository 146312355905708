import * as React from 'react';
import {Container, Grid} from "semantic-ui-react";
import {Link} from "react-router-dom";

class Footer extends React.Component {

  render() {
    return (
      <footer style={{
        height: '100px',
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: "#17454e",
        color: "white",
      }}>
        <Container>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <address>
                  <b>cirqu'Aarau</b><br/>
                  Apfelhausenweg 20<br/>
                  5000 Aarau
                </address>
              </Grid.Column>
              <Grid.Column style={{textAlign: "right"}}>
                <a style={{color: "white"}} href="mailto:info@cirquaarau.ch">
                  info@cirquaarau.ch<br/><br/>
                </a>
                <p style={{textAlign: "right", fontWeight: 'bold', fontSize: '0.8em'}}>
                  <Link to={'/agb'} target={'_blank'} style={{color: 'white', marginRight: 10}}>AGB</Link>
                  |
                  <span style={{marginLeft: 10}}>Copyright © 2021 cirqu'</span>
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </footer>
    )
  }
}

export default (Footer)
