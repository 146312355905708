import * as React from "react";
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Button, Grid, Message, Table} from "semantic-ui-react";
import {Link} from "react-router-dom";
import CartItem from "./cartItem";
import {loadEntities} from "../admin/shared/loadEntities";
import CheckoutSteps from "./checkoutSteps";

class CartConfirmationPage extends React.Component {
  componentDidMount() {
    if (sessionStorage.getItem('cart') != null) {
      this.props.store.cartStore.load(sessionStorage.getItem('cart'))
    }
  }

  render() {
    const {shows} = this.props;
    const cart = this.props.store.cartStore.carts.find(cart => cart.number === sessionStorage.getItem('cart'));

    if (cart && cart.address === null) {
      this.props.history.push('/checkout/address');
    }

    return (
      <>
        <CheckoutSteps active={'confirmation'}/>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              {cart && cart.cart_tickets.length > 0 ?
                <Table style={{fontSize: '1.2em'}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Vorstellung</Table.HeaderCell>
                      <Table.HeaderCell>Veranstaltungsort</Table.HeaderCell>
                      <Table.HeaderCell>Eventdatum</Table.HeaderCell>
                      <Table.HeaderCell>Anzahl Tickets</Table.HeaderCell>
                      <Table.HeaderCell textAlign={'right'}>Preis</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {cart.cart_tickets.map((cartTicket) =>
                      <CartItem key={cartTicket.id} cart={cart} cartItem={cartTicket} shows={shows} editable={false}/>
                    )
                    }
                  </Table.Body>
                  {cart.cart_tickets.length >= 2 ?
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell>Total</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell textAlign={'left'}>{cart.cart_tickets.reduce((a, b) => a + (b["quantity"] || 0), 0)} Tickets</Table.HeaderCell>
                        <Table.HeaderCell textAlign={'right'}>{cart.formatPrice()}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                    : null
                  }
                </Table>
                : <Message>Leider befinden sich keine Tickets in Ihrem Warenkorb</Message>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid style={{marginTop: 40}}>
          <Grid.Row>
            <Grid.Column textAlign={'right'}>
              <Link to={'/checkout/payment'}>
                <Button
                  disabled={cart && cart.cart_tickets.length <= 0}
                  style={{margin: 0}}
                  primary
                >
                  Zur Bezahlung
                </Button>
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  }
}

export default compose(
  loadEntities('showStore', 'shows'),
  withNamespaces('translation'),
  inject('store'),
  observer
)(CartConfirmationPage);