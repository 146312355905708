import * as React from "react";
import compose from "compose-function";
import {loadEntities} from "../admin/shared/loadEntities";
import {withRouter} from "react-router";
import {inject, observer} from "mobx-react";
import {Card, Icon, Tab} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {staffRoute} from "../../routing/routing";
import {BackButton} from "../admin/shared/buttons";
import moment from "moment";

const ShowCard = ({show, store}) => {
  return <Card as={Link} to={staffRoute(`/entry/shows/${show.id}`)} className={'card-link'}>
    <Card.Content>
      {store.configStore.isMobile ? '' : <Icon size='big' name={'play'}/>}
      <Card.Header>{show.name}</Card.Header>
    </Card.Content>
    <Card.Content extra>
      <div className={'show-begin'}>{moment(show.begins_at).format('DD.MM.YYYY, HH:mm') + ' Uhr'}</div>
    </Card.Content>
  </Card>
};

const ShowsTab = ({shows, store}) => {
  return (
    <Tab.Pane>
      <Card.Group itemsPerRow={3} stackable={true} className={'entry-show-list-card card-links'}>
        {shows.map(show => <ShowCard key={show.id} show={show} store={store}/>)}
      </Card.Group>
    </Tab.Pane>
  )
};

const CurrentShowsTab = compose(
  loadEntities('showStore', 'shows', {
    storeParams: () => ({params: {current: true}})
  }),
  inject('store'),
  observer
)(ShowsTab);

const PastShowsTab = compose(
  loadEntities('showStore', 'shows', {
    storeParams: () => ({params: {past: true}})
  }),
  inject('store'),
  observer
)(ShowsTab);

const ShowList = (props) => {

  return (
    <React.Fragment>
      <Tab panes={[
        {
          menuItem: 'Aktuelle Shows',
          render: () => <CurrentShowsTab/>
        },
        {
          menuItem: 'Vergangene Shows',
          render: () => <PastShowsTab/>
        }
      ]}/>
      <BackButton route={staffRoute('/')}/>
    </React.Fragment>
  )
};

export default compose(
  withRouter,
  inject('store'),
  observer
)(ShowList);
