import * as React from 'react';
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Grid, Header, Message} from "semantic-ui-react";
import {loadEntities} from "../admin/shared/loadEntities";
import ShowListItem from "./showListItem";

class ShowList extends React.Component {
  componentDidMount() {
    if (sessionStorage.getItem('cart') != null) {
      this.props.store.cartStore.load(sessionStorage.getItem('cart'))
    } else {
      this.props.store.cartStore.create().then(result => {
        sessionStorage.setItem('cart', result.number)
        this.props.store.cartStore.load(result.number)
      })
    }
  }


  render() {
    const {festivals} = this.props
    const sortedShows = this.props.shows.sort((a, b) => new Date(a.begins_at) - new Date(b.begins_at))
    const festival = festivals[0] || null
    let description = []

    if(festival != null && festival.presale_description != null) {
      description = festival.presale_description.split(/\r?\n/);
    }

    return (
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <Header as='h1' style={{marginBottom: 40}}>Vorverkauf cirqu'</Header>
            { festival && festival.presale_description && festival.presale_description !== "" ?
              <p style={{marginBottom: 40}}>
                {
                  description.map((line, index) => (
                    <React.Fragment key={index}>{line}{index < (description.length - 1) ? <br/> : ''}</React.Fragment>
                  ))
                }
              </p>
              : <></>
            }
          </Grid.Column>
        </Grid.Row>
        {sortedShows && sortedShows.length <= 0 ?
          <Message>
            Leider befinden sich noch keine Shows im Vorverkauf.
          </Message>
          :
          <Grid.Row columns={3} className={"show-list"}>
            {sortedShows.map((show) =>
              show.presale_active ? <ShowListItem key={show.id} show={show}/> : <></>
            )}
          </Grid.Row>
        }
      </Grid>
    )
  }
}

export default compose(
  loadEntities('festivalStore', 'festivals', {
    storeParams: () => ({params: {current: true}})
  }),
  loadEntities('showStore', 'shows'),
  withNamespaces('translation'),
  inject('store'),
  observer
)(ShowList);


