import * as React from "react";
import {Route, Switch} from "react-router-dom";
import SalesPointsSidebar from "../../point-of-sale/sidebar";
import SidebarContent from "./sidebarContent";
import compose from "compose-function";
import {withRouter} from "react-router";
import {routerPath} from "../../../routing/routing";

class Sidebar extends React.Component {

  render() {
    const path = routerPath(this.props.match);

    const childProps = {
      onNavigate: this.props.onNavigate
    };

    return <Switch>
      <Route path={`${path}/pos/:posId`} render={() => <SalesPointsSidebar {...childProps}/>}/>
      <Route path={`*`} render={() => <SidebarContent {...childProps}/>}/>
    </Switch>
  }
}

export default compose(
  withRouter
)(Sidebar);