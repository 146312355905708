import {createEntity, executeDelete, executeGet, updateEntity} from "./base";

export const resourcesName = 'shows';
export const resourceName = 'show';

export const ShowsApi = {
  all: (params) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName, resourceName),
  update: (entity) => updateEntity(entity, `${resourcesName}/${entity.id}`, resourceName),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`)
};
