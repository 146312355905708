import {decorate, observable} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {Reservation} from "../models/reservation";

decorate(Reservation, {
  first_name: observable,
  last_name: observable,
  notes: observable,
  already_paid: observable,
  tickets_picked_up: observable,
  show_id: observable,
  user_id: observable,
  user: observable,
  number_of_persons: observable,
});

export class ReservationStore extends EntityStore {

  reservations = [];

  constructor(rootStore) {
    super(rootStore, 'reservations', Api.reservations, Reservation);
  }
}

decorate(ReservationStore, {
  reservations: observable,
});
