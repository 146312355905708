import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {Button, Popup, Table} from "semantic-ui-react";
import compose from "compose-function";
import {adminRoute} from "../../../routing/routing";
import {inject, observer} from "mobx-react";
import {DetailsButton} from "../shared/buttons";
import moment from "moment";

class FestivalPassListItem extends React.Component {

  render() {
    const {festival, festivalPass, festivalPassCategory} = this.props;
    return (
      <Table.Row>
        <Table.Cell>{festivalPassCategory ? festivalPassCategory.name : '-'}</Table.Cell>
        <Table.Cell>{festivalPass.fullName()}</Table.Cell>
        <Table.Cell>{festivalPass.email}</Table.Cell>
        <Table.Cell>{festivalPass.birth_date ? moment(festivalPass.birth_date).format('LL') : '-'}</Table.Cell>
        <Table.Cell collapsing>
          <DetailsButton route={adminRoute(`/festivals/${festival.id}/festival_passes/${festivalPass.id}`)}/>
          <Popup content='Festivalpass öffnen' trigger={
            <Button icon={'file pdf'} onClick={this.showClicked.bind(this)} />
          }/>
          <Popup content='Festivalpass herunterladen' trigger={
            <Button icon={'download'} onClick={this.downloadClicked.bind(this)} />
          }/>
        </Table.Cell>
      </Table.Row>
    )
  }

  showClicked() {
    if (this.props.onShow) this.props.onShow(this.props.festivalPass);
  }

  downloadClicked() {
    if (this.props.onDownload) this.props.onDownload(this.props.festivalPass);
  }
}

export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(FestivalPassListItem);
