import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {Label, Table} from "semantic-ui-react";
import compose from "compose-function";
import {inject, observer} from "mobx-react";
import {confirmCancel} from "../shared/confirm";
import {TicketStates} from "../../../models/ticket";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import moment from "moment";
import {formatPrice} from "../../../formatters/priceFormatter";
import {CancelButton} from "../shared/buttons";
import {Link} from "react-router-dom";
import {adminRoute} from "../../../routing/routing";

class PresaleTicketItem extends React.Component {
  componentDidMount() {
    this.props.store.priceStore.loadAll({params: {showId: this.props.show.id}})
  }

  render() {
    const {ticket, show} = this.props
    const price = this.props.ticket.price_id ? this.props.store.priceStore.prices.find(p => p.id.toString() === this.props.ticket.price_id.toString()) : null;

    return (
      <Table.Row>
        <Table.Cell>
          # {ticket.id}
        </Table.Cell>
        <Table.Cell>
          <Link to={adminRoute(`/shows/${show.id}`)}>
            <b>{show.name} </b>
          </Link>
          am {show.begins_at ? moment(show.begins_at).format() : '-'}
        </Table.Cell>
        <Table.Cell>
          {ticket.state === 'canceled'
            ? <Label color={'red'} horizontal>{this.props.t(i18nArAttr('ticket.canceled'))}</Label>
            : null
          }
          {ticket.state === 'entered'
            ? <Label color={'blue'} horizontal>{this.props.t(i18nArAttr('ticket.entered'))}</Label>
            : null
          }
          {ticket.state === 'created'
            ? <Label color={'teal'} horizontal>{this.props.t(i18nArAttr('ticket.active'))}</Label>
            : null
          }
        </Table.Cell>
        <Table.Cell>
          {moment(ticket.created_at).format()}
        </Table.Cell>
        <Table.Cell>{price ? this.props.t(i18nArAttr(`price.price_types.${price.price_type}`)) : '-'}</Table.Cell>
        <Table.Cell>{formatPrice(ticket.price)}</Table.Cell>
        <Table.Cell>
          <CancelButton
            className={ticket.isCanceled ? 'disabled' : ''}
            disabled={ticket.isCanceled}
            onClick={() => this.cancelTicket()}
          />
        </Table.Cell>
      </Table.Row>
    )
  }

  cancelTicket() {
    if (confirmCancel(this.props.t, 'ticket')) {
      this.props.store.ticketStore.update({...this.props.ticket, state: TicketStates.canceled})
        .then(() => this.props.store.presaleOrderStore.loadAll());
    }
  }
}

export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(PresaleTicketItem);
