import {Route, Switch} from "react-router-dom";
import * as React from "react";
import NotFound from "../notFound";
import EntryView from "./entryView";
import ReservationsView from "./reservationsView";
import ShowList from "./showList";
import ScannedTicketsView from "./scannedTicketsView";

const Routes = (props) => {
  return (
    <Switch>
      <Route exact path={`${props.match.path}`} component={ShowList}/>
      <Route exact path={`${props.match.path}/shows/:id`} component={EntryView}/>
      <Route exact path={`${props.match.path}/shows/:id/reservations`} component={ReservationsView}/>
      <Route exact path={`${props.match.path}/shows/:id/tickets`} component={ScannedTicketsView}/>
      <Route path={`${props.match.path}/*`} component={NotFound}/>
    </Switch>
  )
};

export default Routes;
