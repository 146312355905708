import {createEntity, executeDelete, executeGet, updateEntity} from "./base";

export const resourcesName = 'reservations';
export const resourceName = 'reservation';

export const ReservationsApi = {
    all: (params) => executeGet(resourcesName, {params}),
    byId: (id) => executeGet(`${resourcesName}/${id}`),
    create: (entity) => createEntity(entity, resourcesName, resourceName, {show_id: entity.show_id}),
    update: (entity) => updateEntity(entity, `${resourcesName}/${entity.id}`, resourceName, {show_id: entity.show_id}),
    destroy: (id) => executeDelete(`${resourcesName}/${id}`)
};
