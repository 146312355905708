import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {EntityDetailTable, EntityDetailTableEntry} from "../shared/tables";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {DeleteButton, EditButton} from "../shared/buttons";
import {withRouter} from "react-router";
import {confirmDelete} from "../shared/confirm";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {loadEntity} from "../shared/loadEntity";
import moment from "moment";
import {Tab} from "semantic-ui-react";
import PriceList from "../prices/list";
import ReservationList from "../reservations/list";
import TicketList from "../tickets/list";

class ShowDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      festival: null
    };
  }

  componentDidMount() {
    if (this.props.show.festival_id) {
      this.props.store.festivalStore.load(this.props.show.festival_id).then((result) =>
        this.setState({'festival': result})
      )
    }
  }

  render() {
    const {t} = this.props;

    return (
      <EntityDetailLayout title={t(i18nArModel('show.one'))} backPath={`/shows`}>
        <EditButton route={adminRoute(`/shows/${this.props.show.id}/edit`)}/>
        <DeleteButton onClick={() => this.delete()}/>
        <EntityDetailTable>
          <EntityDetailTableEntry name={t(i18nArAttr('show.name'))}>
            <b>{this.props.show.name}</b>
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('show.group_name'))}>
            {this.props.show.group_name}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArModel('festival.one'))}>
            {this.state.festival ? this.state.festival.name : ''}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('show.location'))}>{this.props.show.location}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('show.begins_at'))}>{this.props.show.begins_at ? moment(this.props.show.begins_at).format() : '-'}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('show.ends_at'))}>{this.props.show.ends_at ? moment(this.props.show.ends_at).format() : '-'}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('show.max_available_tickets_count'))}>{this.props.show.max_available_tickets_count}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('show.available_tickets_count'))}>{this.props.show.available_tickets_count}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('show.max_tickets_available_for_presale_count'))}>{this.props.show.max_tickets_available_for_presale_count}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('show.available_presale_tickets_count'))}>{this.props.show.available_presale_tickets_count}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('show.presale_starts_at'))}>{this.props.show.presale_starts_at ? moment(this.props.show.presale_starts_at).format() : '-'}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('show.presale_ends_at'))}>{this.props.show.presale_ends_at ? moment(this.props.show.presale_ends_at).format() : '-'}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('show.entries_quantity_sum'))}>{this.props.show.entries_quantity_sum}</EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('show.presale_url'))}>
            <a href={"http://" + window.location.host + '/shows/' + this.props.show.id} target="_blank" rel="noopener noreferrer">{"http://" + window.location.host + '/shows/' + this.props.show.id}</a>
          </EntityDetailTableEntry>
        </EntityDetailTable>

        <Tab panes={[
          {
            menuItem: t(i18nArModel('price.other')),
            render: () => <Tab.Pane><PriceList show={this.props.show}/></Tab.Pane>
          },
          {
            menuItem: t(i18nArModel('reservation.other')),
            render: () => <Tab.Pane><ReservationList show={this.props.show}/></Tab.Pane>
          },
          {
            menuItem: t(i18nArModel('ticket.other')),
            render: () => <Tab.Pane><TicketList show={this.props.show}/></Tab.Pane>
          }
        ]} onTabChange={(e, {activeIndex}) => {
          this.props.store.configStore.selectedTabIndexes[`show@${this.props.show.id}`] = activeIndex
        }} defaultActiveIndex={this.props.store.configStore.selectedTabIndexes[`show@${this.props.show.id}`]}/>
      </EntityDetailLayout>
    )
  }

  delete() {
    if (confirmDelete(this.props.t, 'show')) {
      this.props.store.showStore.delete(this.props.show.id).then(() => {
        this.props.history.push(adminRoute('/shows'));
      });
    }
  }
}

export default compose(
  loadEntity('showStore', 'show', adminRoute('/shows')),
  withRouter,
  withNamespaces('translation'),
  inject('store'),
  observer
)(ShowDetail);
