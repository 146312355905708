import * as React from "react";
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Card} from "semantic-ui-react";
import {i18nArAttr} from "@semabit/rails-i18n-tools";

class TicketCatalog extends React.Component {
  render() {

    const {t} = this.props;

    return <React.Fragment>
      <Card.Group itemsPerRow={3} stackable={true} className={'card-links'}>
        {this.props.prices.map(price => {
          return <Card link className={'card-link'} key={price.id} onClick={() => this.priceClicked(price)}>
            <Card.Content>
              <Card.Header>{t(i18nArAttr(`price.price_types.${price.price_type}`))}</Card.Header>
            </Card.Content>
            <Card.Content>
              <Card.Header>{price.formatPrice()}</Card.Header>
            </Card.Content>
          </Card>
        })}
      </Card.Group>
    </React.Fragment>
  }

  priceClicked(price) {
    if (this.props.onPriceClicked) {
      this.props.onPriceClicked(price);
    }

    const receipt = this.props.store.receiptStore.current;

    const ticket = {
      show_id: this.props.show.id,
      price_id: price.id,
      receipt_id: receipt ? receipt.id : null,
      state: "created"
    };

    this.props.store.ticketStore.create(ticket, {skipNotification: true}).then(ticket => {
      this.props.store.receiptStore.loadCurrent({params: {showId: this.props.show.id}});
      this.props.store.showStore.load(this.props.show.id);
    }).catch(error => {
      this.props.store.messageStore.addMessage({type: 'error', title: error.joinMessages()});
    });
  }
}

export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer,
)(TicketCatalog);
