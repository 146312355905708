import {asField} from 'informed';
import DateTime from 'react-datetime';
import React from "react";
import withStyle from "./withStyle";
import withDefaultId from "./withDefaultId";
import compose from "compose-function";
import moment from "moment";

const DateTimePicker = asField(({fieldState, fieldApi, ...props}) => {
  const {value} = fieldState;
  const {setValue, setTouched} = fieldApi;
  const {onChange, onBlur, initialValue, forwardedRef, locale, ...rest} = props;
  return (
    <DateTime
      {...rest}
      locale={locale || 'de-CH'}
      ref={forwardedRef}
      value={moment(value) || moment(initialValue)}
      onChange={e => {
        setValue(e);
        if (onChange) {
          onChange(e);
        }
      }}
      onBlur={e => {
        setTouched();
        if (onBlur) {
          onBlur(e);
        }
      }}
    />
  );
});

export default compose(
  withStyle,
  withDefaultId
)(DateTimePicker);