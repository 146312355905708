import * as React from 'react';
import {withNamespaces} from 'react-i18next';
import {inject, observer} from "mobx-react";
import {adminRoute} from "../../../routing/routing";
import compose from "compose-function";
import ReservationListItem from "./listItem";
import {LabeledIconLinkButton} from "../shared/buttons";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {loadEntities} from "../shared/loadEntities";

class ReservationList extends React.Component {

  render() {
    const {t} = this.props;
    const headers = [
      t(i18nArAttr('reservation.first_name')),
      t(i18nArAttr('reservation.last_name')),
      t(i18nArAttr('reservation.already_paid')),
      t(i18nArAttr('reservation.number_of_persons')),
      t(i18nArAttr('reservation.notes')),
      ''
    ];

    return (
      <div>
        <LabeledIconLinkButton text={t(i18nArModel('reservation.one'))}
                               route={adminRoute(`/shows/${this.props.show.id}/reservations/create`)}/>
        <EntityTable headers={headers}>
          {this.props.reservations.map((reservation) =>
            <ReservationListItem key={reservation.id} reservation={reservation} show={this.props.show}/>
          )}
        </EntityTable>
      </div>
    )
  }
}

export default compose(
  loadEntities('reservationStore', 'reservations', {
    storeParams: (props) => ({params: {show_id: props.show.id}})
  }),
  withNamespaces('translation'),
  inject('store'),
  observer
)(ReservationList);
