import * as React from "react";
import {Popup, Button, Table, Label} from "semantic-ui-react";
import {formatPrice} from "../../formatters/priceFormatter";
import moment from "moment";
import {confirmCancel} from "../admin/shared/confirm";
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import {TicketStates} from "../../models/ticket";

class ReceiptListItem extends React.Component {

  render() {
    const receiptCanceled = this.props.receipt.tickets_not_canceled_count === 0;

    return (
      <Table.Row>
        <Table.Cell>
          {"#" + this.props.receipt.id}
          {receiptCanceled
            ?
            <Label style={{marginLeft: 20}} color='red' horizontal>
              {this.props.t(i18nArAttr('ticket.canceled'))}
            </Label>
            : ''
          }
        </Table.Cell>
        <Table.Cell>
          {this.props.receipt.tickets_not_canceled_count}
        </Table.Cell>
        <Table.Cell>
          {formatPrice(this.props.receipt.total_price)}
        </Table.Cell>
        <Table.Cell>
          {moment(this.props.receipt.updated_at).format()}
        </Table.Cell>
        <Table.Cell collapsing>
          <Popup content='Tickets öffnen' trigger={
            <Button icon={'file pdf'} onClick={this.showClicked.bind(this)} disabled={receiptCanceled}/>
          }/>
          <Popup content='Tickets herunterladen' trigger={
            <Button icon={'download'} onClick={this.downloadClicked.bind(this)} disabled={receiptCanceled}/>
          }/>
          <Popup content='Alle Tickets Stornieren' trigger={
            <Button icon={'ban'} onClick={() => this.cancelTickets()} disabled={receiptCanceled}/>
          }/>
        </Table.Cell>
      </Table.Row>
    )
  }

  showClicked() {
    if (this.props.onShow) this.props.onShow(this.props.receipt);
  }

  downloadClicked() {
    if (this.props.onDownload) this.props.onDownload(this.props.receipt);
  }

  cancelTickets() {
    if (confirmCancel(this.props.t, 'ticket')) {
      this.props.store.ticketStore.updateMultiple(this.props.tickets.map(ticket => ticket.id), {
        params: {
          showId: this.props.show.id,
          updates: {state: TicketStates.canceled}
        }
      }).then(() => {
        this.props.store.showStore.load(this.props.show.id)
      });
    }
  }

}

export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(ReceiptListItem);
