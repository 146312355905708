import {decorate, observable} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {FestivalPass} from "../models/festivalPass";

decorate(FestivalPass, {
  fist_name: observable,
  last_name: observable,
  email: observable,
  festival_id: observable,
  birth_date: observable,
  entry_allowed: observable,
});

export class FestivalPassStore extends EntityStore {

  festivalPasses = [];

  constructor(rootStore) {
    super(rootStore, 'festivalPasses', Api.festivalPasses, FestivalPass);
  }
}

decorate(FestivalPassStore, {
  festivalPasses: observable,
});
