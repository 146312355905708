import * as React from "react";
import {NavLink} from "react-router-dom";
import {adminRoute} from "../../../routing/routing";
import {Icon, Menu} from "semantic-ui-react";
import {withNamespaces} from "react-i18next";
import {withRouter} from "react-router";
import compose from "compose-function";

class SidebarContent extends React.Component {

  constructor(props) {
    super(props);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    this.state = {activeIndex: -1};
  }

  render() {
    const {t} = this.props;
    return <React.Fragment>
      <Menu.Item as={NavLink} to={adminRoute('/festivals')} onClick={this.handleMenuItemClick}>
        <Icon name='child'/>
        {t('activerecord.models.festival.other')}
      </Menu.Item>
      <Menu.Item as={NavLink} to={adminRoute('/festival_pass_categories')} onClick={this.handleMenuItemClick}>
        <Icon name='tags'/>
        {t('activerecord.models.festival_pass_category.other')}
      </Menu.Item>
      <Menu.Item as={NavLink} to={adminRoute('/shows')} onClick={this.handleMenuItemClick}>
        <Icon name='play'/>
        {t('activerecord.models.show.other')}
      </Menu.Item>
      <Menu.Item as={NavLink} to={adminRoute('/sales_points')} onClick={this.handleMenuItemClick}>
        <Icon name='ticket'/>
        {t('activerecord.models.sales_point.other')}
      </Menu.Item>
      <Menu.Item as={NavLink} to={adminRoute('/presale_orders')} onClick={this.handleMenuItemClick}>
        <Icon name='cart'/>
        {t('activerecord.models.presale_order.other')}
      </Menu.Item>
      <Menu.Item as={NavLink} to={adminRoute('/reservations/search')} onClick={this.handleMenuItemClick}>
        <Icon name='search'/>
        {t('activerecord.attributes.reservation.search')}
      </Menu.Item>
      <Menu.Item as={NavLink} to={adminRoute('/users')} onClick={this.handleMenuItemClick}>
        <Icon name='user'/>
        {t('activerecord.models.user.other')}
      </Menu.Item>
    </React.Fragment>;
  }

  handleMenuItemClick(...args) {
    if (this.props.onNavigate) {
      this.props.onNavigate(args)
    }
  }
}

export default compose(
  withNamespaces('translation'),
  withRouter
)(SidebarContent);
