import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {Table} from "semantic-ui-react";
import compose from "compose-function";
import {adminRoute} from "../../../routing/routing";
import {inject, observer} from "mobx-react";
import {DetailsButton} from "../shared/buttons";
import {i18nBool} from "@semabit/rails-i18n-tools";

class UserListItem extends React.Component {

  render() {
    return (
      <Table.Row>
        <Table.Cell>{this.props.user.email}</Table.Cell>
        <Table.Cell>{this.props.user.first_name}</Table.Cell>
        <Table.Cell>{this.props.user.last_name}</Table.Cell>
        <Table.Cell>{this.props.t(i18nBool(this.props.user.admin))}</Table.Cell>
        <Table.Cell collapsing>
          <DetailsButton route={adminRoute(`/users/${this.props.user.id}`)}/>
        </Table.Cell>
      </Table.Row>
    )
  }
}

export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(UserListItem);
