import * as React from "react";
import NewEntity from "../shared/newEntity";
import FestivalPassForm from "./form";
import compose from "compose-function";
import {loadEntity} from "../shared/loadEntity";
import {adminRoute} from "../../../routing/routing";

class NewFestivalPass extends React.Component {
  render() {
    const {festival} = this.props

    return (
      <NewEntity
        entitiesPath={`/festivals/${this.props.festival.id}`}
        name={'festival_pass'}
        form={FestivalPassForm}
        festival_id={festival.id}
        titleI18nKey={'admin.entity.new.n'}
      />
    );
  }
}

export default compose(
  loadEntity('festivalStore', 'festival', adminRoute('/festivals'), {paramName: 'festivalId'})
)(NewFestivalPass);