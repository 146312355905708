import * as React from "react";
import compose from "compose-function";
import {inject, observer} from "mobx-react";
import {withNamespaces} from "react-i18next";
import {i18nArModel} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import FestivalPassForm from "./form";
import {loadEntity} from "../shared/loadEntity";

class EditFestivalPass extends React.Component {
  render() {
    const {t} = this.props;

    const festivalPassPath = `/festivals/${this.props.festival.id}/festival_passes/${this.props.match.params.id}`;

    return <EntityDetailLayout
      title={t('admin.entity.edit', {model_name: t(i18nArModel(`festival_pass.one`))})}
      backPath={festivalPassPath}
    >
      <FestivalPassForm
        redirectTo={adminRoute(festivalPassPath)}
        festivalPass={this.props.festivalPass}
        festival={this.props.festival}
      />
    </EntityDetailLayout>
  }
}

export default compose(
  loadEntity('festivalStore', 'festival', adminRoute('/festivals'), {paramName: 'festivalId'}),
  loadEntity('festivalPassStore', 'festivalPass', (props) => adminRoute(`/festivals/${props.show.id}`)),
  withNamespaces('translation'),
  inject('store'),
  observer
)(EditFestivalPass);
