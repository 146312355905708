import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {EntityDetailTable, EntityDetailTableEntry} from "../shared/tables";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {DeleteButton, EditButton} from "../shared/buttons";
import {withRouter} from "react-router";
import {confirmDelete} from "../shared/confirm";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {loadEntity} from "../shared/loadEntity";
import moment from "moment";
import {loadEntities} from "../shared/loadEntities";

class FestivalPassDetail extends React.Component {

  render() {
    const {t} = this.props;
    let festivalPassCategory = null

    if(this.props.festivalPass) {
      festivalPassCategory = this.props.festivalPassCategories.find(fp => fp.id === this.props.festivalPass.festival_pass_category_id)
    }

    console.log(festivalPassCategory)

    return (
      <EntityDetailLayout title={t(i18nArModel('festival_pass.one'))} backPath={`/festivals/${this.props.festival.id}`}>
        <EditButton route={adminRoute(`/festivals/${this.props.festival.id}/festival_passes/${this.props.festivalPass.id}/edit`)}/>
        <DeleteButton onClick={() => this.delete()}/>
        <EntityDetailTable>
          <EntityDetailTableEntry
            name={t(i18nArModel('festival.one'))}>{this.props.festival.name}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('festival_pass.category'))}>
            {festivalPassCategory ? festivalPassCategory.name : '-'}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('festival_pass.name'))}><b>{this.props.festivalPass.fullName()}</b></EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('festival_pass.email'))}>{this.props.festivalPass.email}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('festival_pass.birth_date'))}>
            {this.props.festivalPass.birth_date ? moment(this.props.festivalPass.birth_date).format('LL') : '-'}
          </EntityDetailTableEntry>
        </EntityDetailTable>
      </EntityDetailLayout>
    )
  }

  delete() {
    if (confirmDelete(this.props.t, 'festival_pass')) {
      this.props.store.festivalPassStore.delete(this.props.festivalPass.id, {params: {festivalId: this.props.festival.id}}).then(() => {
        this.props.history.push(adminRoute(`/festivals/${this.props.festival.id}`));
      });
    }
  }

  errorCallback(formErrors) {
    console.error({formErrors});
    this.setState({errors: formErrors});
  }
}

export default compose(
  loadEntity('festivalStore', 'festival', adminRoute('/festivals'), {paramName: 'festivalId'}),
  loadEntity('festivalPassStore', 'festivalPass', (props) => adminRoute(`/festivals/${props.festival.id}`)),
  loadEntities('festivalPassCategoryStore', 'festivalPassCategories'),
  withRouter,
  withNamespaces('translation'),
  inject('store'),
  observer
)(FestivalPassDetail);
