import {createEntity, executeDelete, executeGet, updateEntity} from "./base";

export const resourcesName = 'users';
export const resourceName = 'user';

export const UsersApi = {
  all: () => executeGet(resourcesName),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName, resourceName),
  update: (entity) => updateEntity(entity, `${resourcesName}/${entity.id}`, resourceName),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`)
};
