import * as React from "react";
import {staffRoute} from "../../routing/routing";
import {withNamespaces} from "react-i18next";
import compose from "compose-function";
import {inject, observer} from "mobx-react";
import {Card, Header, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";

class Dashboard extends React.Component {

  componentDidMount() {
    this.props.store.configStore.sidebarEnabled = false;
  }

  render() {
    return (
      <React.Fragment>
        <Header as='h1'>Ticketingsystem</Header>
        <Card.Group itemsPerRow={3} stackable={true} className={'card-links'}>
          <Card as={Link} to={staffRoute('/admin')} className={'card-link'}>
            <Card.Content>
              <Icon size='huge' name={'wrench'}/>
              <Card.Header>Zum Admin-Bereich</Card.Header>
            </Card.Content>
          </Card>
          <Card as={Link} to={staffRoute('/pos')} className={'card-link'}>
            <Card.Content>
              <Icon size='huge' name={'ticket'}/>
              <Card.Header>Verkaufsstellen</Card.Header>
            </Card.Content>
          </Card>
          <Card as={Link} to={staffRoute('/entry')} className={'card-link'}>
            <Card.Content>
              <Icon size='huge' name={'hand paper'}/>
              <Card.Header>Einlass</Card.Header>
            </Card.Content>
          </Card>
        </Card.Group>
      </React.Fragment>
    );
  }
}

export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer,
)(Dashboard);
