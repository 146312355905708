import React from 'react';
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import loadEntity from "../admin/shared/loadEntity";
import {Button, Grid, Header, Icon, Image, Message, Table} from "semantic-ui-react";
import moment from "moment";
import {loadEntities} from "../admin/shared/loadEntities";
import {Link} from "react-router-dom";
import {i18nArAttr} from "@semabit/rails-i18n-tools";

class ShowDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priceCounters: new Map(),
      totalCounter: 0,
      message: false,
      error: null
    }
  }

  componentDidMount() {
    const priceCounter = new Map()

    if (sessionStorage.getItem('cart') != null) {
      this.props.store.cartStore.load(sessionStorage.getItem('cart'))
      this.props.store.cartTicketStore.loadAll({params: {cartId: sessionStorage.getItem('cart')}})
    }

    this.props.prices.filter(p => !p.internal).forEach((price) => {
      priceCounter.set(price.id, 0)
    });
    this.setState(
      {
        priceCounters: priceCounter
      }
    )
  }

  handleMinus(id) {
    let {priceCounters, totalCounter} = this.state

    if (priceCounters.get(id) > 0) {
      totalCounter -= 1
      priceCounters.set(id, priceCounters.get(id) - 1)
    }
    this.setState(
      {
        totalCounter: totalCounter,
        error: null,
        priceCounters: priceCounters
      }
    )
  }

  handleAdd(id) {
    let {priceCounters, totalCounter} = this.state
    let sum = 0

    this.state.priceCounters.forEach((k) => sum += k)

    if (sum < this.props.show.available_presale_tickets_count) {
      totalCounter += 1
      priceCounters.set(id, priceCounters.get(id) + 1)
    } else {
      this.setState({error: "Es können nicht mehr Tickets hinzugefügt werden."})
      setTimeout(() => {
        this.setState({
          error: null,
        })
      }, 4000);
    }
    this.setState(
      {
        totalCounter: totalCounter,
        priceCounters: priceCounters
      }
    )
  }

  successfulAdded() {
    this.setState({totalCounter: 0})
    this.props.store.showStore.load(this.props.show.id).then(() => {
      this.props.store.cartStore.load(sessionStorage.getItem('cart')).then(() => {
        this.setState({
          message: true
        })
        const priceCounter = new Map()
        this.props.prices.forEach((price) => {
          priceCounter.set(price.id, 0)
        });
        this.setState({priceCounters: priceCounter})
        setTimeout(() => {
          this.setState({
            message: false,
          })
        }, 4000);
      })
    })
  }

  addTicketsToCart(cart, price, ticketCount) {
    if (ticketCount === 0) return;
    const useExistingCart = sessionStorage.getItem('cart') != null

    if (useExistingCart) {
      const existingCartTicket = this.props.store.cartTicketStore.cartTickets.find(
        cartTicket => cartTicket.show_id === this.props.show.id && cartTicket.cart_id === cart.id && cartTicket.price_id === price.id
      )
      if (existingCartTicket == null) {
        this.props.store.cartTicketStore.create({
          quantity: ticketCount,
          cart_id: cart.id,
          show_id: this.props.show.id,
          price_id: price.id,
          cart_number: sessionStorage.getItem('cart')
        }).then(() => {
          this.successfulAdded();
        })
      } else {
        existingCartTicket.quantity += ticketCount
        existingCartTicket.cart_number = sessionStorage.getItem('cart')
        this.props.store.cartTicketStore.update(existingCartTicket).then(() => {
          this.successfulAdded();
        })
      }
    } else {
      this.props.store.cartStore.create(cart).then(result => {
        this.props.store.cartStore.load(result.number)
        sessionStorage.setItem('cart', result.number)
        this.props.store.cartTicketStore.create({
          quantity: ticketCount,
          cart_id: result.id,
          show_id: this.props.show.id,
          price_id: price.id,
          cart_number: result.number
        }).then(() => this.successfulAdded())
      })
    }
  }

  render() {
    const {t, show, prices} = this.props;
    const cart = this.props.store.cartStore.carts.find(cart => cart.number === sessionStorage.getItem('cart'))
    let description = []


    if(show.description != null) {
      description = show.description.split(/\r?\n/);
    }

    return (
      <>
        <Grid.Row style={{marginBottom: 40}}>
          <Grid.Column>
            <Link to={"/"}>
              <Icon name={"chevron left"}/>Zurück zur Übersicht
            </Link>
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column className={"show-detail-header"} width={16}>
              <Header as='h1' style={{marginBottom: 20}}>{show.name}</Header>
            </Grid.Column>
            <Grid.Column width={8}>
              <Image src={show.presale_image.detail_size.url} wrapped ui={true}/>
            </Grid.Column>
            <Grid.Column width={8}>
              <p style={{marginBottom: 15}}>
                <Icon name='map marker alternate' style={{marginRight: 10}}/>
                <span className='date'>{show.location}</span>
              </p>
              <p style={{marginBottom: 5}}>
                <Icon name='calendar alternate outline' style={{marginRight: 10}}/>
                <span
                  className='date'>{show.begins_at
                  ? moment(show.begins_at).format("DD.MM.YYYY") + " um " +
                  moment(show.begins_at).format("HH:mm")
                  : '-'}</span>
              </p>
              <p style={{paddingLeft: 25, position: 'relative'}}>
                {
                  description.map((line, index) => (
                    <React.Fragment key={index}>{line}{index < (description.length - 1) ? <br /> : ''}</React.Fragment>
                  ))
                }
                <Icon style={{position: 'absolute', left: 0, top: 0}} name='info circle'/>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as='h2' style={{marginBottom: 20}}>
                Tickets kaufen
                <span style={{fontSize: '0.5em', paddingLeft: 40, fontWeight: 300, position: 'relative', top: '-4px'}}>
                <Icon name='circle' style={{color: show.available_presale_tickets_count > 0 ? 'lightgreen' : 'red'}}/>
                  {show.available_presale_tickets_count > 0
                    ? 'Noch ' + show.available_presale_tickets_count + ' freie Tickets'
                    : 'Leider ausverkauft'
                  }
              </span>
              </Header>
            </Grid.Column>

            <Grid.Column width={16}>
              <Table style={{fontSize: '1.2em'}}>
                <Table.Body>
                  {prices.filter(p => !p.internal).map((price) =>
                    <Table.Row key={price.id}>
                      <Table.Cell>
                        {t(i18nArAttr(`price.price_types.${price.price_type}`))}
                      </Table.Cell>
                      <Table.Cell>
                        {price.formatPrice()}
                      </Table.Cell>
                      <Table.Cell textAlign={'right'}>
                        <Button
                          icon='minus'
                          style={{marginRight: 15}}
                          onClick={() => this.handleMinus(price.id)}
                        />
                        {this.state.priceCounters.get(price.id)} Tickets
                        <Button
                          icon='plus'
                          disabled={this.state.totalCounter >= show.available_presale_tickets_count}
                          style={{marginLeft: 20}}
                          onClick={() => this.handleAdd(price.id)}
                        />
                      </Table.Cell>
                      <Table.Cell textAlign={'right'}>
                        <Button
                          onClick={() => this.addTicketsToCart(cart, price, this.state.priceCounters.get(price.id))}
                          disabled={show.available_presale_tickets_count <= 0}
                          primary
                        >
                          In den Warenkorb
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          {this.state.message ?
            <Grid.Row>
              <Grid.Column width={14}>
                <Message
                  positive
                  icon='ticket'
                  header='Ticket wurde erfolgreich dem Warenkorb hinzugefügt'
                />
              </Grid.Column>
            </Grid.Row>
            : <></>
          }
          {this.state.error ?
            <Grid.Row>
              <Grid.Column width={14}>
                <Message
                  warning
                  icon='ticket'
                  header={this.state.error}
                />
              </Grid.Column>
            </Grid.Row>
            : <></>
          }
        </Grid>
      </>
    )
  }
}

export default compose(
  loadEntity('showStore', 'show', "/", {
      paramName: 'id'
    }
  ),
  loadEntities('priceStore', 'prices',
    {
      storeParams: (props) => ({params: {showId: props.show.id}})
    }
  ),
  withNamespaces('translation'),
  inject('store'),
  observer
)(ShowDetail);
