import {BaseModel} from "./base";

export class CartTicket extends BaseModel {
  id = 0;
  quantity = 0;
  cart_id = 0;
  show_id = 0;
  price_id = 0;
  price = 0;
  total_price = 0;

  static fromPlainObject(plainObject) {
    const cartTicket = new CartTicket();
    return Object.assign(cartTicket, plainObject);
  }

}
