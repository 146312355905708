import {BasePolicy} from "./basePolicy";

export class UserPolicy extends BasePolicy {
  canDeleteUser(otherUser) {
    return otherUser.id.toString() !== this.rootStore.authStore.user.id.toString();
  }

  canAccessAdminSection() {
    return this.rootStore.authStore.user.admin;
  }
}
