import * as React from 'react';
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import {Button, Card, Grid, Icon, Image} from "semantic-ui-react";
import moment from "moment";
import {loadEntities} from "../admin/shared/loadEntities";

class ShowListItem extends React.Component {

  render() {

    const {show} = this.props

    return (
      <Grid.Column style={{marginBottom: 30}} className={"show-list-item"} key={show.id}>
        <Link to={"/shows/" + show.id}>
          <Card style={{height: '100%'}} fluid>
            <Image src={show.presale_image.list_size.url} wrapped ui={true}/>
            <Card.Content style={{position: 'relative', paddingBottom: '6em'}}>
              <Card.Header style={{marginTop: 10}}>{show.name}</Card.Header>
              <Card.Meta style={{marginBottom: 20}}>
                <span className='group-name'>{show.group_name}</span>
              </Card.Meta>
              <Card.Meta>
                <p style={{marginBottom: 15}}>
                  <Icon name='map marker alternate' style={{marginRight: 10}}/>
                  <span className='date'>{show.location}</span>
                </p>
                <p style={{marginBottom: 15}}>
                  <Icon name='calendar alternate outline' style={{marginRight: 10}}/>
                  <span
                    className='date'>{show.begins_at
                    ? moment(show.begins_at).format("DD.MM.YYYY") + " um " +
                    moment(show.begins_at).format("HH:mm")
                    : '-'}
                  </span>
                </p>
              </Card.Meta>
              <Card.Description>
                <p style={{paddingLeft: 25, position: 'relative'}}>
                  {show.description.length > 100 ? show.description.substring(0, 100) + "..." : show.description}
                  <Icon style={{position: 'absolute', left: 0, top: 0}} name='info circle'/>
                </p>
              </Card.Description>
              <div className={'available-ticket-div'} style={{textAlign: 'center'}}>
                <Button disabled={show.available_presale_tickets_count <= 0} primary>Tickets kaufen</Button>
                <p style={{fontSize: '0.8em', textAlign: 'center', marginTop: 5, paddingRight: 10}}>
                  <Icon name='circle' style={{color: show.available_presale_tickets_count > 0 ? 'lightgreen' : 'red'}}/>
                  {show.available_presale_tickets_count > 0
                    ? 'Noch ' + show.available_presale_tickets_count + ' freie Tickets'
                    : 'Show ist bereits ausverkauft'
                  }
                </p>
              </div>
            </Card.Content>
          </Card>
        </Link>
      </Grid.Column>
    )
  }
}

export default compose(
  loadEntities('priceStore', 'prices', {
    storeParams: (props) => ({params: {showId: props.show.id}})
  }),
  withNamespaces('translation'),
  inject('store'),
  observer
)(ShowListItem);