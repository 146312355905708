import * as React from "react";
import {
  Route,
  Switch
} from 'react-router-dom'
import NotFound from "../../notFound";
import ReservationSearch from "./search";

class Routes extends React.Component {
  render() {
    const match = this.props.match;
    return <Switch>
      <Route exact path={`${match.path}/search`} component={ReservationSearch}/>
      <Route path={`${match.path}/*`} component={NotFound}/>
    </Switch>
  }
}

export default Routes;