import React from 'react'
import {Redirect} from 'react-router-dom'
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import Text from "../forms/text";
import {Button, Form, Grid, Image, Segment} from "semantic-ui-react";
import CustomForm from "../forms/form";
import compose from "compose-function";
import cirqu_logo from "../../assets/images/cirqu_logo.svg";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import {staffRoute} from "../../routing/routing";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {}
    };

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnSubmit(credentials) {
    const {t} = this.props;
    this.setState({errors: {}});
    this.props.store.authStore.login(credentials).catch((e) => {
      console.error(e);
      this.setState({
        errors: {
          email: [],
          password: [t('errors.login.invalid_credentials')]
        }
      });
    });
  }

  render() {
    const {t} = this.props;
    if (this.props.store.authStore.authenticated) {
      return <Redirect to={staffRoute("/")}/>
    }
    return (
      <Grid verticalAlign={'top'} textAlign={'center'} className={'login'}>
        <Grid.Column>
          <Image src={cirqu_logo}/>
          <Segment stacked>
            <CustomForm onSubmit={this.handleOnSubmit} initialValues={{email: ''}}>
              <Form.Field>
                <Text type={'email'} field="email" errors={this.state.errors.email} icon='user' iconPosition='left'
                      placeholder={t(i18nArAttr('user.email'))}/>
              </Form.Field>
              <Form.Field>
                <Text type={'password'} field="password" errors={this.state.errors.password} icon='lock'
                      iconPosition='left'
                      placeholder={t(i18nArAttr('user.password'))}/>
              </Form.Field>
              <Button type="submit" fluid size='large'>{t('form.login')}</Button>
            </CustomForm>
          </Segment>
        </Grid.Column>
      </Grid>
    )
  }
}


export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(Login);
