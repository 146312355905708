import {decorate, observable} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {Entry} from "../models/entry";

decorate(Entry, {
  user_id: observable,
  user: observable,
  show_id: observable,
  quantity: observable,
  entry_type: observable
});

export class EntryStore extends EntityStore {

  entries = [];

  constructor(rootStore) {
    super(rootStore, 'entries', Api.entries, Entry);
  }
}

decorate(EntryStore, {
  entries: observable,
});
