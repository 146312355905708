import * as React from "react";
import compose from "compose-function";
import {inject, observer} from "mobx-react";
import {adminRoute} from "../../../routing/routing";
import {i18nArModel} from "@semabit/rails-i18n-tools";
import loadEntity from "../shared/loadEntity";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {withNamespaces} from "react-i18next";
import PresaleOrderForm from "./form";

class EditPresaleOrder extends React.Component {
  render() {
    const {t} = this.props;

    return <EntityDetailLayout
      title={t('admin.entity.edit', {model_name: t(i18nArModel(`presale_order.one`))})}
      backPath={`/presale_orders/${this.props.match.params.id}`}
    >
      <PresaleOrderForm
        redirectTo={adminRoute(`/presale_orders/${this.props.match.params.id}`)}
        presaleOrder={this.props.presaleOrder}
        address={this.props.presaleOrder.address}
      />
    </EntityDetailLayout>
  }
}

export default compose(
  loadEntity('presaleOrderStore', 'presaleOrder', adminRoute('/presale_orders')),
  withNamespaces('translation'),
  inject('store'),
  observer
)(EditPresaleOrder);
