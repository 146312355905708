import {createEntity, executeDelete, executeGet, patchEntity, updateEntity} from "./base";

export const resourcesName = 'tickets';
export const resourceName = 'ticket';

const pathPrefix = (showId) => `shows/${showId}`;

export const TicketsApi = {
  all: ({showId}) => executeGet(pathPrefix(showId) + `/${resourcesName}`),
  byId: (id, {showId}) => executeGet(pathPrefix(showId) + `/${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, pathPrefix(entity.show_id) + `/${resourcesName}`, resourceName),
  createMultiple: (count, templateEntity) => createEntity(templateEntity, pathPrefix(templateEntity.show_id) + `/${resourcesName}/create_multiple`, resourceName, {count}),
  update: (entity) => updateEntity(entity, pathPrefix(entity.show_id) + `/${resourcesName}/${entity.id}`, resourceName),
  updateMultiple: (ids, {showId, updates}) => patchEntity(pathPrefix(showId) + `/${resourcesName}/update_multiple`, { ticket_ids: ids, ticket: updates }),
  destroy: (id, {showId}) => executeDelete(pathPrefix(showId) + `/${resourcesName}/${id}`),
  destroyMultiple: (ids, {showId}) => executeDelete(pathPrefix(showId) + `/${resourcesName}/destroy_multiple`, { params: { ticket_ids: ids }})
};
