import {Container} from "semantic-ui-react";
import * as React from 'react';
import compose from "compose-function";
import {withNamespaces} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Route, Switch} from "react-router-dom";
import {withRouter} from "react-router";
import ShowList from "./showList";
import ShowDetail from "./showDetail";
import Cart from "./cart";
import CartConfirmationPage from "./cartConfirmationPage";
import CartContactForm from "./cartContactForm";
import CartPaymentPage from "./cartPaymentPage";
import NotFound from "../notFound";
import Agb from "./agb";

class PreSale extends React.Component {

  render() {
    return (
      <Container>
        <Switch>
          <Route exact path={`/`} component={ShowList}/>
          <Route path={`/shows/:id`} component={ShowDetail}/>
          <Route path={`/cart`} component={Cart}/>
          <Route path={`/agb`} component={Agb}/>
          <Route path={`/checkout/address`} component={CartContactForm}/>
          <Route path={`/checkout/confirmation`} component={CartConfirmationPage}/>
          <Route path={`/checkout/payment`} component={CartPaymentPage}/>
          <Route path={`/*`} component={NotFound}/>
        </Switch>
      </Container>
    )
  }
}

export default compose(
  withNamespaces('translation'),
  withRouter,
  inject('store'),
  observer
)(withRouter(PreSale));
