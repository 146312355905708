import * as React from 'react';
import {withNamespaces} from 'react-i18next';
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import TicketListItem from "./listItem";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {loadEntities} from "../shared/loadEntities";

class TicketList extends React.Component {

  render() {
    const {t} = this.props;
    const headers = [
      t(i18nArAttr('ticket.id')),
      t(i18nArAttr('ticket.state')),
      t(i18nArAttr('common.created_at')),
      t(i18nArAttr('price.price_type')),
      t(i18nArAttr('price.price')),
      `${t(i18nArModel('reservation.one'))} / ${t(i18nArModel('presale_order.one'))}`,
      ''
    ];

    return (
      <div>
        <EntityTable headers={headers}>
          {this.props.tickets.map((ticket) =>
            <TicketListItem
              key={ticket.id}
              ticket={ticket}
              show={this.props.show}
              prices={this.props.prices}
              reservations={this.props.reservations}
            />
          )}
        </EntityTable>
      </div>
    )
  }
}

export default compose(
  loadEntities('reservationStore', 'reservations', {
    lazyLoad: true,
    storeParams: (props) => ({params: {showId: props.show.id}})
  }),
  loadEntities('priceStore', 'prices', {
    lazyLoad: true,
    storeParams: (props) => ({params: {showId: props.show.id}})
  }),
  loadEntities('ticketStore', 'tickets', {
    storeParams: (props) => ({params: {showId: props.show.id}})
  }),
  withNamespaces('translation'),
  inject('store'),
  observer
)(TicketList);
