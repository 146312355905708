import * as React from "react";

const withDefaultId = (FormInput) => {
  return class extends React.Component {
    render() {
      let {field = '', id = '', ...rest} = this.props;

      if (!id && field) {
        id = field;
      }
      return <FormInput field={field} id={id} {...rest} />;
    }
  };

};

export default withDefaultId;