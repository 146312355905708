import * as React from "react";
import {withNamespaces} from "react-i18next";
import CustomForm from "../../forms/form";
import Text from "../../forms/text";
import {inject, observer} from "mobx-react";
import {Form} from "semantic-ui-react";
import {withRouter} from "react-router";
import compose from "compose-function";
import {SubmitButton} from "../shared/buttons";
import {i18nArAttr} from "@semabit/rails-i18n-tools";

class PresaleOrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.successCallback = this.successCallback.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  handleOnSubmit(address) {
    address = {
      ...address,
      id: this.props.address ? this.props.address.id : null
    };

    if (this.props.presaleOrder) {
      this.props.store.addressStore.update(address).then(this.successCallback).catch(this.errorCallback);
    } else {
      this.props.store.addressStore.create(address).then(this.successCallback).catch(this.errorCallback);
    }
  }

  errorCallback(formErrors) {
    this.setState({errors: formErrors});
  }

  successCallback(address) {
    this.setState({errors: {}});
    if (this.props.redirectTo) {
      this.props.history.push(this.props.redirectTo);
    }
  }

  render() {
    // initialValues are not updated in the form once rendered, re-render does not update them either.
    const {t, address = {}} = this.props;
    return <CustomForm onSubmit={this.handleOnSubmit} initialValues={{...address}}>
      <Form.Field>
        <label htmlFor={'firstname'}>{t(i18nArAttr('presale_order.firstname'))}*</label>
        <Text field="firstname" errors={this.state.errors.firstname}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'lastname'}>{t(i18nArAttr('presale_order.lastname'))}*</label>
        <Text field="lastname" errors={this.state.errors.lastname}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'email'}>{t(i18nArAttr('presale_order.email'))}*</label>
        <Text field="email" errors={this.state.errors.email}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'city'}>{t(i18nArAttr('presale_order.city'))}*</label>
        <Text field="city" errors={this.state.errors.city}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'zipcode'}>{t(i18nArAttr('presale_order.zipcode'))}*</label>
        <Text field="zipcode" errors={this.state.errors.zipcode}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'street'}>{t(i18nArAttr('presale_order.street'))}*</label>
        <Text field="street" errors={this.state.errors.street}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'house'}>{t(i18nArAttr('presale_order.house'))}*</label>
        <Text field="house" errors={this.state.errors.house}/>
      </Form.Field>
      <SubmitButton loading={this.props.store.salesPointStore.isLoading}/>
    </CustomForm>
  }
}

export default compose(
  withRouter,
  withNamespaces('translation'),
  inject('store'),
  observer
)(PresaleOrderForm);
