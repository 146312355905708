import * as React from 'react';
import {withNamespaces} from "react-i18next";
import {Table} from "semantic-ui-react";
import compose from "compose-function";
import {adminRoute} from "../../../routing/routing";
import {inject, observer} from "mobx-react";
import {DetailsButton} from "../shared/buttons";
import moment from "moment";

class ShowListItem extends React.Component {

  render() {
    return (
      <Table.Row className={moment().isAfter(this.props.show.ends_at) ? 'inactive' : ''}>
        <Table.Cell>{this.props.show.name}</Table.Cell>
        <Table.Cell>{this.props.festival ? this.props.festival.name : ''}</Table.Cell>
        <Table.Cell>{this.props.show.location}</Table.Cell>
        <Table.Cell>{this.props.show.begins_at ? moment(this.props.show.begins_at).format() : '-'}</Table.Cell>
        <Table.Cell>{`${this.props.show.available_tickets_count} / ${this.props.show.max_available_tickets_count}`}</Table.Cell>
        <Table.Cell>{this.props.show.max_tickets_available_for_presale_count
          ? 'Verfügbar: ' + this.props.show.available_presale_tickets_count + ' / ' + this.props.show.max_tickets_available_for_presale_count
          : '-'
        }
        </Table.Cell>
        <Table.Cell>{this.props.show.presale_starts_at ? moment(this.props.show.presale_starts_at).format() : '-'}</Table.Cell>
        <Table.Cell>{this.props.show.presale_ends_at ? moment(this.props.show.presale_ends_at).format() : '-'}</Table.Cell>
        <Table.Cell collapsing>
          <DetailsButton route={adminRoute(`/shows/${this.props.show.id}`)}/>
        </Table.Cell>
      </Table.Row>
    )
  }
}

export default compose(
  withNamespaces('translation'),
  inject('store'),
  observer
)(ShowListItem);
