import * as React from "react";
import ShowForm from "./form";
import NewEntity from "../shared/newEntity";

class NewShow extends React.Component {
  render() {
    return <NewEntity entitiesPath={'/shows'} name={'show'} form={ShowForm} titleI18nKey={'admin.entity.new.f'}/>;
  }
}

export default NewShow;