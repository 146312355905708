import {BaseModel} from "./base";

export class SalesPoint extends BaseModel {
  id = 0;
  name = '';
  location = '';

  static fromPlainObject(plainObject) {
    const salesPoint = new SalesPoint();
    return Object.assign(salesPoint, plainObject);
  }
}